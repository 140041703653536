import { AlarmConfigState } from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigState';
import { DropdownOrganizationComponent } from './DropdownOrganizationComponent';
import { AlarmConfigAccordions } from './AlarmConfigAccordions';

export const AlarmConfigPage = () => {
	return (
		<AlarmConfigState>
			<DropdownOrganizationComponent />
			<AlarmConfigAccordions />
		</AlarmConfigState>
	);
};
