import { useState } from "react";
import { MapState } from "../../Contexts/Map/MapState"
import { MapComponent } from "./MapComponent"
import { MapHeader } from "./MapHeader"

export const MapPage = () => {
    
    const [showAPsFallen, setShowAPsFallen] = useState(false);

    return (
        <MapState>
            <div style={{overflowX: 'hidden'}}>
                <div style={{margin: '10px' }}>
                    <MapHeader setShowAPsFallen={setShowAPsFallen}/>
                </div>
                <div className="card p-2 grid" style={{textAlign: 'center', margin: '0px 5px'}}>
                    <div className="col-12">
                        <MapComponent showAPsFallen={showAPsFallen}/>
                    </div>
                </div>
            </div>
        </MapState>
    )
}
