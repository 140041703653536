import { AlarmWebhooksConfig } from './AlarmWebhooksConfig';
import { ConfigSection } from './ConfigSection';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { useContext } from 'react';
import AlarmConfigContext from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigContext';
import { AlarmConfigContextType } from '../../../Interfaces/Context/AlarmConfigContextType.interface';
import { AlarmConfigConstants } from '../../../Constants/AlarmConfigConstants';

export const AlarmConfigAccordions = () => {
	const { alarms, update, devices, networks, establishments } = useContext(AlarmConfigContext) satisfies AlarmConfigContextType;

	const callUpdate = () => {
		const alarmsToUpdate = { ...alarms };
		alarmsToUpdate.thresholds = devices?.concat(networks).concat(establishments);
		update(alarmsToUpdate);
	};

	return (
		<>
			{alarms && (
				<div>
					<div style={{ textAlign: 'center' }}>
						<h3 style={{ color: '#495057' }}>Configuración de alarmas</h3>
					</div>
					<div
						className="col-12"
						style={{
							display: 'flex',
							justifyContent: 'end',
							alignItems: 'flex-end'
						}}>
						<Button
							label="Guardar"
							style={{ width: '10%', height: '35px' }}
							onClick={() => {
								callUpdate();
							}}
						/>
					</div>
					<Accordion className="mb-3">
						<AccordionTab header="Configuración de webhooks">
							<AlarmWebhooksConfig />
						</AccordionTab>
						<AccordionTab header="Configuración de dispositivos">
							<ConfigSection
								sectionType={AlarmConfigConstants.DEVICES}
								rows={devices}
							/>
						</AccordionTab>
						<AccordionTab header="Configuración de redes">
							<ConfigSection
								sectionType={AlarmConfigConstants.NETWORKS}
								rows={networks}
							/>
						</AccordionTab>
						<AccordionTab header="Configuración de establecimientos">
							<ConfigSection
								sectionType={AlarmConfigConstants.ESTABLISHMENTS}
								rows={establishments}
							/>
						</AccordionTab>
					</Accordion>
				</div>
			)}
		</>
	);
};
