import { DataChartResponse } from '../../Interfaces/Response/DataChartResponse.interface';
import { OrganizationFieldsResponse } from '../../Interfaces/Response/organizationDashboard/OrganizationFields.interface';
import { BaseService } from '../BaseService';
// import { BaseService } from './BaseService';

export const OrganizationDashboardService = {
    async getFields(): Promise<OrganizationFieldsResponse> {
        // const url = `organizationsTree/trees`;
        // const response = await BaseService.getData(url);
        const response = {
            data: {
                name: 'UBA'
            }
        }
        return response.data as OrganizationFieldsResponse;
    },
    async getDashboardData(idOrganization: string | undefined): Promise<DataChartResponse[]> {
        const url = `networkClients`;
        const qs = `?organizationId=${idOrganization}`
        const response = await BaseService.getData(url, qs);
        return [response.data] as DataChartResponse[];
    }
};