import { useContext, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import AlarmConfigContext from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigContext';
import { AlarmConfigContextType } from '../../../Interfaces/Context/AlarmConfigContextType.interface';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';

export const DropdownOrganizationComponent = () => {
	const { getAlarmsByOrganizationId } = useContext(AlarmConfigContext) as AlarmConfigContextType;
	const { data: organizations } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const value = useRef<string>();

	const onChangeValue = (id: string) => {
		value.current = id;
		getAlarmsByOrganizationId(id);
	};

	useEffect(() => {
		if (organizations && organizations.length > 0) {
			value.current = organizations[0]._id;
			getAlarmsByOrganizationId(organizations[0]._id);
		}
	}, [organizations]);

	return (
		<div className="card p-fluid">
			<h5>Elija la organización</h5>
			<div className="grid formgrid">
				<div className="col-12">
					<Dropdown
						value={value.current}
						options={organizations}
						onChange={(e: any) => {
							onChangeValue(e.value);
						}}
						optionLabel={'name'}
						optionValue={'_id'}
						placeholder={'Seleccione la organización'}
					/>
				</div>
			</div>
		</div>
	);
};
