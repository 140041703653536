import axios, { AxiosRequestConfig } from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { ResponseDto } from '../Interfaces/Response/Response.interface';
import { LocalStorageService } from './LocalStorageService';
import { toast } from 'react-toastify';

const route = process.env.REACT_APP_URL_BACKEND;

axios.interceptors.request.use(
	(config: AxiosRequestConfig<any>) => {
		config.headers = config.headers ?? {};
		const token = LocalStorageService.get('token');
		if (token) config.headers['Authorization'] = 'Bearer ' + token;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response: any) => response,
	(error: { response: any }) => {
		if (window.navigator.onLine) {
			//adecuar a lo que se necesita este es un ejemplo
			if (error.response) {
				if (error.response.data.statusCode === 401) {
					localStorage.clear();
					toast.error('No se encuentra autorizado para realizar la acción, por favor vuelva a loguearse', { position: 'bottom-left' });
					window.location.reload();
				} else if (error.response.data.statusCode === 404 || error.response.data.statusCode === 403) {
					toast.error(error.response.data.message, { position: 'bottom-left' });
					console.log(error);
				} else {
					toast.error('Ha ocurrido un error en la aplicación, por favor comunicarse con soporte', { position: 'bottom-left' });
					console.log(error);
				}
			} else {
				toast.error('Ha ocurrido un error en la aplicación, por favor comunicarse con soporte', { position: 'bottom-left' });
				console.log(error);
			}
			// return Promise.reject(error);
			throw error;
		}
	}
);

export const BaseService = {
	async getData(url: string, qs = '', useTrack = true): Promise<ResponseDto | any> {
		/* console.log(url);
		console.log(route);
		console.log(qs); */
		const res = useTrack
			? await trackPromise(axios.get(`${process.env.REACT_APP_URL_BACKEND}${url}${qs}`))
			: await axios.get(`${process.env.REACT_APP_URL_BACKEND}${url}${qs}`);
		const result = res.data;
		return result;
	},

	async putData(url: string, body: object): Promise<unknown> {
		const res = await trackPromise(axios.put(`${route}${url}`, body));
		const { data } = res.data as ResponseDto;
		return data;
	},

	async postData(url: string, body: object): Promise<unknown> {
		const res = await trackPromise(
			axios.post(`${route}${url}`, body, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			})
		);
		const { data } = res.data;
		return data;
	},

	async deleteData(url: string): Promise<unknown> {
		const res = await trackPromise(axios.delete(`${route}${url}`));
		const { data } = res.data;
		return data;
	},

	async patchData(url: string, body?: object): Promise<unknown> {
		const res = await trackPromise(axios.patch(`${route}${url}`, body));
		const { data } = res.data;
		return data;
	},

	async downloadData(url: string, body: object) {
		const res = await trackPromise(axios.post(`${route}${url}`, body, { responseType: 'blob' }));
		return res;
	},

	async downloadGetData(url: string, qs = '') {
		const res = await trackPromise(axios.get(`${route}${url}${qs}`, { responseType: 'blob' }));
		return res;
	}
};
