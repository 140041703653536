import { BaseService } from './BaseService';
import { MapPointResponse } from '../Interfaces/Response/map/MapPointReponse.interface';

export const MapService = {
    async getMapPoints(): Promise<MapPointResponse[]> {
        const url = 'devices/map';
        const response = await BaseService.getData(url);
        return response.data as MapPointResponse[];
    }
};
