// import { getArrayFormatLossInfo } from '../../Helpers/ChartDataHelper';
import { DataChartResponse } from '../../Interfaces/Response/DataChartResponse.interface';
import { DevicesFieldsResponse } from '../../Interfaces/Response/devicesDashboard/DevicesFields.interface';
import { BaseService } from '../BaseService';

export const DevicesDashboardService = {
	async getInfo(id: string | undefined): Promise<DevicesFieldsResponse> {
		const url = `devices/dashboard`;
		const qs = `?_id=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as DevicesFieldsResponse;
	},
	async getGraphicDashboard(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `performances/`;
		const qs = `?deviceId=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as DataChartResponse[];
	},
	async getTimeSeriesGraphicData(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `devices/losslatency`;
		const qs = `?deviceId=${id}`;
		const response = await BaseService.getData(url, qs);
		const newResponse: any = [];
		if (response && Object.keys(response).length > 0) {
			Object.keys(response).forEach((key) => {
				const newArrLoss: any = [];
				const newArrLatency: any = [];
				newArrLoss.push({ name: key });
				newArrLatency.push({ name: key });
				response[key].lossArray.forEach((item: any) => {
					newArrLoss.push({
						count: item.count,
						date: item.date
					});
				});
				response[key].latencyArray.forEach((item: any) => {
					newArrLatency.push({
						count: item.count,
						date: item.date
					});
				});
				newResponse.push(newArrLoss);
				newResponse.push(newArrLatency);
			});
		}
		return newResponse as DataChartResponse[];
	},
	async getNetworkHealthChannel(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `devices/network-health-channel`;
		const qs = `?deviceSerial=${id}`;
		const response = await BaseService.getData(url, qs, false);
		const newResponse: any = [];
		if (response && Object.keys(response).length > 0) {
			Object.keys(response).forEach((key) => {
				const newArr: any = [];
				newArr.push({ name: key });
				response[key].forEach((item: any) => {
					newArr.push({
						count: item.count,
						date: item.date
					});
				});
				newResponse.push(newArr);
			});
		}
		return newResponse as DataChartResponse[];
	},
	async getSnrSsiChart(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `wireless-signal-quality-history`;
		const qs = `?deviceSerial=${id}`;
		const newResponse: any = [];
		if (id !== undefined) {
			const { data: response } = await BaseService.getData(url, qs);
			if (response && Object.keys(response).length > 0) {
				newResponse.push(response.snr);
				newResponse.push(response.rssi);
			}
		}
		return newResponse as DataChartResponse[];
	}
};
