import { BaseService } from './BaseService';

// import { DeviceBoardResponse } from "../Interfaces/Response/DeviceBoardResponse.interface";

export const DeviceBoardService = {
	async get(serial: string): Promise<any> {
		if (!serial) throw new Error('No serial provided in getDeviceBoard');
		const url = `devices/traffic-device-board?deviceSerial=${serial}`;
		const response = await BaseService.getData(url, '', false);
		return response;
	},
	async getOrganizationForBoardAlarm(serial: string | undefined): Promise<any> {
		if (!serial) throw new Error('No serial provided in getOrganizationForBoardAlarm');
		const url = `alarms/dashboard?organizationId=${serial}`;
		const response = await BaseService.getData(url, '', false);
		return response;
	}
};
