import { useState } from 'react';
import { GraphicComponent } from '../../../Components/GraphicComponent';
import { useFetch } from '../../../Hooks/useFetch';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { DashboardConstants } from '../../../Constants/DashboardStateConstants';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ChartConstants } from '../../../Constants/ChartConstants';

export const EstablishmentGraphics = () => {
	const params = useParams();
	const [type, setType] = useState<string>(ChartConstants.NETWORK_CLIENTS);
	const { data: loginInfo } = useFetch(
		() => FetchKeyConstants.LOGIN_SPLASH(params.id),
		() => FetchConstants.LOGIN_SPLASH(params.id, DashboardConstants.ESTABLISHMENT)
	);
	const { data: dashboardData } = useFetch(
		() => FetchKeyConstants.CHART_DATA(params.id),
		() => FetchConstants.CHART_DATA(params.id, DashboardConstants.ESTABLISHMENT)
	);

	return (
		<div className="card">
			<div className="grid ml-1 text-center">
				<div className="col-12">
					<h4 className="mb-0">Gráficos</h4>
				</div>
				<div className="col-1"></div>
				<div
					className="col-10 grid m-auto mb-0"
					style={{ alignContent: 'center' }}>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							label={ChartConstants.NETWORK_CLIENTS_TITLE}
							disabled={type === ChartConstants.NETWORK_CLIENTS}
							onClick={() => {
								setType(ChartConstants.NETWORK_CLIENTS);
							}}
						/>
					</div>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							label={ChartConstants.LOGIN_SPLASH_TITLE}
							disabled={type === ChartConstants.LOGIN_SPLASH}
							onClick={() => {
								setType(ChartConstants.LOGIN_SPLASH);
							}}
						/>
					</div>
				</div>
				<div className="col-1"></div>
				<div className="col-1"></div>
				<div className="col-10">
					{dashboardData && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.NETWORK_CLIENTS}
							data={dashboardData}
							title={'Red'}
						/>
					)}
					{loginInfo && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.LOGIN_SPLASH}
							data={loginInfo}
							title={ChartConstants.LOGIN_SPLASH_TITLE}
							namesData={[ChartConstants.SUCCESS_LOGIN_NAME, ChartConstants.FAILED_LOGIN_NAME]}
						/>
					)}
				</div>
				<div className="col-1"></div>
			</div>
		</div>
	);
};
