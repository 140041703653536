import { DataChartResponse } from "../../Interfaces/Response/DataChartResponse.interface";
import { BaseService } from "../BaseService";

export const NetworkClientsService = {
    async getDashboardData(id: string | undefined, type: string): Promise<DataChartResponse[]> {
        const url = `networkClients`;
        const qs = `?${type}Id=${id}`
        const response = await BaseService.getData(url, qs);
        return [response.data] as DataChartResponse[];
    }
};