import { Chart } from 'primereact/chart';
import { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { convertChartData, getFormat } from '../Helpers/ChartDataHelper';
import { locale, addLocale } from 'primereact/api';
import moment from 'moment';
import { GraphicComponentInterface, dataSetInterface } from '../Interfaces/Components/Graphic.interface';
import { graphicsParams } from '../Constants/Graphics';
//import { ChartConstants } from '../Constants/ChartConstants';

export const GraphicComponent = ({ data, namesData, redLineParam, yellowLineParam, displayGraphic, graphicFromDate }: GraphicComponentInterface) => {
	const currentDate = moment().set('minute', 0).toDate();
	const yesterdayDate = moment(Date.now() - 86400000)
		.set('minute', 0)
		.toDate();
	const [fromDate, setFromDate] = useState<any>(graphicFromDate ? graphicFromDate : yesterdayDate);
	const [toDate, setToDate] = useState<any>(currentDate);
	const [chartDataFilter, setChartDataFilter] = useState<any>();
	const [fromDateParsed, setFromDateParsed] = useState<string | null>(null);
	const [toDateParsed, setToDateParsed] = useState<string | null>(null);
	const [showMaxDaysMessage, setShowMaxDaysMessage] = useState<boolean>(false);
	const [showGraphic, setShowGraphic] = useState<boolean>(false);
	const chartData = useMemo(() => {
		return convertChartData(data, namesData);
	}, [data]);
	const showMessage = useMemo(() => {
		return (!toDate && !fromDate) || (toDate && !fromDate) || (!toDate && fromDate);
	}, [fromDate, toDate]);

	useEffect(() => {
		filter('', '');
	}, [redLineParam, yellowLineParam]);

	addLocale('es', graphicsParams);

	locale('es');

	const calculateMaxTime = (value: any, type: string) => {
		const diferenceDays = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);
		if (diferenceDays < 31) filter(value, type);
		else {
			setShowGraphic(false);
			setShowMaxDaysMessage(true);
		}
	};

	const redLine: dataSetInterface = {
		borderColor: '#FF0000',
		borderWidth: 0.8,
		label: 'Umbral Rojo',
		tension: 0.1,
		borderDash: [4, 4],
		data: []
	};
	const yellowLine: dataSetInterface = {
		borderColor: '#FFD700',
		borderWidth: 0.8,
		label: 'Umbral Amarillo',
		tension: 0.1,
		borderDash: [4, 4],
		data: []
	};

	const checkLines = (data: any) => {
		if (yellowLineParam) {
			yellowLine.data.push({ x: '1990-01-01', y: yellowLineParam });
			yellowLine.data.push({ x: toDate, y: yellowLineParam });
		}
		if (redLineParam) {
			redLine.data.push({ x: '1990-01-01', y: redLineParam });
			redLine.data.push({ x: toDate, y: redLineParam });
		}
		if (yellowLineParam) {
			data?.datasets?.push(yellowLine);
		}
		if (redLineParam) {
			data?.datasets?.push(redLine);
		}
	};

	const filter = (value: any, type: string) => {
		let toDateCompare = toDate;
		let fromDateCompare = fromDate;
		if (type == 'to') toDateCompare = value;
		if (type == 'from') fromDateCompare = value;
		const data = JSON.parse(JSON.stringify(chartData));
		formatDataSets(data, fromDateCompare, toDateCompare);
		setShowMaxDaysMessage(false);
		setShowGraphic(true);
		setFromDateParsed(getFormat(fromDateCompare));
		setToDateParsed(getFormat(toDateCompare));
		checkLines(data);
		setChartDataFilter(data);
	};

	const formatDataSets = (data: any, fromDateCompare: Date, toDateCompare: Date) => {
		data?.datasets?.map((d: any) => {
			if (d.data.length > 0) {
				if (d.data[0]?.name) {
					d.label = d.data[0]?.name;
				}
			} else {
				d.label = `${d.label}(sin datos)`;
			}
			d.data.forEach((e: any, i: number) => {
				const date = new Date(e.x + ':00');
				if (date < fromDateCompare || date > toDateCompare) {
					d.data.splice(i, 1);
				}
			});
		});
	};

	/* const getMaxY = () => {
		if (!yellowLineParam && !redLineParam) return null;
		if (yellowLineParam && redLineParam) {
			return redLineParam > yellowLineParam ? redLineParam * 1.3 : yellowLineParam * 1.3;
		}
		if (redLineParam) {
			return redLineParam * 1.3;
		}
		if (yellowLineParam) {
			return yellowLineParam * 1.3;
		}
	}; */

	const chartOptions = {
		responsive: true,
		hover: {
			mode: 'index'
		},
		scales: {
			x: {
				display: true,
				type: 'time',
				min: fromDateParsed,
				max: toDateParsed
			},
			y: {
				display: true,
				ticks: { beginAtZero: true },
				suggestedMin: 0,
				suggestedMax: redLineParam ? redLineParam * 1.3 : yellowLineParam ? yellowLineParam * 1.3 : null
			}
		}
	};

	return (
		<div style={{ display: `${displayGraphic ? '' : 'none'}` }}>
			<div className="card p-fluid m-1">
				<div className="grid formgrid">
					<div className="col-6">
						<h6 style={{ color: '#495057' }}>Desde</h6>
						<Calendar
							id="time24"
							value={fromDate}
							onChange={(e: any) => {
								setFromDate(e.value);
								calculateMaxTime(e.value, 'from');
							}}
							maxDate={toDate ? toDate : currentDate}
							dateFormat="dd-mm-yy"
							showTime
							stepMinute={60}
						/>
					</div>
					<div className="col-6">
						<h6 style={{ color: '#495057' }}>Hasta</h6>
						<Calendar
							id="time24"
							value={toDate}
							onChange={(e: any) => {
								setToDate(e.value);
								calculateMaxTime(e.value, 'to');
							}}
							minDate={fromDate ? fromDate : null}
							maxDate={currentDate}
							dateFormat="dd-mm-yy"
							showTime
							stepMinute={60}
						/>
					</div>
					{showMessage && (
						<div className="col-12 p-3">
							<span style={{ color: '#495057', fontSize: '13px' }}>Seleccione las fechas para visualizar el gráfico.</span>
						</div>
					)}
					{showMaxDaysMessage && (
						<div className="col-12 p-3">
							<span style={{ color: '#495057', fontSize: '13px' }}>
								Las fechas seleccionadas superan el máximo permitido. Máximo: 30 días.
							</span>
						</div>
					)}
				</div>
			</div>
			{chartDataFilter && showGraphic && (
				<div className="card card-w-title m-1">
					<Chart
						type="line"
						data={chartDataFilter}
						options={chartOptions}
					/>
				</div>
			)}
		</div>
	);
};
