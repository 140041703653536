import { Route, Routes } from 'react-router-dom';
import { RoutesConstants } from '../Constants/RoutesConstants';
import PrivateRouteContainer from './PrivateRouteContainer';
import { HomePage } from '../Pages/Home/HomePage';
import { OrganizationTreePage } from '../Pages/Organizations/Tree/OrganizationTreePage';
import LayoutContainer from '../Components/Layout/LayoutContainer';
import { UsersPage } from '../Pages/Users/TableUser/UsersPage';
import { RegisterUserPage } from '../Pages/Users/RegisterUser/RegisterUserPage';
import { ChangeUserPasswordPage } from '../Pages/Users/ChangePassword/ChangeUserPasswordPage';
import { OrganizationDashboardPage } from '../Pages/Organizations/Dashboard/OrganizationDashboardPage';
import { DevicesDashboardPage } from '../Pages/Devices/Dashboard/DevicesDashboardPage';
import { NetworkSearchPage } from '../Pages/Network/Search/NetworkSearchPage';
import { DeviceSearchPage } from '../Pages/Devices/Search/DeviceSearchPage';
import { AlarmPage } from '../Pages/Alarms/AlarmSearch/AlarmsPage';
import { EstablishmentPage } from '../Pages/Establishments/Search/EstablishmentsPage';
import { NetworkDashboardPage } from '../Pages/Network/Dashboard/NetworkDashboardPage';
import { EstablishmentDashboardPage } from '../Pages/Establishments/EstablishmentDashboard/EstablishmentDashboardPage';
import { AlarmConfigPage } from '../Pages/Alarms/AlarmConfig/AlarmConfigPage';
import { OrganizationsPage } from '../Pages/Organizations/Search/OrganizationsPage';
import { MapPage } from '../Pages/Map/MapPage';
import DeviceBoardPage from '../Pages/Board/DeviceBoardPage';
import { AlarmBoardPage } from '../Pages/Alarms/AlarmBoard/AlarmBoardPage';

export const PrivateRoutes = () => {
	return (
		<>
			<LayoutContainer />
			<div
				style={{
					paddingTop: '75px',
					paddingLeft: '60px'
				}}>
				<Routes>
					<Route element={<PrivateRouteContainer />}>
						<Route
							path={RoutesConstants.PRIVATE_ROUTE}
							index
							element={<HomePage />}
						/>
						<Route
							path={'/*'}
							index
							element={<HomePage />}
						/>
						<Route
							path={RoutesConstants.CHANGE_PASSWORD}
							element={<ChangeUserPasswordPage />}
						/>
						<Route
							path={RoutesConstants.USERS_PAGE}
							element={<UsersPage />}
						/>
						<Route
							path={RoutesConstants.USERS_ABM_ROUTE}
							element={<RegisterUserPage />}
						/>
						<Route
							path={RoutesConstants.USERS_ABM}
							element={<RegisterUserPage />}
						/>
						<Route
							path={RoutesConstants.ORGANIZATION_PAGE}
							index
							element={<OrganizationTreePage />}
						/>
						<Route
							path={RoutesConstants.ALARMS_CONFIG}
							element={<AlarmConfigPage />}
						/>
						<Route
							path={RoutesConstants.ORGANIZATION_TABLE_PAGE}
							element={<OrganizationsPage />}
						/>
						<Route
							path={RoutesConstants.MAP_PAGE}
							element={<MapPage />}
						/>
						<Route
							path={RoutesConstants.ESTABLISHMENT_PAGE}
							element={<EstablishmentPage />}
						/>
						<Route
							path={RoutesConstants.DEVICES_SEARCH_PAGE}
							element={<DeviceSearchPage />}
						/>
						<Route
							path={RoutesConstants.NETWORKS_SEARCH_PAGE}
							element={<NetworkSearchPage />}
						/>
						<Route
							path={RoutesConstants.ALARMS_PAGE}
							element={<AlarmPage />}
						/>
						<Route
							path={RoutesConstants.ORGANIZATION_DASHBOARD}
							element={<OrganizationDashboardPage />}
						/>
						<Route
							path={RoutesConstants.DEVICES_DASHBOARD}
							element={<DevicesDashboardPage />}
						/>
						<Route
							path={RoutesConstants.NETWORK_DASHBOARD}
							element={<NetworkDashboardPage />}
						/>
						<Route
							path={RoutesConstants.ESTABLISHMENT_DASHBOARD}
							element={<EstablishmentDashboardPage />}
						/>
						<Route
							path={RoutesConstants.DEVICE_BOARD_PAGE_SERIAL}
							element={<DeviceBoardPage />}
						/>
						<Route
							path={`${RoutesConstants.DEVICE_BOARD_PAGE}/:id`}
							element={<DeviceBoardPage />}
						/>
						<Route
							path={`${RoutesConstants.DEVICE_BOARD_PAGE}`}
							element={<DeviceBoardPage />}
						/>
						<Route
							path={RoutesConstants.ALARMS_BOARD}
							element={<AlarmBoardPage />}
						/>
					</Route>
				</Routes>
			</div>
		</>
	);
};
