//Alta usuarios
import { UserFormState } from '../../../Contexts/UserForm/UserFormState';
import { RegisterUserForm } from './RegisterUserForm';

export const RegisterUserPage = () => {
    return (
        <UserFormState>
            <RegisterUserForm />
        </UserFormState>
    );
};
