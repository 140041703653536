export const ChartConstants = {
	NAME: 'name',
	LABEL: 'label',
	POINTS: 'points',
	DATA: 'data',
	X_LABEL: 'x',
	Y_LABEL: 'y',
	DATE: 'date',
	COUNT: 'count',
	NO_DATA: 'Sin datos',
	BACKGROUND: 'backgroundColor',
	BORDER_COLOR: 'borderColor',
	BORDER_WIDTH: 'borderWidth',
	BORDER_WIDTH_VALUE: 0.8,
	FILL: 'fill',
	FILL_VALUE: true,
	TENSION: 'tension',
	TENSION_VALUE: 0.1,
	LATENCY_GRAPHIC_NAME: 'Latencia Ms',
	LOSS_GRAPHIC_NAME: 'Porcentaje de pérdida',
	SUCCESS_LOGIN_NAME: 'Login exitosos',
	FAILED_LOGIN_NAME: 'Login fallidos',
	NETWORK_CLIENTS_TITLE: 'Clientes conectados',
	LOGIN_SPLASH_TITLE: 'Información Login',
	NETWORK_CLIENTS: 'networkClients',
	DEVICE_PERFORMANCE: 'Performance dispositivo',
	DEVICE_PERFORMANCE_NAME: 'Uso del dispositivo',
	DEVICE_SNR_SSI: 'SNR/SSI',
	DEVICE_SNR: 'SNR',
	DEVICE_SSI: 'SSI',
	LOGIN_SPLASH: 'loginSplash',
	NETWORK_TRAFFIC: 'Tráfico de red',
	NETWORK_TRAFFIC_TOTAL: 'GB totales',
	NETWORK_TRAFFIC_DOWN: 'GB de bajada',
	NETWORK_TRAFFIC_UP: 'GB de subida',
	NETWORK_HEALTH_CHANNEL: 'Saturacion y Solapamiento de Canales',
	UPLINKS_USAGE: 'Uso Uplinks',
	ORGANIZATION_DASHBOARD: 'Organización',
	SWITCH_PORT_STATUS: 'Estado de puertos'
};
