import config from '../../../Configs/Tables/TreeTableConfig.json';
import { TreeTableComponent } from '../../../Components/TreeTableComponent';
import { OrganizationTreeState } from '../../../Contexts/Organization/Tree/OrganizationTreeState';
import { DropdownComponent } from './Components/DropdownComponent';


export const OrganizationTreePage = () => {
    return (
        <OrganizationTreeState>
            <div>
                <DropdownComponent/>
                <TreeTableComponent config={config}/>
            </div>
        </OrganizationTreeState>
    );
};
