import { getSearchQS } from '../../Helpers/GetSearchQSHelper';
import { SearchRequestInterface } from '../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { ResponseList } from '../../Interfaces/Response/ResponseList';
import { NetworkStructureResponse } from '../../Interfaces/Response/networkSearch/NetworkStructureResponse.interface';
import { BaseService } from '../BaseService';

export const NetworkSearchService = {
	async getAllNetworks(request: SearchRequestInterface): Promise<ResponseList<NetworkStructureResponse>> {
		const url = `networks/search`;
		const response = await BaseService.getData(url, getSearchQS(request));
		return { data: response.data as NetworkStructureResponse[], count: response.count };
	}
};
