import { useState, useContext, useEffect } from 'react';

import { AlarmBoardContext } from '../../../Contexts/Alarm/AlarmBoard/AlarmBoardContext';

// Styles
import './AlarmBoardView.scss';

// Interfaces
import {
	IAccordionTabProps,
	IBoardAlarmSelects,
	INetwork,
	INetworkMetrics,
	IDeviceStates
} from '../../../Interfaces/Components/AlarmBoard/AlarmBoard.interface';
// import { EstablishmentForSelects } from '../../../Interfaces/Helpers/DataForSelects';

// Components
import { AlarmBoardPanel } from './AlarmBoardPanel';
import { NoAlarmsCard } from './NoAlarmsCard';
import { Skeleton } from 'primereact/skeleton';
import { PanelSkeleton } from './Skeletons/PanelSkeleton';
import { Button } from 'primereact/button';

// Enums
import { ColorRgbEnum } from '../../../Enums/ColorRgbEnum';

// Services
import { DeviceSearchService } from '../../../Services/Search/DeviceSearchService';

import { AccordionTab, Accordion } from 'primereact/accordion';
import { AlarmBtnRgb } from '../../../Components/AlarmBtnRgb/AlarmBtnRgb';

export const AlarmBoardView = () => {
	const {
		organizationFilter,
		networkFilter,
		setNetworkFilter,
		establishmentFilter,
		setEstablishmentFilter,
		isOrgDataLoading,
		orgData,
		isOrgLoading,
		establishmentsArr
	} = useContext(AlarmBoardContext);
	const [establishmentsTabs, setEstablishmentsTabs] = useState<IAccordionTabProps[]>([]);
	const [networksTabs, setNetworksTabs] = useState<IAccordionTabProps[]>([]);
	const [deviceTabs, setDeviceTabs] = useState<IAccordionTabProps[]>([]);

	const searchDevices = async (serial: string) => {
		const resp = await DeviceSearchService.getDeviceBySerial(serial);
		if (resp.data) {
			const a = document.getElementById('deviceRef');
			if (a) {
				a.setAttribute('href', `/dispositivos/${resp.data._id}/${resp.data.serial}`);
				a.click();
			}
		}
	};

	const setNavigation = (id: number, device: string) => {
		if (id === 1) {
			searchDevices(device);
			return;
		}
		const a = document.getElementById('deviceRef');
		if (a) {
			a.setAttribute('href', `/tablero-dispositivo/${device}`);
			a.click();
		}
	};

	useEffect(() => {
		if (establishmentsArr.length > 0) {
			const establishmentsTabs: IAccordionTabProps[] = [];
			establishmentsArr?.map((establishment: any) => {
				establishmentsTabs.push({
					title: establishment.name,
					color: establishment.color ? establishment.color : ColorRgbEnum.NoColor,
					description: (
						<>
							<p>{establishment.errorDescription}</p>
							<Button
								onClick={() =>
									setEstablishmentFilter({
										name: establishment.name,
										id: establishment.id,
										color: establishment.color ? establishment.color : ColorRgbEnum.NoColor,
										errorDescription: establishment.errorDescription
									})
								}>
								Ver estado de las redes...
							</Button>
						</>
					)
				});
			});
			setEstablishmentsTabs(establishmentsTabs);
		}
	}, [orgData, establishmentsArr]);

	useEffect(() => {
		setNetworkFilter(null);
		if (establishmentFilter) {
			const establishment = orgData?.establishments?.find((establishment: IBoardAlarmSelects) => establishment.id === establishmentFilter.id);
			if (establishment) {
				const newNetworksTabs: IAccordionTabProps[] = [];
				establishment.networks?.map((network: INetwork) => {
					newNetworksTabs.push({
						title: network.name,
						color: network.globalState ? (network.globalState.toLowerCase() as ColorRgbEnum) : ColorRgbEnum.NoColor,
						description: (
							<>
								{network.networkMetrics?.map((metric: INetworkMetrics, index) => {
									return (
										<Accordion
											key={index}
											activeIndex={-1}
											className="accordion-alarms">
											<AccordionTab
												className="tab"
												header={
													<div className="tab-title">
														<p className="m-0">{metric.metricName}</p>
														{metric.state && <AlarmBtnRgb colors={[metric.state]} />}
													</div>
												}>
												<h6>Descripción</h6>
												<div>{metric.description || 'Sin descripción'}</div>
											</AccordionTab>
										</Accordion>
									);
								})}

								{network.deviceStates?.length > 0 ? (
									<Button
										style={{ marginTop: '1rem' }}
										onClick={() => {
											setNetworkFilter({
												name: network.name,
												id: network.id
											});
											console.log({ name: network.name, id: network.id });
										}}>
										Ver estado de dispositivos...
									</Button>
								) : (
									<strong style={{ marginLeft: '1rem' }}>No hay dispositivos en alarmas para mostrar.</strong>
								)}
							</>
						)
					});
				});
				setNetworksTabs(newNetworksTabs);
			}
		}
	}, [establishmentFilter]);

	useEffect(() => {
		if (networkFilter) {
			const establishment = orgData?.establishments?.find((establishment: IBoardAlarmSelects) => establishment.id === establishmentFilter.id);
			if (establishment) {
				const network = establishment.networks?.find((network: INetwork) => network.id === networkFilter.id);
				if (network) {
					const newDeviceTabs: IAccordionTabProps[] = [];
					network.deviceStates?.map((device: IDeviceStates) => {
						newDeviceTabs.push({
							title: `${device.serial}`,
							color: device.globalState ? (device.globalState.toLowerCase() as ColorRgbEnum) : ColorRgbEnum.NoColor,
							description: (
								<>
									<p>{device.errorDescription}</p>
									{
										<div className="link-devices">
											<Button onClick={() => setNavigation(1, device.serial)}>Ir a dashboard del dispositivo</Button>
											<Button onClick={() => setNavigation(0, device.serial)}>Ir a tablero del dispositivo</Button>
										</div>
									}
								</>
							)
						});
					});
					setDeviceTabs(newDeviceTabs);
				}
			}
		}
	}, [networkFilter]);

	return (
		<>
			{isOrgDataLoading || isOrgLoading || establishmentsTabs.length == 0 ? (
				<div className="mx-0 my-4">
					<Skeleton
						className="mb-4"
						width={'100%'}
						height="55px"
					/>
					<Skeleton
						width={'100%'}
						height="455px">
						<PanelSkeleton />
					</Skeleton>
				</div>
			) : (
				<>
					{/* Cambiar el render de <NoAlarmsCard/> cuando tenga un dato que me diga si el sistema tiene alarmas o no. */}
					{/* 	{!networkFilter && !establishmentFilter && !organizationFilter && !isOrgLoading && <NoAlarmsCard />} */}
					{establishmentsTabs.length <= 0 && !isOrgDataLoading && !isOrgLoading && <NoAlarmsCard title="El sistema opera sin alarmas" />}
					<a
						href={'#'}
						target="_blank"
						rel="noreferrer"
						style={{ display: 'none' }}
						id={'deviceRef'}
					/>
					{!networkFilter && !establishmentFilter && organizationFilter && (
						<>
							{establishmentsTabs.length > 0 ? (
								<>
									<h3 className="estado-dispositivos">Estado de Establecimientos</h3>
									<div className="panels">
										<AlarmBoardPanel
											title={'Estado de Establecimientos'}
											tabs={establishmentsTabs}
										/>
									</div>
								</>
							) : (
								<h3 className="estado-dispositivos">Sin Establecimientos disponibles</h3>
							)}
						</>
					)}
					{!networkFilter && establishmentFilter && (
						<>
							{networksTabs.length > 0 ? (
								<>
									<h3 className="estado-dispositivos">Estado de Redes</h3>
									<Button
										className="m-3"
										onClick={() => setEstablishmentFilter(null)}>
										{'<- Volver'}
									</Button>
									<div className="panels">
										<AlarmBoardPanel
											title={'Estado de Redes'}
											tabs={networksTabs}
										/>
									</div>
								</>
							) : (
								<h3 className="estado-dispositivos">Sin Redes disponibles</h3>
							)}
						</>
					)}
					{networkFilter ? (
						<>
							{deviceTabs.length > 0 ? (
								<>
									<h3 className="estado-dispositivos">Estado de Dispositivos</h3>
									<Button
										className="m-3"
										onClick={() => setNetworkFilter(null)}>
										{'<- Volver'}
									</Button>
									<div className="panels">
										<AlarmBoardPanel
											title={'Estado de Dispositivos'}
											tabs={deviceTabs}
										/>
									</div>
								</>
							) : (
								<h3 className="estado-dispositivos">Sin Dispositivos disponibles</h3>
							)}
						</>
					) : null}
				</>
			)}
		</>
	);
};
