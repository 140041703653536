import { LoginForm } from './LoginForm';
import { useEffect, useContext } from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { AppContextType } from '../../Interfaces/Context/appContextType.interface';
import AppContext from '../../Contexts/Global/AppContext';

export const LoginContainer = ({ element }: { element?: JSX.Element }) => {
	const { onGetToken } = useContext(AppContext) as AppContextType;
	const location = useLocation();
	const { search } = location;

	useEffect(() => {
		const queryStringParams = queryString.parse(search);
		const { token, unauthorizedSAML, unauthorizedUser } = queryStringParams;
		if (token) {
			onGetToken(token.toString());
		}
		if (unauthorizedSAML) {
			toast.error('Ha ocurrido un error en la comunicación con el OAM, por favor contactarse con soporte');
		}
		if (unauthorizedUser) {
			toast.error('El usuario no existe en el sistema, por favor contáctese con un administrador para tramitar su alta.');
		}
	}, []);

	return (
		<div className="login-body">
			<div className="card login-panel p-fluid">
				<div className="login-panel-content">
					<div className="grid">
						<div className="col-12 sm:col-6 md:col-6 logo-container">
							{/* TODO: cambiar logo */}
							<img
								src="assets/layout/images/logo-uba.png"
								alt="logo uba"
								style={{ width: '80%', marginTop: '-60px', marginLeft: '-10px' }}
							/>
						</div>
						{element ? element : <LoginForm />}
					</div>
				</div>
			</div>
		</div>
	);
};
