import { MouseEventHandler, useContext } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
import { useNavigate } from 'react-router-dom';
import { AppContextType } from '../../Interfaces/Context/appContextType.interface';
import logoUBA from '../../Assets/logo-uba-blanco.png';

const Header = (props: { onMenuButtonClick: MouseEventHandler<HTMLButtonElement> | undefined }) => {
	const { token, onLogout, user } = useContext(AppContext) as AppContextType;
	const navigate = useNavigate();

	return (
		<div
			className="layout-topbar"
			style={{ display: `${token ? 'block' : 'none'}` }}>
			{user && (
				<ul
					className="topbar-menu"
					style={{ marginRight: '10%', marginTop: '10px' }}>
					<div className="user-profile">
						<div className="layout-profile-userinfo">
							<i
								className="pi pi-user"
								style={{ marginRight: '10px', color: '#FFF', fontSize: '18px' }}
							/>
							<span
								className="layout-profile-name"
								style={{ fontSize: '18px' }}>
								{user.name} {user.surname}
							</span>
						</div>
					</div>
				</ul>
			)}
			<div className="grid layout-right-panel-button sign-out-container">
				<div
					className="col-12 sm:col-6 md:col-1"
					onClick={onLogout}>
					<button
						type="button"
						className="p-link layout-right-panel-button layout-topbar-icon">
						<i className="pi pi-sign-out"></i>
					</button>
				</div>
				<div className="col-12 sm:col-6 md:col-1">
					<p className="p-sign-out">Cerrar sesión</p>
				</div>
			</div>
			<button
				type="button"
				className="p-link layout-menu-button layout-topbar-icon"
				onClick={props.onMenuButtonClick}>
				<i className="pi pi-bars"></i>
			</button>
			<button
				type="button"
				className="p-link layout-topbar-logo"
				onClick={() => navigate('/')}>
				<img
					id="topbar-logo"
					src={logoUBA}
					alt="logo-uba"
					style={{ height: '46px' }}
				/>
			</button>
		</div>
	);
};

export default Header;
