import GoogleMap from 'google-map-react';
import { useRef, useState, useContext, useEffect } from 'react';
import pin from '../../Assets/pin.png';
import pinDevice from '../../Assets/pin-device.png';
import MapContext from '../../Contexts/Map/MapContext';
import { MapContextType } from '../../Interfaces/Context/MapContextType';
import { MapConstants } from '../../Constants/MapConstants';
import { FetchConstants, FetchKeyConstants } from '../../Constants/FetchConstants';
import { useFetch } from '../../Hooks/useFetch';

export const MapComponent = ({ showAPsFallen }: { showAPsFallen: boolean }) => {
	const { points, getPoints } = useContext(MapContext) as MapContextType;
	const { data: networks } = useFetch(
		() => FetchKeyConstants.NETWORKS_LIST,
		() => FetchConstants.NETWORKS_LIST()
	);
	const [mapPoints, setMapPoints] = useState<any>();
	const [map, setMap] = useState<any>();
	const [maps, setMaps] = useState<any>();
	const prevMarkersRef = useRef<any>([]);
	const zoomRef = useRef<any>(MapConstants.ZOOM_16);
	const childrenMarkers = useRef<boolean>(false);

	useEffect(() => {
		if (showAPsFallen) {
			getAPsFallen();
			if (zoomRef.current >= MapConstants.ZOOM_CHILDREN_RENDER) renderChildrenMarkers(MapConstants.ZOOM_CHILDREN_RENDER);
			if (zoomRef.current <= MapConstants.ZOOM_16) renderMarkers(map, maps);
		} else {
			setMapPoints(null);
			getPoints();
			if (zoomRef.current >= MapConstants.ZOOM_CHILDREN_RENDER) renderChildrenMarkers(MapConstants.ZOOM_CHILDREN_RENDER);
			if (zoomRef.current <= MapConstants.ZOOM_16) renderMarkers(map, maps);
		}
	}, [showAPsFallen]);

	useEffect(() => {
		setMapPoints(points);
	}, [points]);

	const getAPsFallen = () => {
		const pointsToDelete: any = [];
		let c = 0;
		mapPoints?.forEach((p: any) => {
			p.children = p.children.filter((c: any) => c.fallen === true);
		});
		mapPoints?.forEach((p: any, index: number) => {
			if (p.children.length === 0) pointsToDelete.push(index);
		});
		pointsToDelete.forEach((p: any) => {
			mapPoints.splice(p - c, 1);
			c += 1;
		});
	};

	const clearMarkers = () => {
		if (prevMarkersRef.current)
			for (const m of prevMarkersRef.current) {
				m.setMap(null);
			}
	};

	const renderMarkers = (map: any, maps: any) => {
		clearMarkers();
		const markers: any = [];
		mapPoints?.forEach((d: any) => {
			markers.push(
				new maps.Marker({
					position: { lat: d.lat, lng: d.lng },
					map,
					title: d.title + d.children.length,
					label: {
						text: d.children.length.toString(),
						fontSize: '18px',
						fontWeight: 'bold',
						color: 'white'
					},
					icon: pin
				})
			);
		});
		prevMarkersRef.current = markers;
		return markers;
	};

	const renderChildrenMarkers = (zoomValue?: any) => {
		if (zoomValue >= MapConstants.ZOOM_CHILDREN_RENDER) {
			if (maps && !childrenMarkers.current) {
				clearMarkers();
				const markers: any = [];
				mapPoints?.forEach((d: any) => {
					d.children.forEach((c: any) => {
						markers.push(
							new maps.Marker({
								position: { lat: c.lat, lng: c.lng },
								map,
								title: `Dispositivo: ${c.serial}\nTipo de dispositivo: ${c.productType}\nEstado: ${
									c.fallen ? 'Caído' : 'Estable'
								}\nRed: ${networks.filter((n: any) => n['networkId'] === c.networkId)[0].name}`,
								icon: pinDevice
							})
						);
					});
				});
				childrenMarkers.current = true;
				prevMarkersRef.current = markers;
				return markers;
			}
		} else {
			if (zoomValue <= MapConstants.ZOOM_16) {
				if (map && maps && childrenMarkers.current) {
					childrenMarkers.current = false;
					clearMarkers();
					renderMarkers(map, maps);
				}
			}
		}
	};

	return (
		<div style={{ height: '68vh', width: '100%' }}>
			{mapPoints && (
				<GoogleMap
					bootstrapURLKeys={{ key: 'AIzaSyCapzuguJ5PnW0Div-YToMBA4uZ4wyJHLs' }}
					defaultCenter={{ lat: -34.59736854660297, lng: -58.39788917767809 }}
					defaultZoom={MapConstants.INITIAL_ZOOM}
					onGoogleApiLoaded={({ map, maps }) => {
						setMap(map);
						setMaps(maps);
						renderMarkers(map, maps);
					}}
					onChange={(e: any) => {
						renderChildrenMarkers(e.zoom), (zoomRef.current = e.zoom);
					}}
				/>
			)}
		</div>
	);
};
