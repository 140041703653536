import { getArrayFormatLogin } from '../../Helpers/ChartDataHelper';
import { DataChartResponse } from '../../Interfaces/Response/DataChartResponse.interface';
import { BaseService } from '../BaseService';

export const DashboardGeneralService = {
    async getLoginInfo(id: string | undefined, type: string): Promise<DataChartResponse[]> {
        const url = `loginSplash`;
        const qs = `?${type}Id=${id}`
        const response = await BaseService.getData(url, qs);
        return getArrayFormatLogin(response.data) as any;
    },
};