import 'react-toastify/dist/ReactToastify.css';
import { ReactNode, useState } from 'react';
import { ToastContext } from './ToastContext';
import { ToastContainer } from 'react-toastify';
import './toast.scss';

import { IToastProvider } from '../../Interfaces/Context/Toast';

export const ToastProvider = ({ children }: { children: ReactNode }) => {
	const [toastDefConfig] = useState<IToastProvider>({
		position: 'bottom-right',
		type: 'default',
		autoClose: 4500,
		hideProgressBar: false,
		closeOnClick: true,
		rtl: false,
		pauseOnFocusLoss: false,
		theme: 'dark' /* ,
		customId */
	});

	return (
		<ToastContext.Provider value={toastDefConfig}>
			<>
				{children}
				<ToastContainer
					{...toastDefConfig}
					/* toastClassName={'toastClassname'}
					bodyClassName={'bodyClassname'} */
					limit={3}
				/>
			</>
		</ToastContext.Provider>
	);
};
