import jwtDecode from 'jwt-decode';
import { LocalStorageService } from '../Services/LocalStorageService';

export const useAuth = () => {
    const decodeToken = () => {
        const token = LocalStorageService.get('token');
        return token ? jwtDecode(token) : '';
    };
    const data: any = decodeToken();

    const checkIsUserConsulta = () => {
        return data && data.establishments?.length > 0;
    };

    const disabledDropdown = () => {
        return data && data.establishments?.length === 1;
    };

    return { checkIsUserConsulta, dataEstablishments: data.establishments, disabledDropdown };
};
