// Context
import { AlarmBoardState } from '../../../Contexts/Alarm/AlarmBoard/AlarmBoardState';
// Style
import './AlarmBoard.scss';
// Components
import { AlarmBoardSelects, AlarmBoardView } from '../AlarmBoard';

export const AlarmBoardPage = () => {
	return (
		<AlarmBoardState>
			<div className="alarm-board-page">
				<AlarmBoardSelects />
				<AlarmBoardView />
			</div>
		</AlarmBoardState>
	);
};
