//Alta usuarios
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorLabel } from '../../../Components/ErrorLabel';
import { FormConstants } from '../../../Constants/FormConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import UserFormContext from '../../../Contexts/UserForm/UserFormContext';
import { dniValidate, usernameValidate, nameValidate, numberValidate, emailValidate } from '../../../Helpers/ValidationsHelper';
import { UserFormContextType } from '../../../Interfaces/Context/UserFormContextType.interface';
import { UserFormRequest } from '../../../Interfaces/Request/UserRequest/UserFormRequest.interface';
import { EstablishmentSelectResponse } from '../../../Interfaces/Response/establishment/establishmentSelectResponse.interface';
import { RolResponse } from '../../../Interfaces/Response/rol/RolResponse.interface';
import { UserByIdResponse } from '../../../Interfaces/Response/user/userByIdResponse';

export const RegisterUserForm = () => {
	const { rolOptions, establishmentOptions, getUserById, insertUserData, updateUserData } = useContext(UserFormContext) as UserFormContextType;

	const [selectedEstablishment, setSelectedEstablishment] = useState<EstablishmentSelectResponse | null>();
	const [selectedRol, setSelectedRol] = useState<RolResponse | null>();
	const [autoFilteredValue, setAutoFilteredValue] = useState([{}]);

	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [document, setDocument] = useState('');
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [cellphoneNumber, setCellphoneNumber] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	const [errorUsername, setErrorUsername] = useState<boolean>(false);
	const [errorName, setErrorName] = useState<boolean>(false);
	const [errorLastname, setErrorLastname] = useState<boolean>(false);
	const [errorEmail, setErrorEmail] = useState<boolean>(false);
	const [errorDni, setErrorDni] = useState<boolean>(false);
	const [errorPhoneNumber, setErrorPhoneNumber] = useState<boolean>(false);
	const [errorCellphoneNumber, setErrorCellphoneNumber] = useState<boolean>(false);

	const navigate = useNavigate();

	const { id } = useParams();

	useEffect(() => {
		if (id && rolOptions) {
			loadUser(id);
		}
	}, [id, rolOptions]);

	const loadUser = async (id: string) => {
		if (id) {
			const user = await getUserById(id);
			getValues(user);
		}
	};

	const getValues = (data: UserByIdResponse) => {
		setFirstname(data.name);
		setLastname(data.surname);
		setDocument(data.dni);
		setUsername(data.username);
		setEmail(data.email);
		setCellphoneNumber(data.cell_phone);
		setPhoneNumber(data.work_phone);
		setSelectedRol(rolOptions.filter((x: RolResponse) => x._id === data.role)[0]);
		if (data.allowEstablishments && data.allowEstablishments.length)
			setSelectedEstablishment(establishmentOptions.filter((x: EstablishmentSelectResponse) => x.id === data.allowEstablishments[0])[0]);
	};

	const handleDisabled = () => {
		if (
			firstname &&
			lastname &&
			document &&
			username &&
			email &&
			selectedRol &&
			((selectedRol.allowEstablishments && selectedEstablishment) || !selectedRol.allowEstablishments) &&
			!errorUsername &&
			!errorName &&
			!errorLastname &&
			!errorDni &&
			!errorCellphoneNumber &&
			!errorEmail &&
			!errorPhoneNumber
		) {
			return false;
		} else {
			return true;
		}
	};

	const searchOption = (event: any, ListToFind: any) => {
		setTimeout(() => {
			if (!event.query.trim().length) {
				setAutoFilteredValue(ListToFind);
			} else {
				const values = ListToFind.filter((opt: any) => {
					return opt.name.toLowerCase().startsWith(event.query.toLowerCase());
				});
				setAutoFilteredValue(values);
			}
		}, 250);
	};

	const insertUser = async () => {
		const body: UserFormRequest = {
			_id: id ? id : undefined,
			username: username,
			name: firstname,
			surname: lastname,
			email: email,
			dni: document,
			cell_phone: cellphoneNumber,
			work_phone: phoneNumber,
			role: selectedRol?._id,
			//refactor
			allowEstablishments: selectedEstablishment?.id && selectedRol?.name !== 'SUPER_ADMIN' ? [selectedEstablishment?.id as string] : []
		};
		id ? await updateUserData(body) : await insertUserData(body);
		navigate(RoutesConstants.USERS_PAGE);
	};

	const validateUsername = (value: string) => {
		if (value !== '') {
			setErrorUsername(usernameValidate(value));
		} else {
			setErrorUsername(false);
		}
	};

	const validateName = (value: string, field: string) => {
		if (value !== '') {
			if (field === 'firstname') {
				setErrorName(nameValidate(value));
			} else {
				setErrorLastname(nameValidate(value));
			}
		} else {
			if (field === 'firstname') {
				setErrorName(false);
			} else {
				setErrorLastname(false);
			}
		}
	};

	const validateDni = (value: string) => {
		if (value != '') {
			setErrorDni(dniValidate(value));
		} else {
			setErrorDni(false);
		}
	};

	const validateNumber = (value: string, field: string) => {
		if (value != '') {
			if (field === 'phonenumber') {
				setErrorPhoneNumber(numberValidate(value));
			} else {
				setErrorCellphoneNumber(numberValidate(value));
			}
		} else {
			if (field === 'phonenumber') {
				setErrorPhoneNumber(false);
			} else {
				setErrorCellphoneNumber(false);
			}
		}
	};

	const validateEmail = (emailInput: string) => {
		if (emailInput !== '') {
			setErrorEmail(emailValidate(emailInput));
		} else {
			setErrorEmail(false);
		}
	};

	return (
		<div className="grid m-auto">
			<div className="col-12">
				<div className="card p-4">
					<h5>{!id ? 'Registrar usuario' : 'Editar usuario'}</h5>
					<div className="flex flex-column flex-wrap align-content-center">
						<div className="p-fluid formgrid grid m-4">
							<div className="field col-10 md:col-5">
								<label htmlFor="firstname">Nombre *</label>
								<div className={errorName ? 'p-invalid' : 'login-input'}>
									<InputText
										id="firstname"
										type="text"
										value={firstname}
										onChange={(e) => setFirstname(e.target.value)}
										onBlur={(e) => validateName(e.target.value, 'firstname')}
									/>
									<ErrorLabel
										validation={errorName}
										errorMsg={FormConstants.ERROR_NAME_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="lastname">Apellidos *</label>
								<div className={errorLastname ? 'p-invalid' : 'login-input'}>
									<InputText
										id="lastname"
										type="text"
										value={lastname}
										onChange={(e) => setLastname(e.target.value)}
										onBlur={(e) => validateName(e.target.value, 'lastname')}
									/>
									<ErrorLabel
										validation={errorLastname}
										errorMsg={FormConstants.ERROR_LASTNAME_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="document">DNI *</label>
								<div className={errorDni ? 'p-invalid' : 'login-input'}>
									<InputText
										id="document"
										type="number"
										value={document}
										onChange={(event) => setDocument(event.target.value)}
										onBlur={(e) => validateDni(e.target.value)}
									/>
									<ErrorLabel
										validation={errorDni}
										errorMsg={FormConstants.ERROR_DNI_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="username">Username *</label>
								<div className={errorUsername ? 'p-invalid' : 'login-input'}>
									<InputText
										id="username"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										onBlur={(e) => validateUsername(e.target.value)}
									/>
									<ErrorLabel
										validation={errorUsername}
										errorMsg={FormConstants.ERROR_USERNAME_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="email">Email *</label>
								<div className={errorEmail ? 'p-invalid' : 'login-input'}>
									<InputText
										id="email"
										type="mail"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										onBlur={(e) => validateEmail(e.target.value)}
									/>
									<ErrorLabel
										validation={errorEmail}
										errorMsg={FormConstants.ERROR_EMAIL_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="rol">Roles</label>
								<AutoComplete
									placeholder="Roles"
									id="rol"
									dropdown
									value={selectedRol}
									onChange={(e) => setSelectedRol(e.value)}
									suggestions={autoFilteredValue}
									completeMethod={(e) => searchOption(e, rolOptions)}
									field="name"
								/>
							</div>
							{selectedRol && selectedRol.allowEstablishments && (
								<div className="field col-10 md:col-5">
									<label htmlFor="establishment">Establecimiento</label>
									<AutoComplete
										placeholder="Establecimiento"
										id="establishment"
										dropdown
										value={selectedEstablishment}
										onChange={(e) => setSelectedEstablishment(e.value)}
										suggestions={autoFilteredValue}
										completeMethod={(e) => searchOption(e, establishmentOptions)}
										field="name"
									/>
								</div>
							)}
							<div className="field col-10 md:col-5">
								<label htmlFor="cellphoneNumber">Teléfono celular</label>
								<div className={errorCellphoneNumber ? 'p-invalid' : 'login-input'}>
									<InputText
										id="cellphoneNumber"
										type="number"
										value={cellphoneNumber}
										onChange={(event) => setCellphoneNumber(event.target.value)}
										onBlur={(e) => validateNumber(e.target.value, 'cellphone')}
									/>
									<ErrorLabel
										validation={errorCellphoneNumber}
										errorMsg={FormConstants.ERROR_PHONE_MSG}
									/>
								</div>
							</div>
							<div className="field col-10 md:col-5">
								<label htmlFor="phoneNumber">Teléfono laboral</label>
								<div className={errorPhoneNumber ? 'p-invalid' : 'login-input'}>
									<InputText
										id="phoneNumber"
										type="number"
										value={phoneNumber}
										onChange={(event) => setPhoneNumber(event.target.value)}
										onBlur={(e) => validateNumber(e.target.value, 'phonenumber')}
									/>
									<ErrorLabel
										validation={errorPhoneNumber}
										errorMsg={FormConstants.ERROR_PHONE_MSG}
									/>
								</div>
							</div>
						</div>
						<div className="flex justify-content-end">
							<Button
								className="p-button-outlined"
								style={{ marginRight: '16px' }}
								label="Volver"
								onClick={() => navigate(RoutesConstants.USERS_PAGE)}
							/>
							<Button
								onClick={() => insertUser()}
								icon="pi pi-check"
								label="Guardar"
								disabled={handleDisabled()}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
