import { Button } from 'primereact/button'
import { ConfirmModalInterface } from '../Interfaces/Components/ConfirmModal.interface';

export const ConfirmModal = ({ dialogHook, action, label = "elemento" }: ConfirmModalInterface) => {

    const handleClose = () => {
        dialogHook.CloseDialog();
    };

    return (
        <div className="grid p-fluid">
            <div className="col-12 sm:col-12 md:col-12">
                <div className="col-12 sm:col-12 md:col-12 logo-container">
                    <span className="guest-sign-inguest-sign-in">¿Está seguro que desea eliminar el {label} seleccionado?</span>
                </div>
                <div className="grid p-fluid" style={{ marginLeft: '25px' }}>
                    <div className="col-12 sm:col-5 md:col-5"
                        style={{ marginBottom: 15, marginTop: 15 }}
                    >
                        <Button label="Cancelar" icon="pi pi-times" className='p-button-danger'
                            onClick={() => handleClose()} />
                    </div>
                    <div className="col-12 sm:col-1 md:col-1"></div>
                    <div className="col-12 sm:col-5 md:col-5"
                        style={{ marginBottom: 15, marginTop: 15 }}
                    >
                        <Button label="Eliminar" icon="pi pi-check" className='p-button-success'
                            onClick={() => { action(); handleClose() }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
