import './DevicesGraphic.scss';
import { useParams } from 'react-router';
import { GraphicComponent } from '../../../Components/GraphicComponent';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { useEffect, useState } from 'react';
import { ChartConstants } from '../../../Constants/ChartConstants';
import { Button } from 'primereact/button';
import { DeviceGraphicPropsInterface } from '../../../Interfaces/Components/Graphic.interface';
import { ChartSkeleton } from '../../Alarms/AlarmBoard/Skeletons/ChartSkeleton';
import moment from 'moment';

export const DevicesGraphic = ({ productType, thresholds }: DeviceGraphicPropsInterface) => {
	const params = useParams();
	const initialGraphic = productType === 'appliance' ? ChartConstants.DEVICE_PERFORMANCE : ChartConstants.NETWORK_HEALTH_CHANNEL;
	const [type, setType] = useState<string>(initialGraphic);

	const {
		data: dashboardData,
		mutate: mutatePerformanceDevice,
		isLoading: loadingDashboardData
	} = useFetch(
		() => FetchKeyConstants.CHART_DATA_DEVICE(params.id),
		() => FetchConstants.CHART_DATA_DEVICE(params.id),
		false,
		false
	);
	const {
		data: timeSeries,
		mutate: mutateTimeSeries,
		isLoading: loadingTimeSeries
	} = useFetch(
		() => FetchKeyConstants.TIME_SERIES_DATA(params.id),
		() => FetchConstants.TIME_SERIES_DATA(params.id),
		false,
		false
	);
	const {
		data: networkHealth,
		mutate: mutateNetworkHealth,
		isLoading: loadingNetworkHealth
	} = useFetch(
		() => FetchKeyConstants.CHART_NETWORK_HEALTH_CHANNEL(params.serial),
		() => FetchConstants.CHART_NETWORK_HEALTH_CHANNEL(params.serial),
		false,
		false
	);
	const { data: SNR_SSI_data, mutate: mutateSnrSsi } = useFetch(
		() => FetchKeyConstants.DEVICE_SNR_SSI(params.serial),
		() => FetchConstants.DEVICE_SNR_SSI(params.serial),
		false,
		false
	);
	const { data: SWITCH_PORT_STATUS_DATA, mutate: mutateSwitchPortStatus } = useFetch(
		() => FetchKeyConstants.SWITCH_PORT_STATUS(params.id),
		() => FetchConstants.SWITCH_PORT_STATUS(params.id),
		false,
		false
	);

	useEffect(() => {
		mutateTimeSeries();
		mutateNetworkHealth();
		mutatePerformanceDevice();
		mutateSnrSsi();
		mutateSwitchPortStatus();
	}, [params.id, params.serial]);

	useEffect(() => {
		if (!loadingDashboardData && !loadingTimeSeries && !loadingNetworkHealth) {
			const initialGraphic = productType === 'appliance' ? ChartConstants.DEVICE_PERFORMANCE : ChartConstants.NETWORK_HEALTH_CHANNEL;
			if (productType === 'switch') {
				setType(ChartConstants.SWITCH_PORT_STATUS);
				return;
			}
			setType(initialGraphic);
		}
	}, [loadingDashboardData, loadingTimeSeries, loadingNetworkHealth]);

	return (
		<div className="card">
			<div className="dashboard-container">
				<div className="col-12">
					<h4 className="mb-0">Gráficos</h4>
				</div>
				<div
					className="grid-charts"
					style={{ alignContent: 'center' }}>
					{productType === 'switch' && (
						<div>
							<Button
								className="p-button-info p-1"
								label={ChartConstants.SWITCH_PORT_STATUS}
								disabled={type === ChartConstants.SWITCH_PORT_STATUS}
								onClick={() => {
									setType(ChartConstants.SWITCH_PORT_STATUS);
								}}
							/>
						</div>
					)}
					{productType === 'appliance' && (
						<div>
							<Button
								className="p-button-info p-1"
								label={ChartConstants.DEVICE_PERFORMANCE}
								disabled={type === ChartConstants.DEVICE_PERFORMANCE}
								onClick={() => {
									setType(ChartConstants.DEVICE_PERFORMANCE);
								}}
							/>
						</div>
					)}

					<div>
						{productType === 'wireless' && (
							<Button
								className="p-button-info p-1"
								label={ChartConstants.NETWORK_HEALTH_CHANNEL}
								disabled={type === ChartConstants.NETWORK_HEALTH_CHANNEL}
								onClick={() => {
									setType(ChartConstants.NETWORK_HEALTH_CHANNEL);
								}}
							/>
						)}
					</div>
					<div>
						{productType === 'wireless' && (
							<Button
								className="p-button-info p-1"
								label={ChartConstants.DEVICE_SNR_SSI}
								disabled={type === ChartConstants.DEVICE_SNR_SSI}
								onClick={() => {
									setType(ChartConstants.DEVICE_SNR_SSI);
								}}
							/>
						)}
					</div>
					<div>
						{productType === 'appliance' && (
							<Button
								className="p-button-info p-1"
								label={ChartConstants.LOSS_GRAPHIC_NAME}
								disabled={type === ChartConstants.LOSS_GRAPHIC_NAME}
								onClick={() => {
									setType(ChartConstants.LOSS_GRAPHIC_NAME);
								}}
							/>
						)}
					</div>
					<div>
						{productType === 'appliance' && (
							<Button
								className="p-button-info p-1"
								label={ChartConstants.LATENCY_GRAPHIC_NAME}
								disabled={type === ChartConstants.LATENCY_GRAPHIC_NAME}
								onClick={() => {
									setType(ChartConstants.LATENCY_GRAPHIC_NAME);
								}}
							/>
						)}
					</div>
				</div>
				<div className="chart">
					{loadingNetworkHealth && type === ChartConstants.NETWORK_HEALTH_CHANNEL ? (
						<>
							<ChartSkeleton />
						</>
					) : (
						<>
							{networkHealth && (
								<GraphicComponent
									displayGraphic={type === ChartConstants.NETWORK_HEALTH_CHANNEL}
									data={networkHealth}
									title={ChartConstants.NETWORK_HEALTH_CHANNEL}
									namesData={[ChartConstants.NETWORK_HEALTH_CHANNEL]}
									redLineParam={0}
									yellowLineParam={0}
								/>
							)}
						</>
					)}
					{SWITCH_PORT_STATUS_DATA && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.SWITCH_PORT_STATUS}
							data={SWITCH_PORT_STATUS_DATA}
							title={ChartConstants.SWITCH_PORT_STATUS}
							namesData={[ChartConstants.SWITCH_PORT_STATUS]}
							redLineParam={thresholds ? thresholds.filter((t) => t.name === 'SWITCH_PORT_STATUS')[0]?.redValue : 0}
							yellowLineParam={thresholds ? thresholds.filter((t) => t.name === 'SWITCH_PORT_STATUS')[0]?.yellowValue : 0}
						/>
					)}

					{dashboardData && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.DEVICE_PERFORMANCE}
							data={[dashboardData]}
							title={ChartConstants.DEVICE_PERFORMANCE}
							namesData={[ChartConstants.DEVICE_PERFORMANCE_NAME]}
							redLineParam={thresholds ? thresholds.filter((t) => t.name === 'PERFORMANCE')[0]?.redValue : 0}
							yellowLineParam={thresholds ? thresholds.filter((t) => t.name === 'PERFORMANCE')[0]?.yellowValue : 0}
						/>
					)}
					{productType === 'appliance' && timeSeries && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.LOSS_GRAPHIC_NAME}
							data={[timeSeries[0], timeSeries[2]]}
							title={ChartConstants.LOSS_GRAPHIC_NAME}
							namesData={[ChartConstants.LOSS_GRAPHIC_NAME]}
							redLineParam={thresholds ? thresholds.filter((t) => t.name === 'PACKET LOSS')[0]?.redValue : 0}
							yellowLineParam={thresholds ? thresholds.filter((t) => t.name === 'PACKET LOSS')[0]?.yellowValue : 0}
						/>
					)}
					{productType === 'appliance' && timeSeries && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.LATENCY_GRAPHIC_NAME}
							data={[timeSeries[1], timeSeries[3]]}
							title={ChartConstants.LATENCY_GRAPHIC_NAME}
							namesData={[ChartConstants.LATENCY_GRAPHIC_NAME]}
							redLineParam={thresholds ? thresholds.filter((t) => t.name === 'MAXIMUN LATENCY')[0]?.redValue : 0}
							yellowLineParam={thresholds ? thresholds.filter((t) => t.name === 'MAXIMUN LATENCY')[0]?.yellowValue : 0}
						/>
					)}
					{SNR_SSI_data && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.DEVICE_SNR_SSI}
							data={SNR_SSI_data}
							title={ChartConstants.DEVICE_SNR_SSI}
							namesData={[ChartConstants.DEVICE_SNR, ChartConstants.DEVICE_SSI]}
							graphicFromDate={moment().subtract(1, 'week').toDate()}
						/>
					)}
				</div>
				<div className="col-1"></div>
			</div>
		</div>
	);
};
