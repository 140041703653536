import { useState, useEffect } from 'react';
import { DevicesGraphic } from './DevicesGraphic';
import { DevicesInfo } from './DevicesInfo';
import { useFetch } from '../../../Hooks/useFetch';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useParams } from 'react-router-dom';
import { DevicePerformanceInfo } from './DevicePerformanceInfo';
import { DeviceInterfaceRebootInfo } from './DeviceInterfaceRebootInfo';
import { DeviceConfigurationInfo } from './DeviceConfigurationInfo';
import { LocalStorageService } from '../../../Services/LocalStorageService';
import { IThreshold } from '../../../Interfaces/Response/organization/Organization.interface';
import { OrganizationTreeService } from '../../../Services/OrganizationTreeService';

export const DevicesDashboardPage = () => {
	const params = useParams();
	const { data: fieldsGeneralInfo } = useFetch(
		() => FetchKeyConstants.GRAL_INFO_DEVICE(params.id),
		() => FetchConstants.GRAL_INFO_DEVICE(params.id),
		false
	);
	const [thresholds, setThresholds] = useState<IThreshold[] | null>(null);

	useEffect(() => {
		getSingleOrganization();
	}, []);

	const getSingleOrganization = async () => {
		const response = await OrganizationTreeService.getSingleOrganization();
		LocalStorageService.set('thresholds', JSON.stringify(response.thresholds));
		setThresholds(response.thresholds);
	};

	return (
		<>
			<div
				className="grid m-auto text-center"
				style={{ background: '#80808054' }}>
				<div className="col-12">
					<DevicesInfo fieldsGeneralInfo={fieldsGeneralInfo} />
				</div>
				<div className={'col-12'}>
					<DevicePerformanceInfo fieldsGeneralInfo={fieldsGeneralInfo} />
				</div>
				{fieldsGeneralInfo?.interfaceModification && fieldsGeneralInfo?.equipmentReboot && (
					<div className="col-12">
						<DeviceInterfaceRebootInfo fieldsGeneralInfo={fieldsGeneralInfo} />
					</div>
				)}
				{fieldsGeneralInfo?.configurationChange && (
					<div className={'col-12'}>
						<DeviceConfigurationInfo fieldsGeneralInfo={fieldsGeneralInfo} />
					</div>
				)}
				<div className="col-12">
					<DevicesGraphic
						productType={fieldsGeneralInfo?.productType}
						thresholds={thresholds}
					/>
				</div>
			</div>
		</>
	);
};
