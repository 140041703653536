import { ConfigComponentTable } from './ConfigComponentTable';

export const ConfigSection = ({ sectionType, rows }: { sectionType: string, rows: any }) => {

    return (
        <>
            {rows ?
                <>
                    <div className='col-12'
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'flex-end'
                        }}>
                    </div>
                    <ConfigComponentTable
                        columns={[]}
                        rows={rows}
                        type={sectionType}
                        emptyMessage={'No hay configuraciones disponibles'}
                        paginationProps={undefined}
                    />
                </>
                : 'Debe seleccionar una organización'
            }
        </>
    );
};
