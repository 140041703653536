export const TreeTableConstants = {
    ADD_FACULTY: 'Agregar facultad',
    ADD_BUILDING: 'Agregar edificio',
    ASSOCIATE_NETWORK: 'Asociar red',
    DELETE: 'Eliminar',
    FACULTY_LABEL: 'Faculty',
    FACULTY_WITHOUTBUILDINGS_LABEL: 'Faculty without buildings',
    BUILDING_LABEL: 'Building',
    NETWORK_LABEL: 'Network',
    KEY_LABEL: 'key',
    ENTITY_LABEL: 'entity',
    ID_LABEL: 'id',
    NETWORKID_LABEL: 'networkId',
    FACULTIESWITHBUILDINGS_LABEL: 'facultiesWithBuilding',
    FACULTIESWITHOUTBUILDINGS_LABEL: 'facultiesWithoutBuilding',
    FACULTY_CHILDREN_LABEL: 'buildings',
    BUILDING_CHILDREN_LABEL: 'networks',
    CHILDREN_LABEL: 'children',
    FACULTY_NEW_PROPERTY: 'faculties',
    DATA_PROPERTY: 'data',
    ESTABLISHMENTS_LABEL: 'Faculties'
};
