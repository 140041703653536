// Styles
import './AlarmBtnRgb.scss';
// Interfaces
import { AlarmBtnRgbInterface } from './AlarmBtnRgb.interface';

export const AlarmBtnRgb = ({ colors }: AlarmBtnRgbInterface) => {
	return (
		<div className="btnRgb">
			{colors &&
				colors.map((color, index) => {
					return (
						<div
							key={index}
							className="btnOutside">
							<div className={`btnInside ${color.toLocaleLowerCase()}`} />{' '}
						</div>
					);
				})}
		</div>
	);
};
