import { InputText } from 'primereact/inputtext';
import { BackButton } from '../../Components/BackButton';
import { useEffect, useState } from 'react';
import useDebounce from '../../Hooks/useDebounce';
import { useParams } from 'react-router-dom';

export const DeviceBoardHeader = ({ onSearch }: any) => {
	const params = useParams();
	const [count, setCount] = useState(10);
	const [serial, setSerial] = useState('');

	useEffect(() => {
		if (params.serial) {
			setSerial(params.serial);
			onSearch(params.serial);
		}
	}, [params]);

	useDebounce(() => onSearch(serial), 3000, [count]);

	return (
		<div className="grid">
			<div className="col-1">
				<BackButton url={'/dispositivos'} />
			</div>

			<div
				className="col-10"
				style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<h3
					className="m-0 mb-6"
					style={{ color: '#495057' }}>
					Tablero de dispositivo
				</h3>
			</div>

			<div className="col-3">
				<h5
					className="mb-2"
					style={{ color: '#495057' }}>
					Serial
				</h5>
				<InputText
					type="search"
					value={serial}
					onInput={(e) => {
						setSerial(e.currentTarget.value);
						setCount((c) => c + 1);
					}}
					style={{ marginRight: 5, width: '90%', height: '35px' }}
					placeholder="Ingrese serial del dispositivo"
				/>
				<i className="pi pi-search" />
			</div>
		</div>
	);
};
