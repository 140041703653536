import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useContext, useState } from 'react';
import UserContext from '../../../Contexts/Users/UserContext';
import { UserContextType } from '../../../Interfaces/Context/UserContextType.interface';

export const ChangeUserPasswordForm = () => {
    const { sendNewPass } = useContext(UserContext) as UserContextType;

    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    const handleContinue = async () => {
        sendNewPass({ username, oldPassword, newPassword });
    };

    const handleDisabled = () => {
        return username && newPassword && oldPassword ? false : true;
    };

    return (
        <>
            <div className="grid m-auto">
                <div className="col-12">
                    <div className="card p-4">
                        <h5>Cambio de contraseña</h5>
                        <span >
                            Para cambiar la contraseña ingresá el username, contraseña actual y la nueva
                            contraseña.
                        </span>
                        <div className="p-fluid formgrid grid mt-6">
                            <div className="field col-8 md:col-5">
                                <label htmlFor='username'>Username</label>
                                <InputText
                                    id="username"
                                    type="text"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="field col-8 md:col-5">
                                <label htmlFor='password'>Contraseña</label>
                                <InputText
                                    id="password"
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </div>
                            <div className="field col-8 md:col-5">
                                <label htmlFor='new-password'>Nueva contraseña</label>
                                <InputText
                                    id="new-password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div
                            className="flex justify-content-end"
                            onClick={() => handleContinue()}
                            style={{ marginBottom: 15, marginTop: 15 }}>
                            <Button label="Continuar" icon="pi pi-check" disabled={handleDisabled()} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
