import { OrganizationGeneralInfo } from './OrganizationGeneralInfo';
import { OrganizationGraphics } from './OrganizationGraphics';

export const OrganizationDashboardPage = () => {

    return (
        <>
            <div className="grid m-auto">
                <div className="col-12">
                    <OrganizationGeneralInfo />
                </div>
                <div className="col-12">
                    <OrganizationGraphics />
                </div>
            </div>
        </>
    );
};
