import { EstablishmentSelectResponse } from '../../Interfaces/Response/establishment/establishmentSelectResponse.interface';
import { EstablishmentsTableResponse } from '../../Interfaces/Response/establishment/EstablishmentsTableResponse.interface';
import { ResponseList } from '../../Interfaces/Response/ResponseList';
import { BaseService } from '../BaseService';
import { SearchRequestInterface } from '../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { getSearchQS } from '../../Helpers/GetSearchQSHelper';
import { saveAs } from 'file-saver';

export const EstablishmentService = {
    async getEstablishmentsData(
        data: SearchRequestInterface
    ): Promise<ResponseList<EstablishmentsTableResponse>> {
        const url = 'establishments';
        const response = await BaseService.getData(url, getSearchQS(data));
        return { data: response.data as EstablishmentsTableResponse[], count: response.count };
    },
    async getEstablishmentsForSelect(): Promise<EstablishmentSelectResponse[]> {
        const url = 'establishments/select';
        const response = await BaseService.getData(url);
        return response.data as EstablishmentSelectResponse[];
    },
    async download(body: any, filename: string): Promise<any> {
        const url = 'reports';
        const response: any = await BaseService.downloadData(url, body);
        const file = new Blob([response.data]);
        saveAs(file, `${filename}.pdf`);
    }
};
