import { FC, useState } from 'react';
import AlarmConfigContext from './AlarmConfigContext';
import Props from '../../../Interfaces/State/Props.interface';
import { AlarmConfigService } from '../../../Services/AlarmConfigService';
import { AlarmsConfigResponse } from '../../../Interfaces/Response/alarm/AlarmConfigResponse.interface';
import { thresholdsResponse } from '../../../Interfaces/Response/alarm/thresholdsResponse.interface';
import { AlarmTypesConstants } from '../../../Constants/AlarmConfigConstants';
import { toast } from 'react-toastify';

export const AlarmConfigState: FC<Props> = ({ children }) => {
	const [alarms, setAlarms] = useState<AlarmsConfigResponse>();
	const [networks, setNetworks] = useState<thresholdsResponse[]>();
	const [devices, setDevices] = useState<thresholdsResponse[]>();
	const [establishments, setEstablishments] = useState<thresholdsResponse[]>();
	const [idOrganization, setIdOrganization] = useState<string>('');

	const getAlarmsByOrganizationId = async (id: string) => {
		const response = await AlarmConfigService.get(id);
		if (!response) throw new Error('No se encontraron alarmas para la organización seleccionada.');
		setAlarms(response);
		setNetworks(response?.thresholds.filter((row: { thresholdsType: string }) => row.thresholdsType == AlarmTypesConstants.NETWORKS));
		setDevices(
			response?.thresholds.filter(
				(row: { thresholdsType: string }) =>
					row.thresholdsType == AlarmTypesConstants.UTM_SWITCH || row.thresholdsType == AlarmTypesConstants.APS
			)
		);
		setEstablishments(response?.thresholds.filter((row: { thresholdsType: string }) => row.thresholdsType == AlarmTypesConstants.ESTABLISHMENTS));
		setIdOrganization(response.id);
	};

	const update = async (alarms: AlarmsConfigResponse) => {
		await AlarmConfigService.put(idOrganization, alarms);
		setAlarms(alarms);
		toast.success('Las modificaciones fueron guardadas.');
	};

	return (
		<AlarmConfigContext.Provider
			value={{
				alarms,
				setAlarms,
				networks,
				setNetworks,
				devices,
				setDevices,
				establishments,
				setEstablishments,
				getAlarmsByOrganizationId,
				update
			}}>
			{children}
		</AlarmConfigContext.Provider>
	);
};
