// Style
import './AlarmBoard.scss';
// PrimeReact
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AlarmBtnRgb } from '../../../Components/AlarmBtnRgb/AlarmBtnRgb';
// Enum
import { ColorRgbEnum } from '../../../Enums/ColorRgbEnum';
import { IAlarmBoardPanelProps } from '../../../Interfaces/Components/AlarmBoard/AlarmBoard.interface';

export const AlarmBoardPanel = ({ title, tabs, globalState }: IAlarmBoardPanelProps) => {
	return (
		<div className="alarm-board-panel">
			<Card
				title={
					<div className="alarm-card title">
						<p className="m-1" style={{ paddingLeft: '1rem' }}>{title}</p>
						<AlarmBtnRgb colors={globalState ? globalState : [ColorRgbEnum.Red, ColorRgbEnum.Yellow, ColorRgbEnum.Green]} />
					</div>
				}
				className="alarm-card">
				<Accordion
					activeIndex={-1}
					className="accordion-alarms">
					{tabs &&
						tabs.map((tab, index) => {
							return (
								<AccordionTab
									key={`${tab.title}-${index}`}
									className="tab"
									header={
										<div className="tab-title">
											<p className="m-0">{tab.title}</p>
											{tab.color && <AlarmBtnRgb colors={[tab.color]} />}
										</div>
									}>
									<div>{tab.description}</div>
								</AccordionTab>
							);
						})}
					{tabs && tabs?.length <= 0 && (
						<AccordionTab
							key={0}
							className="tab"
							disabled
							header={
								<div className="tab-title">
									<p className="m-0">Nada que mostrar...</p>
								</div>
							}></AccordionTab>
					)}
				</Accordion>
			</Card>
		</div>
	);
};
