import { SearchRequestInterface } from '../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { BaseService } from '../BaseService';
import { DeviceResponse } from '../../Interfaces/Response/deviceSearch/DeviceResponse';
import { ResponseList } from '../../Interfaces/Response/ResponseList';
import { getSearchQS } from '../../Helpers/GetSearchQSHelper';
import { saveAs } from 'file-saver';

export const DeviceSearchService = {
	async getAllDevices(request: SearchRequestInterface): Promise<ResponseList<DeviceResponse>> {
		const url = `devices/byFilter`;
		const response = await BaseService.getData(url, getSearchQS(request));
		return { data: response.data as DeviceResponse[], count: response.count };
	},
	async getAllDevicesWithSerial(request: SearchRequestInterface | undefined): Promise<ResponseList<DeviceResponse>> {
		if (!request || request?.params?.serial.length <= 0) {
			return { data: [], count: 0 };
		}
		const url = `devices/byFilter`;
		const response = await BaseService.getData(url, getSearchQS(request));
		return { data: response.data as DeviceResponse[], count: response.count };
	},
	async getDownloadSearchDevices(request: SearchRequestInterface, filename: string): Promise<any> {
		const url = `devices/byFilter/download`;
		const response = await BaseService.downloadGetData(url, getSearchQS(request));
		const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		saveAs(file, `${filename}.xlsx`);
	},
	async patchRobbenDevices(request: { _id: string; isStolen: boolean }): Promise<any> {
		const url = `devices/stolen?_id=${request._id}&isStolen=${request.isStolen}`;
		const response = await BaseService.patchData(url);
		return { data: response };
	},
	async getDeviceBySerial(serial: string): Promise<any> {
		const url = `devices/device?serial=${serial}`;
		const response = await BaseService.getData(url, '', true);
		return response;
	}
};
