/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { GraphicComponent } from '../../../Components/GraphicComponent';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { DashboardConstants } from '../../../Constants/DashboardStateConstants';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useFetch } from '../../../Hooks/useFetch';
import { ChartConstants } from '../../../Constants/ChartConstants';
import { useState, useEffect } from 'react';
import moment from 'moment';

export const NetworkGraphics = () => {
	const params = useParams();
	const [type, setType] = useState<string>(ChartConstants.NETWORK_CLIENTS);
	const { data: loginInfo } = useFetch(
		() => FetchKeyConstants.LOGIN_SPLASH(params.id),
		() => FetchConstants.LOGIN_SPLASH(params.id, DashboardConstants.NETWORK)
	);
	const { data: dashboardData } = useFetch(
		() => FetchKeyConstants.CHART_DATA(params.id),
		() => FetchConstants.CHART_DATA(params.id, DashboardConstants.NETWORK)
	);
	const { data: trafficData } = useFetch(
		() => FetchKeyConstants.TRAFFIC_DATA(params.id),
		() => FetchConstants.TRAFFIC_DATA(params.id)
	);
	const { data: uplinksUsageData } = useFetch(
		() => FetchKeyConstants.UPLINKS_USAGE(params.id),
		() => FetchConstants.UPLINKS_USAGE(params.id)
	);

	const { keys: uplinks_names, values: uplinks_data } = uplinksUsageData ?? {};

	return (
		<div className="card">
			<div className="grid ml-1 text-center">
				<div className="col-12">
					<h4 className="mb-0">Gráficos</h4>
				</div>
				<div className="col-1"></div>
				<div
					className="col-10 grid m-auto mb-0"
					style={{ alignContent: 'center' }}>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							disabled={type === ChartConstants.NETWORK_CLIENTS}
							label={ChartConstants.NETWORK_CLIENTS_TITLE}
							onClick={() => {
								setType(ChartConstants.NETWORK_CLIENTS);
							}}
						/>
					</div>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							disabled={type === ChartConstants.LOGIN_SPLASH}
							label={ChartConstants.LOGIN_SPLASH_TITLE}
							onClick={() => {
								setType(ChartConstants.LOGIN_SPLASH);
							}}
						/>
					</div>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							disabled={type === ChartConstants.NETWORK_TRAFFIC}
							label={ChartConstants.NETWORK_TRAFFIC}
							onClick={() => {
								setType(ChartConstants.NETWORK_TRAFFIC);
							}}
						/>
					</div>
					<div className="col text-center">
						<Button
							style={{ width: '100%', height: '35px', fontSize: '15px', borderRadius: '5px' }}
							className="p-button-info"
							disabled={type === ChartConstants.UPLINKS_USAGE}
							label={ChartConstants.UPLINKS_USAGE}
							onClick={() => {
								setType(ChartConstants.UPLINKS_USAGE);
							}}
						/>
					</div>
				</div>
				<div className="col-1"></div>
				<div className="col-1"></div>
				<div className="col-10">
					{dashboardData && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.NETWORK_CLIENTS}
							data={dashboardData}
							title={'Red'}
						/>
					)}
					{loginInfo && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.LOGIN_SPLASH}
							data={loginInfo}
							title={ChartConstants.LOGIN_SPLASH_TITLE}
							namesData={[ChartConstants.SUCCESS_LOGIN_NAME, ChartConstants.FAILED_LOGIN_NAME]}
						/>
					)}

					{trafficData && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.NETWORK_TRAFFIC}
							data={trafficData}
							title={ChartConstants.NETWORK_TRAFFIC}
							namesData={[ChartConstants.NETWORK_TRAFFIC_DOWN, ChartConstants.NETWORK_TRAFFIC_UP, ChartConstants.NETWORK_TRAFFIC_TOTAL]}
							graphicFromDate={moment().subtract(2, 'day').toDate()}
							redLineParam={trafficData?.redValue ? trafficData.redValue : 0}
							yellowLineParam={trafficData?.yellowValue ? trafficData.yellowValue : 0}
						/>
					)}
					{uplinksUsageData && (
						<GraphicComponent
							displayGraphic={type === ChartConstants.UPLINKS_USAGE}
							data={uplinks_data}
							title={ChartConstants.UPLINKS_USAGE}
							namesData={uplinks_names}
						/>
					)}
				</div>
				<div className="col-1"></div>
			</div>
		</div>
	);
};
