import { useContext } from 'react';
import { TableComponentGeneric } from '../../../Components/TableGeneric';
import OrganizationTableContext from '../../../Contexts/Organization/Search/OrganizationTableContext';
import { useNavigate } from 'react-router';
import { OrganizationTableContextType } from '../../../Interfaces/Context/OrganizationTableContextType.interface';
import OrganizationTableConfig from '../../../Configs/Tables/OrganizationTableConfig.json';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

export const OrganizationsTable = () => {
	const { organizations, paginationProps, onChangePage } = useContext(OrganizationTableContext) satisfies OrganizationTableContextType;
	const navigate = useNavigate();

	const navigateDashboard = (index: number) => {
		navigate(`${RoutesConstants.ORGANIZATION_TABLE_PAGE}/${organizations[index]._id}`);
	};

	const actions = [
		{
			icon: 'pi-chart-bar',
			class: 'p-button-info',
			action: navigateDashboard
		}
	];

	const handlePaginationChange = (limit: number, skip: number, page: number) => {
		onChangePage(limit, page);
	};

	return (
		<div className="m-3">
			<div className="card p-fluid">
				<div style={{ textAlign: 'center' }}>
					<h3
						className="mb-6"
						style={{ color: '#495057' }}>
						Buscador de organizaciones
					</h3>
				</div>
			</div>
			<TableComponentGeneric
				columns={OrganizationTableConfig.tableConfig}
				rows={organizations}
				actions={actions}
				paginationProps={paginationProps}
				emptyMessage={'No hay organizaciones'}
				handleChange={handlePaginationChange}
			/>
		</div>
	);
};
