import { CssBaseline } from '@mui/material';
import './App.scss';
import { Spinner } from './Components/Layout/Spinner';
import { MainPage } from './Pages/MainPage';
import { ToastProvider } from './Contexts/Toast/ToastProvider';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

function App() {
	return (
		<ToastProvider>
			<CssBaseline />
			<>
				<Spinner />
				<MainPage />
			</>
		</ToastProvider>
	);
}

export default App;
