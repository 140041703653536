import { Fieldset } from 'primereact/fieldset';
import DashboardProps from '../Interfaces/Components/DashboardProps.interface';

export const DashboardGeneric = ({ fieldsData, fieldsLabel }: DashboardProps) => {
    return (
        <div className="grid flex">
            {fieldsLabel.map(
                (f: { field: string; label: string; defaultValue?: string, collapsed?: boolean, col?: string }) =>
                    (fieldsData[f.field] != null || f.defaultValue) && (
                        <div className={f.col ? `${f.col} card m-1` : "col-12 card m-0"} key={f.field}>
                            {f.collapsed ?
                                <Fieldset legend={f.label} toggleable collapsed>
                                    <p>
                                        {fieldsData[f.field] != null
                                            ? fieldsData[f.field]
                                            : f.defaultValue}
                                    </p>
                                </Fieldset> :
                                <Fieldset legend={f.label} toggleable>
                                    <p>
                                        {fieldsData[f.field] != null
                                            ? fieldsData[f.field]
                                            : f.defaultValue}
                                    </p>
                                </Fieldset>
                            }
                        </div>
                    )
            )}
        </div>
    );
};
