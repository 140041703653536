import { useMemo } from 'react';
import {
	// statusParseDates,
	statusParseHelper
} from '../../../Helpers/StatusParseHelper';
import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import DevicesDashboardConfig from '../../../Configs/Dashboards/DevicesDashboardConfig.json';
import { DevicesFieldsResponse } from '../../../Interfaces/Response/devicesDashboard/DevicesFields.interface';

interface DevicesInfoPropsInterface {
	fieldsGeneralInfo: DevicesFieldsResponse;
}

export const DevicePerformanceInfo = ({ fieldsGeneralInfo }: DevicesInfoPropsInterface) => {
	const fieldsData = fieldsGeneralInfo?.deviceFallInfoDto;
	const fieldsPowerSupply = fieldsGeneralInfo?.powerSupplyInfo;
	const fieldsDisconnectedPort = fieldsGeneralInfo?.disconnectedPortInfo;
	const fieldsCriticalTemperature = fieldsGeneralInfo?.criticalTemperatureInfo;
	const fields = useMemo(() => {
		return statusParseHelper(fieldsData);
	}, [fieldsData]);
	const fieldsSupply = useMemo(() => {
		return statusParseHelper(fieldsPowerSupply);
	}, [fieldsPowerSupply]);
	const fieldsDisconnectedPortInfo = useMemo(() => {
		return statusParseHelper(fieldsDisconnectedPort);
	}, [fieldsDisconnectedPort]);
	// const fieldsCriticalTemperature = useMemo(() => {
	// 	return statusParseDates(fieldsCritical);
	// }, [fieldsDisconnectedPort]);
	const productType = fieldsGeneralInfo?.productType;

	return (
		<div className="grid m-0">
			<div
				className="col-12"
				style={{ textAlign: 'start' }}>
				<div className="card p-3 grid">
					<div className="col-12">
						<h5>Performance de la red</h5>
					</div>
					<div
						className="col-12"
						style={{ padding: '15px' }}>
						{fields && (
							<DashboardGeneric
								fieldsData={fields ? fields : null}
								fieldsLabel={DevicesDashboardConfig.isFall}
							/>
						)}
					</div>
					{fieldsSupply && (
						<div
							className="col-12"
							style={{ padding: '15px' }}>
							<DashboardGeneric
								fieldsData={fieldsSupply}
								fieldsLabel={DevicesDashboardConfig.powerSupply}
							/>
						</div>
					)}
					{fieldsCriticalTemperature && (
						<div
							className="col-12"
							style={{ padding: '15px' }}>
							<DashboardGeneric
								fieldsData={fieldsCriticalTemperature}
								fieldsLabel={DevicesDashboardConfig.criticalTemperature}
							/>
						</div>
					)}
					{fieldsDisconnectedPortInfo && productType === 'switch' ? (
						<div
							className="col-12"
							style={{ padding: '15px' }}>
							<DashboardGeneric
								fieldsData={fieldsDisconnectedPortInfo}
								fieldsLabel={DevicesDashboardConfig.disconnectedPort}
							/>
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
