import { TreeTableConstants } from '../Constants/TreeTableConstants';
import { BuildingTreeResponse } from '../Interfaces/Response/organizationTree/BuildingTreeResponse.interface';
import { FacultyTreeResponse } from '../Interfaces/Response/organizationTree/FacultyTreeResponse.interface';
import { NetworkTreeResponse } from '../Interfaces/Response/organizationTree/NetworkTreeResponse.interface';
import { OrganizationTreeResponse } from '../Interfaces/Response/organizationTree/OrganizationTreeResponse.interface';

export const convertOrganizationData = (data: OrganizationTreeResponse) => {
    if (data.faculties) {
        data.faculties.length > 0 &&
            data.faculties.forEach((f: FacultyTreeResponse) => {
                getTreeStructure(f, TreeTableConstants.FACULTY_LABEL);
                if (f.buildings && f.buildings.length > 0) {
                    f.buildings.forEach((b: BuildingTreeResponse) => {
                        getTreeStructure(b, TreeTableConstants.BUILDING_LABEL);
                        b.networks &&
                            b.networks.forEach((n: NetworkTreeResponse) => {
                                getTreeStructure(n, TreeTableConstants.NETWORK_LABEL);
                            });
                        getChildren(b, TreeTableConstants.BUILDING_CHILDREN_LABEL);
                    });
                    getChildren(f, TreeTableConstants.FACULTY_CHILDREN_LABEL);
                } else {
                    f.networks &&
                        f.networks.forEach((n: NetworkTreeResponse) => {
                            getTreeStructure(n, TreeTableConstants.NETWORK_LABEL);
                        });
                    getChildren(f, TreeTableConstants.BUILDING_CHILDREN_LABEL);
                }
            });
    }
    return data;
};

export const unconvertOrganizationData = (data: any) => {
    let withBuildings = false;
    data[TreeTableConstants.ESTABLISHMENTS_LABEL] = data.faculties;
    data.establishmentsTree.forEach((f: any) => {
        getOldStructure(f);
        f.children &&
            f.children.forEach((b: any) => {
                if (b.data.entity === TreeTableConstants.BUILDING_LABEL) {
                    withBuildings = true;
                    b.children &&
                        b.children.forEach((n: any) => {
                            getOldStructure(n);
                        });
                    getOldChildren(b, TreeTableConstants.BUILDING_CHILDREN_LABEL);
                } else withBuildings = false;
                getOldStructure(b);
            });
        withBuildings
            ? getOldChildren(f, TreeTableConstants.FACULTY_CHILDREN_LABEL)
            : getOldChildren(f, TreeTableConstants.BUILDING_CHILDREN_LABEL);
    });
    delete data[TreeTableConstants.FACULTY_NEW_PROPERTY];
    return data;
};

const getTreeStructure = (data: any, entityName: string) => {
    data[TreeTableConstants.DATA_PROPERTY] = { entity: entityName, name: data.name };
    if (entityName === TreeTableConstants.NETWORK_LABEL){
        data[TreeTableConstants.KEY_LABEL] = data[TreeTableConstants.NETWORKID_LABEL];
        delete data[TreeTableConstants.NETWORKID_LABEL];
    }   
    else{
        data[TreeTableConstants.KEY_LABEL] = data[TreeTableConstants.ID_LABEL];
        delete data[TreeTableConstants.ID_LABEL];
    } 
    delete data['name'];
};

const getChildren = (data: any, oldChildrenLabel: string) => {
    data[TreeTableConstants.CHILDREN_LABEL] = data[oldChildrenLabel];
    delete data[oldChildrenLabel];
};

const getOldStructure = (data: any) => {
    data['name'] = data.data.name;
    data[TreeTableConstants.ID_LABEL] = data[TreeTableConstants.KEY_LABEL];
    delete data[TreeTableConstants.KEY_LABEL];
    delete data['data'];
};

const getOldChildren = (data: any, childrenLabel: string) => {
    data[childrenLabel] = data[TreeTableConstants.CHILDREN_LABEL];
    delete data[TreeTableConstants.CHILDREN_LABEL];
};
