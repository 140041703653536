// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/assets/layout/fonts/Inter-UI-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/assets/layout/fonts/Inter-UI-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/assets/layout/fonts/Inter-UI-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../public/assets/layout/fonts/Inter-UI-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  font-family: 'Inter UI' !important;\n    font-style: normal !important;\n    font-weight: 400 !important;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype'), /* IE6-IE8 */\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2'), /* Super Modern Browsers */\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'), /* Modern Browsers */\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype'); /* Safari, Android, iOS */\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;IAChC,6BAA6B;IAC7B,2BAA2B;IAC3B;;;8DAG4E,EAAE,yBAAyB;AAC3G","sourcesContent":["body {\n  font-family: 'Inter UI' !important;\n    font-style: normal !important;\n    font-weight: 400 !important;\n    src: url(\"../public/assets/layout/fonts/Inter-UI-Regular.otf\") format('opentype'), /* IE6-IE8 */\n    url(\"../public/assets/layout/fonts/Inter-UI-Regular.woff2\") format('woff2'), /* Super Modern Browsers */\n    url(\"../public/assets/layout/fonts/Inter-UI-Regular.woff\") format('woff'), /* Modern Browsers */\n    url(\"../public/assets/layout/fonts/Inter-UI-Regular.ttf\") format('truetype'); /* Safari, Android, iOS */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
