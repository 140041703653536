import { FC, useEffect } from 'react';
import { getFormat } from '../../../Helpers/ChartDataHelper';
import { useTable } from '../../../Hooks/useTable';
import Props from '../../../Interfaces/State/Props.interface';
import { PaginationLimitsInterface } from '../../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { OrganizationResponse } from '../../../Interfaces/Response/organizationTable/OrganizationResponse.interface';
import { OrganizationTableService } from '../../../Services/Search/OrganizationSearchService';
import OrganizationTableContext from './OrganizationTableContext';

export const OrganizationTableState: FC<Props> = ({ children }) => {
    const tableInfo = useTable<OrganizationResponse>();
    const { paginationProps } = tableInfo;

    const initialLimits: PaginationLimitsInterface = {
        limit: paginationProps.limit,
        skip: 0, page: 0
    };

    useEffect(() => {
        getOrganizationsData(initialLimits);
    }, []);

    const getOrganizationsData = async (
        limits: PaginationLimitsInterface,
    ) => {
        const data = {
            paginationProps: {
                limit: limits.limit ? limits.limit : paginationProps.limit,
                skip: limits.skip ? limits.skip : tableInfo.getSkip(),
            },
        }
        const response = await OrganizationTableService.getOrganizations(data);
        response.data.forEach((d: OrganizationResponse) => {
            d.createdAt = getFormat(d.createdAt, 'DD-MM-yyyy HH:mm:ss')
        })
        tableInfo.onChange(response.data, data.paginationProps.limit, limits.page, response.count)
    };

    const onChangePage = (limit: number, page: number) => {
        const skip = tableInfo.getSkip(limit, page);
        getOrganizationsData({ limit, skip, page })
    }

    return (
        <OrganizationTableContext.Provider
            value={{
                organizations: tableInfo.data,
                paginationProps: paginationProps,
                getOrganizationsData,
                onChangePage
            }}>
            {children}
        </OrganizationTableContext.Provider>
    );
};
