const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
const passwordRegex = new RegExp(/^(?=\w*\d)(?=\w*[a-z])\S{8,}$/);
const nameRegex = new RegExp(/^(?=.{3,15}$)[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?:[A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$/);
const usernameRegex = new RegExp(/^[a-zA-Z0-9]{1,}$/);
const numberRegex = new RegExp(/^[0-9]*$/);
const treeNameRegex = new RegExp(/^[A-Za-z0-9\s]+$/);

export const passwordValidate = (pass: string) => {
    return !passwordRegex.test(pass);
}

export const emailValidate = (email: string) => {
    return !emailRegex.test(email);
}

export const usernameValidate = (username: string) => {
    return !usernameRegex.test(username);
}

export const nameValidate = (name: string) => {
    return !nameRegex.test(name);
}

export const dniValidate = (document: string) => {
    return !(document.length >= 7) || !(document.length <= 9) || !numberRegex.test(document);
}

export const numberValidate = (number: string) => {
    return !numberRegex.test(number)
}

export const treeNameValidate = (name: string) => {
    return !treeNameRegex.test(name);
}