import { useContext, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { TableComponentGeneric } from '../../../Components/TableGeneric';
import EstablishmentContext from '../../../Contexts/Establishment/Search/EstablishmentContext';
import { useNavigate } from 'react-router';
import { EstablishmentContextType } from '../../../Interfaces/Context/EstablishmentContextType.interface';
import EstablishmentTableConfig from '../../../Configs/Tables/EstablishmentTableConfig.json';
import { Dropdown } from 'primereact/dropdown';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { useAuth } from '../../../Hooks/useAuth';
import useDebounce from '../../../Hooks/useDebounce';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { getMissingNames } from '../../../Helpers/TableHelper';
import { EstablishmentDialog } from '../../../Components/EstablishmentDialog/EstablishmentDialog';

export const EstablishmentTable = () => {
	const { establishments, activateFilterSearch, onChangePage, paginationProps, establishmentTypes, resetPagination, downloadReport } = useContext(
		EstablishmentContext
	) satisfies EstablishmentContextType;
	const { data: organizations } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const navigate = useNavigate();
	const tableEstablishments = useMemo(
		() => getMissingNames(establishments, [], organizations, establishments),
		[establishments, organizations]
	);
	const [organizationFilter, setOrganizationFilter] = useState('');
	const [establishmentFilter, setEstablishmentFilter] = useState('');
	const [establishmentTypeFilter, setEstablishmentTypeFilter] = useState('');
	const { disabledDropdown, checkIsUserConsulta } = useAuth();

	const [localIndex, setLocalIndex] = useState(-1);
	const [showDialogReport, setShowDialogReport] = useState(false);

	const [count, setCount] = useState(10);

	useDebounce(() => activateFilterSearch(organizationFilter, establishmentFilter, establishmentTypeFilter), 3000, [count]);

	const navigateDashboard = (index: number) => {
		navigate(`${RoutesConstants.ESTABLISHMENT_PAGE}/${establishments[index].id}`);
	};

	const downloadPdf = (index: number) => {
		setLocalIndex(index);
		setShowDialogReport(true);
	};

	const actions = [
		{
			icon: 'pi-chart-bar',
			class: 'p-button-info',
			action: navigateDashboard
		},
		{
			icon: 'pi-file-pdf',
			class: 'p-button-success',
			action: downloadPdf
		}
	];

	const handlePaginationChange = (limit: number, skip: number, page: number) => {
		onChangePage(limit, page);
	};

	return (
		<div className="m-3">
			<div className="card p-fluid">
				<div style={{ textAlign: 'center' }}>
					<h3
						className="mb-6"
						style={{ color: '#495057' }}>
						Buscador de establecimientos
					</h3>
				</div>
				{!checkIsUserConsulta() && (
					<div className="grid formgrid">
						<div className="col-4">
							<h5
								className="mb-2"
								style={{ color: '#495057' }}>
								Establecimientos
							</h5>
							<i className="pi pi-search" />
							<InputText
								type="search"
								onInput={(e) => {
									setEstablishmentFilter(e.currentTarget.value);
									setCount((c) => c + 1);
								}}
								style={{ marginLeft: 3, width: '95%', height: '35px' }}
								placeholder="Buscar por nombre de establecimiento"
								disabled={disabledDropdown()}
							/>
						</div>
						<div className="col-4">
							<h5
								className="mb-2"
								style={{ color: '#495057' }}>
								Organizaciones
							</h5>
							<Dropdown
								value={organizationFilter}
								onChange={(e) => {
									setOrganizationFilter(e.value);
									activateFilterSearch(e.value, establishmentFilter, establishmentTypeFilter);
								}}
								options={organizations}
								optionLabel="name"
								optionValue="_id"
								placeholder="Organización"
								emptyMessage={'No se encontraron organizaciones'}
								filter
								filterBy="name"
								showClear
								disabled={disabledDropdown()}
							/>
						</div>
						<div className="col-4">
							<h5
								className="mb-2"
								style={{ color: '#495057' }}>
								Tipo de establecimiento
							</h5>
							<Dropdown
								value={establishmentTypeFilter}
								onChange={(e) => {
									setEstablishmentTypeFilter(e.value);
									activateFilterSearch(organizationFilter, establishmentFilter, e.value);
								}}
								options={establishmentTypes}
								optionLabel="name"
								optionValue="id"
								placeholder="Tipo de establecimiento"
								emptyMessage={'No se encontraron tipos de establecimientos'}
								showClear
								disabled={disabledDropdown()}
							/>
						</div>
					</div>
				)}
			</div>
			<TableComponentGeneric
				columns={EstablishmentTableConfig.tableConfig}
				rows={tableEstablishments ? tableEstablishments : []}
				actions={actions}
				emptyMessage={'No hay establecimientos'}
				paginationProps={paginationProps}
				handleChange={handlePaginationChange}
				resetPagination={resetPagination}
			/>
			{showDialogReport && (
				<EstablishmentDialog
					info={establishments[localIndex]}
					id={localIndex}
					showDialog={showDialogReport}
					setShowDialog={setShowDialogReport}
					action={(index, month, year) => downloadReport(establishments[index], month, year, 'ALL')}
				/>
			)}
		</div>
	);
};
