import { Checkbox } from "primereact/checkbox";
import { useState } from 'react';
import { MapHeaderInteface } from "../../Interfaces/Pages/Map/MapHeader.interface";

export const MapHeader = ({ setShowAPsFallen }: MapHeaderInteface) => {

    const [value, setValue] = useState(false);

    const setAPsFallenOnMap = (check: boolean) => {
        setShowAPsFallen(check);
        setValue(check)
    }

    return (
        <div>
            <div className="card p-2 grid">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                    <h4 className="m-0" style={{ color: '#495057' }}>Mapa de dispositivos</h4>
                </div>
                <div className="col-12">
                    <label htmlFor="chkbox1"
                        style={{ marginRight: 5, color: '#495057', fontSize: '14px' }}>
                        <b>Mostrar únicamente los APs caídos</b>
                    </label>
                    <Checkbox inputId="chkbox1"
                        checked={value}
                        onChange={(e) => setAPsFallenOnMap(e.checked)} />
                </div>
            </div>
        </div>
    )
}
