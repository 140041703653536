import { ChartConstants } from '../Constants/ChartConstants';
import { GraphicDataInterface } from '../Interfaces/Helpers/GraphicData.interface';
import { DataChartResponse } from '../Interfaces/Response/DataChartResponse.interface';
import moment from 'moment';
import { GraphicDatasetsDataInterface } from '../Interfaces/Helpers/GraphicDatasetsData.interface';
import { GraphicDatasetsInterface } from '../Interfaces/Helpers/GraphicDatasets.interface';
import { UplinksUsageInterface } from '../Interfaces/Response/uplinks/uplinksUsage.interface';

export const convertChartData = (data: DataChartResponse[], name = ['Clientes conectados']) => {
	const newData: GraphicDataInterface = { datasets: [] };
	if (data) {
		const copyDataArray = JSON.parse(JSON.stringify(data));
		copyDataArray.forEach((element: GraphicDatasetsDataInterface[]) => {
			element.forEach((points: GraphicDatasetsDataInterface) => {
				getPoints(points);
			});
			newData.datasets.push({ data: element });
		});
		newData.datasets.forEach((d: GraphicDatasetsInterface, index: number) => {
			getProperties(d, name[index]);
		});
	}
	return newData satisfies GraphicDataInterface;
};

const getRandomColor = () => {
	return '#' + ('00000' + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
};

const getProperties = (data: any, name: string) => {
	data[ChartConstants.LABEL] = name;
	data[ChartConstants.BORDER_COLOR] = getRandomColor();
	data[ChartConstants.BORDER_WIDTH] = ChartConstants.BORDER_WIDTH_VALUE;
	// data[ChartConstants.FILL] = ChartConstants.FILL_VALUE;
	data[ChartConstants.TENSION] = ChartConstants.TENSION_VALUE;
};

const getPoints = (points: any) => {
	points[ChartConstants.X_LABEL] = points[ChartConstants.DATE];
	points[ChartConstants.Y_LABEL] = points[ChartConstants.COUNT];
	delete points[ChartConstants.DATE];
	delete points[ChartConstants.COUNT];
};

export const getFormat = (date: Date, format = 'yyyy-MM-DD HH:mm:ss') => {
	return moment(date).format(format);
};

export const getArrayFormatLogin = (data: any) => {
	const newData = [];
	newData.push(data.loginsSuccess);
	newData.push(data.loginFailure);
	return newData;
};

export const getArrayFormatLossInfo = (data: any) => {
	const newData = [];
	newData.push(data.lossArray);
	newData.push(data.latencyArray);
	return newData;
};

export const getArrayFormatTrafficData = (data: any) => {
	const newData = [];
	newData.push(data.downStreamArray);
	newData.push(data.upStreamArray);
	return newData;
};

export const getArrayUplinksUsageData = (data: Record<string, DataChartResponse>): UplinksUsageInterface => {
	return { keys: Object.keys(data).map((x) => x.replace('_', ' ')), values: Object.values(data) };
};
