import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { TreeTableConstants } from '../../../../Constants/TreeTableConstants'
import { FormAddNewInterface } from '../../../../Interfaces/Pages/Tree/FormAddNew.interface';
import { useMemo, useState } from 'react';
import { treeNameValidate } from '../../../../Helpers/ValidationsHelper';
import { Dropdown } from 'primereact/dropdown';

export const FormAddNew = ({ label, action, networksOptions }: FormAddNewInterface) => {

    const [name, setName] = useState(null);
    const title = `${label == TreeTableConstants.ADD_FACULTY ? 'de la facultad' : 'del edificio'}`;
    const inputLabel = `${label == TreeTableConstants.ADD_FACULTY ? 'Facultad' : 'Edificio'}`;
    const [selectValue, setSelectValue] = useState(null);
    const errorName = useMemo(() => name != null ? treeNameValidate(name) : false, [name]);

    return (
        <div className="grid p-fluid">
            <div className="col-12 sm:col-12 md:col-12">
                <div className="col-12 sm:col-12 md:col-12 logo-container">
                    {label != TreeTableConstants.ASSOCIATE_NETWORK ?
                        <span className="guest-sign-inguest-sign-in">Ingresá el nombre {title} que querés agregar.</span> :
                        <span className="guest-sign-inguest-sign-in">Seleccioná la red que querés agregar.</span>
                    }
                </div>
                <div className="col-12 md:col-12 username-container">
                    {label != TreeTableConstants.ASSOCIATE_NETWORK && <label>{inputLabel}</label>}
                    <div className="login-input">
                        {label != TreeTableConstants.ASSOCIATE_NETWORK ?
                            <InputText id="input" type="text" required onChange={(e: any) => setName(e.target.value)} /> :
                            <Dropdown
                                value={selectValue}
                                onChange={(e) => setSelectValue(e.value)}
                                options={networksOptions}
                                optionLabel="name"
                                placeholder="Seleccione una red"
                            />
                        }
                    </div>
                </div>
                <div className="col-12 sm:col-6 md:col-12"
                    style={{ marginBottom: 15, marginTop: 15 }}
                >
                    <Button label="Agregar" icon="pi pi-check"
                        disabled={
                            (label != TreeTableConstants.ASSOCIATE_NETWORK && name == null)
                            || (label == TreeTableConstants.ASSOCIATE_NETWORK && selectValue == null)
                            || errorName
                        }
                        onClick={() => name ? action(label, name) : action(label, selectValue)} />
                </div>
            </div>
        </div>
    )
}
