import { FC, useEffect, useState } from 'react';
import { FormConstants } from '../../Constants/FormConstants';
import Props from '../../Interfaces/State/Props.interface';
import { NewPasswordInterface } from '../../Interfaces/Request/UserRequest/newPasswordRequest.interface';
import { PaginationLimitsInterface } from '../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { RolResponse } from '../../Interfaces/Response/rol/RolResponse.interface';
import { UserTableResponse } from '../../Interfaces/Response/user/userTableResponse.interface';
import { RolService } from '../../Services/RolService';
import { UsersService } from '../../Services/UsersService';
import UserContext from './UserContext';
import { useTable } from '../../Hooks/useTable';
import { toast } from 'react-toastify';

export const UserState: FC<Props> = ({ children }) => {
	const [roles, setRoles] = useState<RolResponse[]>([]);
	const [dniFilter, setDniFilter] = useState('');
	const [usernameFilter, setUsernameFilter] = useState('');
	const tableInfo = useTable<UserTableResponse>();
	const { paginationProps } = tableInfo;
	const initialLimits: PaginationLimitsInterface = { limit: paginationProps.limit, skip: 0, page: 0 };

	useEffect(() => {
		getRoles();
	}, []);

	const activateFilterSearch = async (dni: string, username: string) => {
		await getUserByFilterData(initialLimits, roles, dni, username);
		setDniFilter(dni);
		setUsernameFilter(username);
	};

	const onChangePage = (limit: number, page: number) => {
		const skip = tableInfo.getSkip(limit, page);
		getUserByFilterData({ limit, skip, page });
	};

	const getUserByFilterData = async (
		limits: PaginationLimitsInterface,
		allRoles: RolResponse[] = roles,
		newDNIFilter = dniFilter,
		newusernameFilter = usernameFilter
	) => {
		const data = {
			limit: limits.limit ? limits.limit : paginationProps.limit,
			skip: limits.skip ? limits.skip : tableInfo.getSkip(),
			username: newusernameFilter,
			dni: newDNIFilter
		};
		const response = await UsersService.getUserData(data);
		const users = response.data.map((x) => {
			const filterRol = allRoles.filter((r) => r._id === x.role);
			const foundRol = filterRol.length ? filterRol[0].name : null;
			return { ...x, rol: foundRol };
		});
		tableInfo.onChange(users, data.limit, limits.page, response.count);
	};

	const deleteUser = async (index: string) => {
		const body = { _id: tableInfo.data[Number(index)].id };
		if (body._id) {
			await UsersService.deleteUser(body);
			toast.success(FormConstants.SUCCESS_DELETE_USER);
			getUserByFilterData(initialLimits);
		}
	};

	const sendNewPass = async (data: NewPasswordInterface) => {
		const response = await UsersService.newPass(data);
		return response;
	};

	const getRoles = async () => {
		const roles = await RolService.getRoles();
		setRoles(roles);
		getUserByFilterData(initialLimits, roles);
	};

	return (
		<UserContext.Provider
			value={{
				users: tableInfo.data,
				paginationProps: paginationProps,
				getUserByFilterData,
				setDniFilter,
				setUsernameFilter,
				sendNewPass,
				deleteUser,
				activateFilterSearch,
				onChangePage
			}}>
			{children}
		</UserContext.Provider>
	);
};
