import { NetworkSelectResponse } from '../Interfaces/Response/select/NetworkSelectResponse.interface';
import { OrganizationSelectResponse } from '../Interfaces/Response/select/OrganizationSelect.interface';
import { EstablishmentSelectResponse } from '../Interfaces/Response/establishment/establishmentSelectResponse.interface';

export const getMissingNames = (
	data: any,
	networks?: NetworkSelectResponse[],
	organizations?: OrganizationSelectResponse[],
	establishments?: EstablishmentSelectResponse[]
) => {
	data?.forEach((d: any) => {
		if (!d.name) {
			d.name = 'Sin nombre asignado desde meraki';
		}
		if (d.networkId) {
			networks &&
				networks.forEach((n: NetworkSelectResponse) => {
					if (n.networkId == d.networkId) d.networkName = n.name;
				});
		}
		if (d.organizationId) {
			organizations &&
				organizations.forEach((o: OrganizationSelectResponse) => {
					if (o.externalId == d.organizationId || o._id == d.organizationId) d.organizationName = o.name;
				});
		}
		if (d.establishmentId) {
			establishments &&
				establishments.forEach((e: EstablishmentSelectResponse) => {
					if (e.id == d.establishmentId) d.establishmentName = e.name;
				});
		} else {
			d.establishmentName ? d.establishmentName : (d.establishmentName = 'Sin asignar');
		}
	});
	return data;
};
