import { BaseService } from './BaseService';

export const AuthService = {
	async login(body: object): Promise<string> {
		const url = 'auth/login';
		const response = await BaseService.postData(url, body);
		return response as string;
	},
	async logout(body: object): Promise<string> {
		const url = 'auth/logout';
		const response = (await BaseService.postData(url, body)) as { url: string };
		console.log(response);
		window.location.href = response.url;
		return response.url;
	},
	async redirectUBALogin() {
		window.location.href = process.env.REACT_APP_URL_BACKEND + 'auth/redirectLogin';
	}
};
