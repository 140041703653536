import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TableProps from '../Interfaces/Components/TableProps.interface';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Paginator } from 'primereact/paginator';

export const TableComponentGeneric = ({
    columns,
    rows,
    emptyMessage,
    actions,
    paginationProps,
    handleChange,
    resetPagination
}: TableProps) => {

    const [first, setFirst] = useState(0);

    useEffect(() => {
        if (resetPagination) {
            setFirst(0);
            handleChange(paginationProps.limit, null, 0)
        }
    }, [resetPagination])


    const onBasicPageChange = (event: { first: number; page: number }) => {
        setFirst(event.first);
        handleChange(paginationProps.limit, null, event.page)
    };

    const actionsBody = (rowIndex: number) => {
        return (
            <div className="flex align-items-center justify-content-center button-demo">
                {
                    actions && rows ? actions.map((a: { icon: string, class: string, action: any, tooltip: string }) => (
                        <Button icon={"pi " + a.icon} className={"p-button-rounded " + a.class + " p-button-text"} key={rows[rowIndex]._id + a.class}
                            style={{ marginRight: 10 }} onClick={() => a.action(rowIndex)} tooltip={a.tooltip ? a.tooltip : ''} tooltipOptions={{ position: 'bottom' }}
                        />))
                        : null
                }
            </div>
        )

    }

    return (
        <>
            <DataTable
                value={rows}
                className="p-datatable-gridlines"
                showGridlines
                rows={paginationProps.limit}
                dataKey="id"
                filterDisplay="menu"
                responsiveLayout="scroll"
                emptyMessage={emptyMessage}
                editMode="row"
            >
                {columns.map((column: { label: string, field: string }) => {
                    return (<Column
                        key={column.label}
                        header={column.label}
                        style={{ minWidth: '10rem' }}
                        field={column.field}
                        body={column.field === 'actions' ? (data, props) => actionsBody(props.rowIndex) : null}
                    >
                    </Column>);
                })
                }
            </DataTable>
            <Paginator first={first} rows={paginationProps.limit}
                totalRecords={paginationProps.count}
                onPageChange={onBasicPageChange}
            ></Paginator>
        </>
    )
}