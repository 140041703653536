import { EstablishmentSelectResponse } from '../../Interfaces/Response/select/EstablishmentSelectResponse.interface';
import { NetworkSelectResponse } from '../../Interfaces/Response/select/NetworkSelectResponse.interface';
import { OrganizationSelectResponse } from '../../Interfaces/Response/select/OrganizationSelect.interface';
import { BaseService } from '../BaseService';

export const SelectSearchService = {
	async getNetworks(): Promise<NetworkSelectResponse[]> {
		const url = `networks/AllNetworks`;
		const response = await BaseService.getData(url, '', false);
		return response.data as NetworkSelectResponse[];
	},
	async getOrganizations(): Promise<OrganizationSelectResponse[]> {
		const url = `organizations`;
		const response = await BaseService.getData(url, '', false);
		return response.data as OrganizationSelectResponse[];
	},
	async getEstablishments(): Promise<EstablishmentSelectResponse[]> {
		const url = `establishments/select`;
		const response = await BaseService.getData(url, '', false);
		return response.data as EstablishmentSelectResponse[];
	},
	async getDefault(): Promise<any> {
		const url = `organizations/default`;
		const response = await BaseService.getData(url, '', false);
		return response as any;
	}
};
