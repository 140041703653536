import { FC, useEffect, useState } from 'react';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { getDataSearch } from '../../../Helpers/DataSearchHelper';
import { useAuth } from '../../../Hooks/useAuth';
import { useFetch } from '../../../Hooks/useFetch';
import { useTable } from '../../../Hooks/useTable';
import { SearchRequestInterface } from '../../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { PaginationLimitsInterface } from '../../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { EstablishmentsTableResponse } from '../../../Interfaces/Response/establishment/EstablishmentsTableResponse.interface';
import { OrganizationSelectResponse } from '../../../Interfaces/Response/select/OrganizationSelect.interface';
import Props from '../../../Interfaces/State/Props.interface';
import { EstablishmentService } from '../../../Services/Search/EstablishmentService';
import EstablishmentContext from './EstablishmentContext';

export const EstablishmentState: FC<Props> = ({ children }) => {
	const [organizationFilter, setOrganizationFilter] = useState('');
	const [establishmentFilter, setEstablishmentFilter] = useState('');
	const [establishmentTypeFilter, setEstablishmentTypeFilter] = useState('');
	const { data: organizations } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const [resetPagination, setResetPagination] = useState(false);
	const { checkIsUserConsulta, dataEstablishments } = useAuth();
	const tableInfo = useTable<EstablishmentsTableResponse>();
	const { paginationProps } = tableInfo;

	const initialLimits: PaginationLimitsInterface = {
		limit: paginationProps.limit,
		skip: 0,
		page: 0
	};

	const establishmentTypes = [
		{ name: 'Facultad', id: '1' },
		{ name: 'Edificio', id: '2' }
	];

	useEffect(() => {
		getEstablishmentsByFilterData(initialLimits, organizations);
	}, []);

	const activateFilterSearch = async (organization: string, establishment: string, establishmentType: string) => {
		// con esto fijo el filtro por establecimiento en caso de que sea usuario de consulta

		await getEstablishmentsByFilterData(initialLimits, organizations, organization, establishment, establishmentType);
		setOrganizationFilter(organization);
		setEstablishmentFilter(establishment);
		setEstablishmentTypeFilter(establishmentType);
		setResetPagination(true);
	};

	const getEstablishmentsByFilterData = async (
		limits: PaginationLimitsInterface,
		allOrganizations: OrganizationSelectResponse[] = organizations,
		newOrganizationFilter = organizationFilter,
		newEstablishmentFilter = establishmentFilter,
		newEstablishmentTypeFilter = establishmentTypeFilter
	) => {
		const data: SearchRequestInterface = getDataSearch(limits, tableInfo, paginationProps, {
			organizationId: newOrganizationFilter,
			name: newEstablishmentFilter,
			typeEstablishment: newEstablishmentTypeFilter,
			establishmentId: checkIsUserConsulta() ? dataEstablishments[0] : null
		});
		const response = await EstablishmentService.getEstablishmentsData(data);
		const establishments = response.data.map((e) => {
			const organization = allOrganizations?.find((r) => r.id === e.organizationId)?.name;
			const type = e.typeEstablishment == 1 ? 'Facultad' : 'Edificio';
			return { ...e, organization: organization, status: status, typeEstablishment: type };
		});
		tableInfo.onChange(establishments, data.paginationProps.limit, limits.page, response.count);
		setResetPagination(false);
	};

	const onChangePage = (limit: number, page: number) => {
		const skip = tableInfo.getSkip(limit, page);
		getEstablishmentsByFilterData({ limit, skip, page });
	};

	const downloadReport = async (establishment: { id: string; name: string }, month: string, year: number, type: string) => {
		const body = {
			type: type,
			stablishmentId: establishment.id,
			month,
			year
		};
		await EstablishmentService.download(body, `Reporte ${establishment.name}`);
	};

	return (
		<EstablishmentContext.Provider
			value={{
				establishments: tableInfo.data,
				paginationProps: paginationProps,
				getEstablishmentsByFilterData,
				activateFilterSearch,
				onChangePage,
				establishmentTypes,
				resetPagination,
				downloadReport
			}}>
			{children}
		</EstablishmentContext.Provider>
	);
};
