import { DeviceSearchState } from "../../../Contexts/Devices/Search/DeviceSearchState"
import { TableDevices } from "./TableDevices"

export const DeviceSearchPage = () => {

    return (
        <DeviceSearchState>
            <TableDevices/>
        </DeviceSearchState>
    )
}