import { OrganizationResponse } from '../../Interfaces/Response/organizationTable/OrganizationResponse.interface';
import { BaseService } from '../BaseService';
import { ResponseList } from '../../Interfaces/Response/ResponseList';
import { RequestOrganizationInterface } from '../../Interfaces/Request/OrganizationRequest/requestOrganization.interface';

export const OrganizationTableService = {
    async getOrganizations(data: RequestOrganizationInterface): Promise<ResponseList<OrganizationResponse>> {
        const url = `organizations`;
        const qs = `?limit=${data.paginationProps.limit}&skip=${data.paginationProps.skip}`;
        const response = await BaseService.getData(url, qs);
        return { data: response.data as OrganizationResponse[], count: response.count };
    }
};