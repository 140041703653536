import { useContext, useState } from 'react';
import OrganizationTreeContext from "../../../../Contexts/Organization/Tree/OrganizationTreeContext";
import { OrganizationTreeContextType } from "../../../../Interfaces/Context/OrganizationTreeContextType.interface";
import { Dropdown } from 'primereact/dropdown';

export const DropdownComponent = () => {
    
    const { getOrganizationById, organizations } = useContext(OrganizationTreeContext) as OrganizationTreeContextType;
    const [value, setValue] = useState();
    const onSelect = (id: string) =>{
        getOrganizationById(id)
    }
    
    return (
        <div className="card p-fluid">
            <h5>Elija la organización</h5>
            <div className="grid formgrid">
                <div className="col-12">
                    <Dropdown       
                    value={value}             
                    options={organizations} 
                    onChange={(e:any) => {setValue(e.value); onSelect(e.value)}} 
                    optionLabel={'name'} 
                    optionValue={'organizationId'}
                    placeholder={'Seleccione la organización'}
                    />
                </div>
            </div>
        </div>
    )
}