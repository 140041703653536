import { FC, useEffect, useState } from 'react';
import { TreeTableConstants } from '../../../Constants/TreeTableConstants';
import Props from '../../../Interfaces/State/Props.interface';
import RequestTreeInterface from '../../../Interfaces/Request/OrganizationRequest/RequestTree.interface';
import { NetworkAssociateResponse } from '../../../Interfaces/Response/NetworkAssociateResponse.interface';
import { OrganizationSelectResponse } from '../../../Interfaces/Response/select/OrganizationSelect.interface';
import { OrganizationTreeResponse } from '../../../Interfaces/Response/organizationTree/OrganizationTreeResponse.interface';
import { OrganizationTreeService } from '../../../Services/OrganizationTreeService';
import OrganizationTreeContext from './OrganizationTreeContext';

export const OrganizationTreeState: FC<Props> = ({ children }) => {
    const [organizationSelected, setOrganizationSelected] = useState<
        OrganizationTreeResponse | undefined
    >();
    const [organizations, setOrganizations] = useState<OrganizationSelectResponse[]>([]);
    const [networks, setNetworks] = useState<NetworkAssociateResponse[]>([]);

    useEffect(() => {
        getAllOrganizations();
    }, []);

    const getAllOrganizations = async () => {
        const response = await OrganizationTreeService.getAll();
        setOrganizations(response);
    };

    const getOrganizationById = async (id: string) => {
        const response = await OrganizationTreeService.getById(id);
        setOrganizationSelected(response);
        getNetworksByOrganizationId(id);
    };

    const insertNewElement = async (treeData: RequestTreeInterface, type: string) => {
        switch (type) {
            case TreeTableConstants.FACULTY_LABEL:
                return await OrganizationTreeService.addFaculty(treeData);
            case TreeTableConstants.BUILDING_LABEL:
                return await OrganizationTreeService.addBuilding(treeData);
            case TreeTableConstants.NETWORK_LABEL:
                {
                    const response = await OrganizationTreeService.associateNetwork(treeData);
                    if (treeData.organizationId) await getNetworksByOrganizationId(treeData.organizationId);
                    return response
                }
        }
    };

    const deleteElement = async (treeData: RequestTreeInterface, type: string) => {
        let response;
        switch (type) {
            case TreeTableConstants.FACULTY_LABEL:
                response = await OrganizationTreeService.deleteFaculty(treeData);
                if (treeData.organizationId) await getNetworksByOrganizationId(treeData.organizationId);
                return response;
            case TreeTableConstants.BUILDING_LABEL:
                response = await OrganizationTreeService.deleteBuilding(treeData);
                if (treeData.organizationId) await getNetworksByOrganizationId(treeData.organizationId);
                return response;
            case TreeTableConstants.NETWORK_LABEL:
                response = await OrganizationTreeService.deleteNetworkAssociate(treeData);
                if (treeData.organizationId) await getNetworksByOrganizationId(treeData.organizationId);
                return response;
        }
    };

    const getNetworksByOrganizationId = async (id: string) => {
        const response = await OrganizationTreeService.getNetworks(id);
        setNetworks(response);
    };

    return (
        <OrganizationTreeContext.Provider
            value={{
                organizationSelected,
                organizations,
                networks,
                getOrganizationById,
                getNetworksByOrganizationId,
                insertNewElement,
                deleteElement
            }}>
            {children}
        </OrganizationTreeContext.Provider>
    );
};
