import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
	url?: string;
}

export const BackButton = ({ url }: BackButtonProps) => {
	const navigate = useNavigate();

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
			<div style={{ width: '100%', height: '35px' }}>
				<Button
					label="<- Volver"
					onClick={() => {
						if (url) {
							navigate(url);
						} else {
							navigate(-1);
						}
					}}
				/>
			</div>
		</div>
	);
};
