import { Button } from 'primereact/button';
import { useContext } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
// import { ForgotPasswordForm } from './Components/ForgotPassword';
import { FormConstants } from '../../Constants/FormConstants';
import { AppContextType } from '../../Interfaces/Context/appContextType.interface';

export const LoginForm = () => {
	const { onLogin } = useContext(AppContext) as AppContextType;

	return (
		<div className="grid">
			<div className="col-12 sm:col-6 md:col-8 logo-container">
				<span className="guest-sign-in">Bienvenido, para comenzar, debe loguearse con su cuenta UBA</span>
			</div>
			<div className="col-12 sm:col-6 md:col-6">
				<Button
					type="submit"
					label={FormConstants.SIGN_IN_BUTTON_LABEL}
					icon="pi pi-check"
					onClick={() => onLogin()}
				/>
			</div>
		</div>
	);
};
