import { BackButton } from './BackButton';

export const DashboardHeader = ({ title }: { title: string }) => {
	return (
		<div
			className="p-2 m-1 grid"
			style={{ textAlign: 'center' }}>
			<div>
				<BackButton />
			</div>
			<div style={{ 
				width:'85%',
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'end'}}>
				<h4
					className="m-0"
					style={{ color: '#495057' }}>
					Dashboard de {title}
				</h4>
			</div>
		</div>
	);
};
