import { FC, useEffect, useState } from 'react';
import Props from '../../../Interfaces/State/Props.interface';
import DeviceSearchContext from './DeviceSearchContext';
import { DeviceSearchService } from '../../../Services/Search/DeviceSearchService';
import { useTable } from '../../../Hooks/useTable';
import { DeviceResponse } from '../../../Interfaces/Response/deviceSearch/DeviceResponse';
import { PaginationLimitsInterface } from '../../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { SearchRequestInterface } from '../../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { useAuth } from '../../../Hooks/useAuth';
import { getDataSearch } from '../../../Helpers/DataSearchHelper';
import { IThreshold } from '../../../Interfaces/Response/organization/Organization.interface';
/* import { OrganizationTreeService } from '../../../Services/OrganizationTreeService';
import { LocalStorageService } from '../../../Services/LocalStorageService'; */

export const DeviceSearchState: FC<Props> = ({ children }) => {
	const [organizationBothId, setOrganizationBothId] = useState([]);
	const [thresholds] = useState<IThreshold[]>([]);
	const [organizationIdFilter, setOrganizationIdFilter] = useState('');
	const [networkIdFilter, setNetworkIdFilter] = useState('');
	const [establishmentIdFilter, setEstablishmentIdFilter] = useState('');
	const [deviceTypeFilter, setDeviceTypeFilter] = useState('');
	const [serialFilter, setSerialFilter] = useState('');
	const [resetPagination, setResetPagination] = useState(false);
	const [isRobben, setIsRobben] = useState(false);
	const [count, setCount] = useState<number | undefined>(0);
	const { checkIsUserConsulta, dataEstablishments } = useAuth();
	const tableInfo = useTable<DeviceResponse>();
	const { paginationProps } = tableInfo;
	const initialLimits: PaginationLimitsInterface = {
		limit: paginationProps.limit,
		skip: 0,
		page: 0
	};

	const deviceTypes = [
		{ name: 'Appliance', id: 'appliance' },
		{ name: 'Camera', id: 'camera' },
		{ name: 'Cellular Gateway', id: 'cellularGateway' },
		{ name: 'Sensor', id: 'sensor' },
		{ name: 'Switch', id: 'switch' },
		{ name: 'Systems Manager', id: 'systemsManager' },
		{ name: 'Wireless', id: 'wireless' }
	];

	/* 	Cuando haya mas de 1 organización configurar esto */
	/* useEffect(() => {
		if (organizationBothId.length > 0) {
			const org: any = organizationBothId.find((org_: any) => org_.externalId === organizationIdFilter);
			if (org) {
				getSingleOrganization(org._id);
			} else {
				getSingleOrganization('63b74aeb7496a15df6443bb0');
			}
			return;
		}
		LocalStorageService.remove('thresholds');
	}, [organizationBothId, organizationIdFilter]);

	const getSingleOrganization = async (id: string) => {
		const response = await OrganizationTreeService.getSingleOrganization(id);
		LocalStorageService.set('thresholds', JSON.stringify(response.thresholds));
		setThresholds(response.thresholds);
	}; */

	useEffect(() => {
		console.log(organizationBothId);
		getDevicesByFilterData(initialLimits);
	}, []);

	const onChangePage = (limit: number, page: number) => {
		const skip = tableInfo.getSkip(limit, page);
		getDevicesByFilterData({ limit, skip, page });
	};

	const getDevicesByFilterData = async (
		limits: PaginationLimitsInterface,
		networkId = networkIdFilter,
		organizationId = organizationIdFilter,
		establishmentId = checkIsUserConsulta() ? dataEstablishments[0] : establishmentIdFilter,
		deviceType = deviceTypeFilter,
		serial = serialFilter,
		isStolen = isRobben
	) => {
		const data: SearchRequestInterface = getDataSearch(limits, tableInfo, paginationProps, {
			organizationId: organizationId,
			establishmentId: establishmentId,
			networkId: networkId,
			productType: deviceType,
			serial: serial,
			isStolen: isStolen
		});
		const response = await DeviceSearchService.getAllDevices(data);
		tableInfo.onChange(response.data, data.paginationProps.limit, limits.page, response.count);
		setCount(response.count);
		setResetPagination(false);
	};

	const patchRobbenDevicesData = async (id: string, isStolen: boolean) => {
		const data = { _id: id, isStolen: isStolen };
		await DeviceSearchService.patchRobbenDevices(data);
		await getDevicesByFilterData(
			initialLimits,
			networkIdFilter,
			organizationIdFilter,
			establishmentIdFilter,
			deviceTypeFilter,
			serialFilter,
			isRobben
		);
	};

	const exportSearchDevices = async (filename: string) => {
		const data = {
			paginationProps: {
				limit: count,
				skip: 0
			},
			params: {
				organizationId: organizationIdFilter,
				establishmentId: checkIsUserConsulta() ? dataEstablishments[0] : establishmentIdFilter,
				networkId: networkIdFilter,
				productType: deviceTypeFilter,
				serial: serialFilter,
				isStolen: isRobben,
				exportData: true
			}
		};
		await DeviceSearchService.getDownloadSearchDevices(data, filename);
	};

	const activateFilterSearch = async (
		networkId: string,
		organizationId: string,
		establishmentId: string,
		deviceType: string,
		serial: string,
		isStolen: boolean
	) => {
		await getDevicesByFilterData(initialLimits, networkId, organizationId, establishmentId, deviceType, serial, isStolen);
		setNetworkIdFilter(networkId);
		setOrganizationIdFilter(organizationId);
		setEstablishmentIdFilter(establishmentId);
		setDeviceTypeFilter(deviceType);
		setSerialFilter(serial);
		setResetPagination(true);
		setIsRobben(isStolen);
	};

	return (
		<DeviceSearchContext.Provider
			value={{
				devices: tableInfo.data,
				paginationProps: paginationProps,
				networkIdFilter,
				deviceTypes,
				setNetworkIdFilter,
				onChangePage,
				activateFilterSearch,
				resetPagination,
				patchRobbenDevicesData,
				thresholds,
				setOrganizationBothId,
				exportSearchDevices
			}}>
			{children}
		</DeviceSearchContext.Provider>
	);
};
