import { getArrayFormatTrafficData, getArrayUplinksUsageData } from '../../Helpers/ChartDataHelper';
import { DataChartResponse } from '../../Interfaces/Response/DataChartResponse.interface';
import { DevicesPowersupplyResponse } from '../../Interfaces/Response/devicesDashboard/DevicesPowersupply.interface';
import { NetworkActivesResponse } from '../../Interfaces/Response/networkDashboard/NetworkActives.interface';
import { NetworkGeneralInfoResponse } from '../../Interfaces/Response/networkDashboard/NetworkGeneralInfo.interface';
import { UplinksUsageInterface } from '../../Interfaces/Response/uplinks/uplinksUsage.interface';
import { BaseService } from '../BaseService';

export const NetworkDashboardService = {
	async getSwitchPortStatus(id: string | undefined): Promise<any> {
		const url = `devices/switchstatus`;
		const qs = `?deviceId=${id}`;
		const response = await BaseService.getData(url, qs);
		const newResponse: any = [];
		if (response && Object.keys(response).length > 0) {
			Object.keys(response).forEach((key) => {
				const newArr: any = [];
				newArr.push({ name: key });
				response[key].forEach((item: any) => {
					newArr.push({
						count: item.count,
						date: item.date
					});
				});
				newResponse.push(newArr);
			});
		}
		return newResponse as DataChartResponse[];
	},
	async getNetworkFall(id: string | undefined): Promise<NetworkActivesResponse> {
		const url = `networks/dashboard`;
		const qs = `?networkId=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as NetworkActivesResponse;
	},
	async getGeneralInfo(id: string | undefined): Promise<NetworkGeneralInfoResponse> {
		const url = `networks/network`;
		const qs = `?_id=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as NetworkGeneralInfoResponse;
	},
	async getPowerSupply(id: string | undefined): Promise<DevicesPowersupplyResponse> {
		const url = `devices/powersupplytime`;
		const qs = `?networkId=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as DevicesPowersupplyResponse;
	},
	async getDashboardData(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `networkClients`;
		const qs = `?networkId=${id}`;
		const response = await BaseService.getData(url, qs);
		return [response.data] as DataChartResponse[];
	},
	async getTrafficData(id: string | undefined): Promise<DataChartResponse[]> {
		const url = `networks/bandwidthusage`;
		const qs = `?networkId=${id}`;
		const response = await BaseService.getData(url, qs);
		return getArrayFormatTrafficData(response) as DataChartResponse[];
	},

	async getUplinksUsage(id: string | undefined): Promise<UplinksUsageInterface> {
		const url = `networks/sentReceived`;
		const qs = `?networkId=${id}`;
		const response = await BaseService.getData(url, qs);
		return getArrayUplinksUsageData(response) as UplinksUsageInterface;
	}
};
