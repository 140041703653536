import { Dialog } from 'primereact/dialog';
import DialogHookInterface from '../Interfaces/Hooks/DialogHook.interface';

export const ModalGeneric = ({dialogHook}: DialogHookInterface) => {

  const handleClose = () => {
    dialogHook.CloseDialog();
  };

  return (
    <div>
      <Dialog
        header={dialogHook.header}
        footer={dialogHook.footer}
        visible={dialogHook.showDialog}
        onHide={() => handleClose()}
      >
        {dialogHook.content}
      </Dialog>
    </div>
  );
};
