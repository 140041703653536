export const FormConstants = {
	EMAIL_TYPE: 'email',
	PASSWORD_TYPE: 'password',
	ERROR_USERNAME_MSG: 'Formato de username incorrecto.',
	ERROR_EMAIL_MSG: 'Formato de email incorrecto.',
	ERROR_NAME_MSG: 'Formato de nombre incorrecto.',
	ERROR_LASTNAME_MSG: 'Formato de apellido incorrecto.',
	ERROR_DNI_MSG: 'Formato de dni incorrecto.',
	ERROR_PHONE_MSG: 'Formato de número telefónico incorrecto.',
	ERROR_PASSWORD_MSG: 'Formato incorrecto. La contraseña debe tener como mínimo 8 caracteres, al menos una letra, y un dígito.',
	SEND_EMAIL_MSG_SUCCESS: 'Se le ha enviado un mail para reestablecer su contraseña.',
	CONTINUE_BUTTON_LABEL: 'Continuar',
	SET_PASS_BUTTON_LABEL: 'Establecer Contraseña',
	SIGN_IN_BUTTON_LABEL: 'Redireccionar a login UBA',
	SUCCESS_REGISTER_MSG: 'Usuario registrado de forma exitosa.',
	SUCCESS_EDIT_MSG: 'Usuario actualizado de forma exitosa.',
	SUCCESS_DELETE_USER: 'Usuario eliminado de forma exitosa.',
	INVALID_EMPTY_FIELD: 'El campo es obligatorio'
};
