import './Skeletons.scss';
import { Skeleton } from 'primereact/skeleton';

export const PanelSkeleton = () => {
	return (
		<Skeleton className="skeleton-panel">
			<Skeleton
				className="m-3 "
				width={'90%'}
				height="50px"
			/>
			<Skeleton
				className="m-1 mt-4"
				width={'90%'}
				height="60px"
			/>
			<Skeleton
				className="m-1"
				width={'90%'}
				height="60px"
			/>
			<Skeleton
				className="m-1"
				width={'90%'}
				height="60px"
			/>
		</Skeleton>
	);
};
