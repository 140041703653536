import { SearchRequestInterface } from "../Interfaces/Request/SearchRequest/SearchRequest.interface";
import { PaginationLimitsInterface } from "../Interfaces/Request/UserRequest/paginationLimits.interface";

export const getDataSearch = (limits: PaginationLimitsInterface, tableInfo: { getSkip: () => number; },
    paginationProps: { limit: number; }, params: object) => {
    const data: SearchRequestInterface = {
        paginationProps: {
            limit: limits.limit ? limits.limit : paginationProps.limit,
            skip: limits.skip != null ? limits.skip : tableInfo.getSkip(),
        },
        params: params
    };
    return data;
}
