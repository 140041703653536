import { AlarmState } from '../../../Contexts/Alarm/Search/AlarmState';
import { AlarmsTable } from './AlarmsTable';

export const AlarmPage = () => {
    return (
        <AlarmState>
            <AlarmsTable />
        </AlarmState>
    );
};
