import { Card } from 'primereact/card';
import './AlarmBoard.scss';

export const NoAlarmsCard = ({ title = 'sistema' }: { title?: string }) => {
	return (
		<div>
			<h3 className="estado-dispositivos no-alarm-title">Estado de Dispositivos</h3>
			<div className="panels">
				<Card
					className="m-6 card-no-alarms"
					style={{ width: '90%' }}>
					<div className=" card-no-alarms description">
						<h1 className="card-no-alarms text">{`${title} opera SIN ALARMAS`}</h1>
						<div className="green-btn" />
					</div>
				</Card>
			</div>
		</div>
	);
};
