import { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { useLocation } from 'react-router-dom';
import SideNavbar from './SideNavbar';
import Header from './Header';
import MenuConfig from '../../Configs/MenuConfig.json';
import { useAuth } from '../../Hooks/useAuth';

const LayoutContainer = () => {
    const layoutMode = 'slim';
    const lightMenu = true;
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [isUserConsulta, setIsUserConsulta] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const topbarColor = 'layout-topbar-blue';
    const [configActive, setConfigActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const { checkIsUserConsulta } = useAuth();

    let menuClick;
    let configClick = false;

    useEffect(() => {
        setIsUserConsulta(checkIsUserConsulta());
    }, []);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    const onDocumentClick = () => {

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        menuClick = false;
        configClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (layoutMode === 'overlay') {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }

        event.preventDefault();
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    const isSlim = () => {
        return layoutMode === 'slim';
    };

    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-slim': layoutMode === 'slim',
            'layout-menu-light': lightMenu,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive,
        },
        topbarColor
    );

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Header
                onMenuButtonClick={onMenuButtonClick}
            />
            <div className="layout-menu-container" onClick={onMenuClick}>
                <SideNavbar model={ isUserConsulta ? MenuConfig.menu_user_consulta : MenuConfig.menu_admin} onMenuItemClick={onMenuItemClick} 
                onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} 
                active={menuActive} mobileMenuActive={staticMenuMobileActive} />
            </div>
        </div>
    );
};

export default LayoutContainer;
