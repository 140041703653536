import { ColorRgbEnum } from '../../Enums/ColorRgbEnum';
import { IDataForSelects, IBoardAlarmSelects } from '../Components/AlarmBoard/AlarmBoard.interface';

export const EstablishmentForSelects = (orgData: any) => {
	if (orgData) {
		const establishmentsArrAux: IDataForSelects[] = [];
		orgData?.establishments?.map((org: IBoardAlarmSelects) => {
			const isRed = org.globalState === 'RED';
			const isYellow = org.globalState === 'YELLOW';
			const isGreen = org.globalState === 'GREEN';

			if (isRed) {
				establishmentsArrAux.push({ name: org.name, id: org.id, color: ColorRgbEnum.Red, errorDescription: org.errorDescription });
				return;
			}
			if (isYellow) {
				establishmentsArrAux.push({ name: org.name, id: org.id, color: ColorRgbEnum.Yellow, errorDescription: org.errorDescription });
				return;
			}
			if (isGreen) {
				establishmentsArrAux.push({ name: org.name, id: org.id, color: ColorRgbEnum.Green, errorDescription: org.errorDescription });
				return;
			}
			establishmentsArrAux.push({ name: org.name, id: org.id, color: ColorRgbEnum.NoColor, errorDescription: org.errorDescription });
		});
		const orderEstablishments = establishmentsArrAux.sort((a, b) => a.name.localeCompare(b.name));
		return [...orderEstablishments];
	} else {
		return [];
	}
};
