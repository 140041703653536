import { useState } from 'react';

const DEFAULT_LIMIT = 10;
const DEFAULT_START_PAGE = 0;

//Hook para manejar los estados de la paginación presente en tablas.
//Recibe como parametro el limite inicial, y la página de inicio
export const usePagination = () => {
    const [page, setPage] = useState(DEFAULT_START_PAGE);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    //el count es el total de registros, que es necesario para que el componente paginador funcione correctamente
    const [count, setCount] = useState(0);

    const updateCount = (newCount: number) => {
        setCount(newCount);
    };

    const getSkip = (limitParameter: number = limit, pageParameter: number = page) => {
        return limitParameter*pageParameter
    }

    const updatePagination = (limitParameter: number, pageParameter: number) => {
        if (pageParameter >= 0) {
            setPage(pageParameter)
        }
        if (limitParameter) setLimit(limitParameter);
    };

    const updateLimit = (limit: number) => {
        setLimit(limit);
        setPage(0);
    };

    /*se expone el siguiente objeto
      page : nro pagina
      limit: cantidad de registros por pagina
      count: registros totales
      update count: modifica el total
      updatePagination : modifica el page y limit */
    return { paginationProps: { page, limit, count, default_limit: DEFAULT_LIMIT}, updateCount, updatePagination, updateLimit, getSkip };
};
