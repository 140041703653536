import { EstablishmentGraphics } from './EstablishmentGraphics';
import { EstablishmentGeneralInfo } from './EstablishmentGeneralInfo';
import { EstablishmentPerformanceInfo } from './EstablishmentPerformanceInfo';

export const EstablishmentDashboardPage = () => {

    return (
        <>
            <div className="grid m-auto">
                <div className="col-12">
                    <EstablishmentGeneralInfo />
                </div>
                <div className="col-12">
                    <EstablishmentPerformanceInfo/>
                </div>
                <div className="col-12">
                    <EstablishmentGraphics />
                </div>
            </div>
        </>
    );
};