import { FC, useEffect, useState } from 'react';
import Props from '../../../Interfaces/State/Props.interface';
import { PaginationLimitsInterface } from '../../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { AlarmsTableResponse } from '../../../Interfaces/Response/alarm/AlarmsTableResponse.interface';
import { AlarmService } from '../../../Services/Search/AlarmService';
import AlarmContext from './AlarmContext';
import { useTable } from '../../../Hooks/useTable';
import { SearchRequestInterface } from '../../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { getDataSearch } from '../../../Helpers/DataSearchHelper';
import { useAuth } from '../../../Hooks/useAuth';
import { getFormat } from '../../../Helpers/ChartDataHelper';
import { AlarmConfigService } from '../../../Services/AlarmConfigService';

export interface AlertTypeProps {
	name: string;
	id: string;
}
export const AlarmState: FC<Props> = ({ children }) => {
	const [organization_idFilter, setOrganization_idFilter] = useState('');
	const [organizationIdFilter, setOrganizationIdFilter] = useState('');
	const [networkIdFilter, setNetworkIdFilter] = useState('');
	const [alertTypeFilter, setAlertTypeFilter] = useState('');
	const [establishmentIdFilter, setEstablishmentIdFilter] = useState('');
	const [resetPagination, setResetPagination] = useState(false);
	const { checkIsUserConsulta, dataEstablishments } = useAuth();
	const tableInfo = useTable<AlarmsTableResponse>();
	const { paginationProps } = tableInfo;
	const initialLimits: PaginationLimitsInterface = {
		limit: paginationProps.limit,
		skip: 0,
		page: 0
	};
	const [alertsTypes, setAlertsTypes] = useState<AlertTypeProps[]>([]);

	useEffect(() => {
		setAlertsTypes([]);
		if (!organization_idFilter || organization_idFilter === '') return;
		getAlarmConfigService();
	}, [organization_idFilter]);

	const getAlarmConfigService = async () => {
		const res = await AlarmConfigService.get(organization_idFilter);
		const activeAlerts = res.webhookAlarms.filter((a) => a.isActive);
		if (activeAlerts) {
			const auxAlerts: AlertTypeProps[] = [];
			activeAlerts.forEach((a) => {
				auxAlerts.push({ name: a.alarmType, id: a.alarmType });
			});
			setAlertsTypes(auxAlerts);
		}
	};

	useEffect(() => {
		getAlarmsByFilterData(initialLimits);
	}, []);

	const activateFilterSearch = async (organization: string, network: string, alertType: string, establishmentId: string) => {
		await getAlarmsByFilterData(initialLimits, organization, network, alertType, establishmentId);
		setOrganizationIdFilter(organization);
		setNetworkIdFilter(network);
		setAlertTypeFilter(alertType);
		setEstablishmentIdFilter(establishmentId);
		setResetPagination(true);
	};

	const getAlarmsByFilterData = async (
		limits: PaginationLimitsInterface,
		newOrganizationId = organizationIdFilter,
		newNetworkId = networkIdFilter,
		newAlertType = alertTypeFilter,
		newEstablishmentId = checkIsUserConsulta() ? dataEstablishments[0] : establishmentIdFilter
		/* newEstablishmentId = checkIsUserConsulta() ? establishmentIdFilter : establishmentIdFilter */
	) => {
		const data: SearchRequestInterface = getDataSearch(limits, tableInfo, paginationProps, {
			organizationId: newOrganizationId,
			establishmentId: newEstablishmentId,
			networkId: newNetworkId,
			alertType: newAlertType
		});
		const response = await AlarmService.getAlarmsData(data);
		response?.data?.forEach((d: AlarmsTableResponse) => {
			d.createdAt = getFormat(d.createdAt, 'DD-MM-yyyy HH:mm:ss');
			d.establishmentName = d.establishmentName ? d.establishmentName : 'Sin asignar';
		});
		const alarms = response.data;
		tableInfo.onChange(alarms, data.paginationProps.limit, limits.page, response.count);
		setResetPagination(false);
	};

	const onChangePage = (limit: number, page: number) => {
		const skip = tableInfo.getSkip(limit, page);
		getAlarmsByFilterData({ limit, skip, page });
	};

	return (
		<AlarmContext.Provider
			value={{
				alarms: tableInfo.data,
				paginationProps: paginationProps,
				getAlarmsByFilterData,
				activateFilterSearch,
				onChangePage,
				alertsTypes,
				resetPagination,
				setOrganization_idFilter
			}}>
			{children}
		</AlarmContext.Provider>
	);
};
