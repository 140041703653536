import { FC, useEffect, useState } from 'react';
import { FormConstants } from '../../Constants/FormConstants';
import Props from '../../Interfaces/State/Props.interface';
import { UserFormRequest } from '../../Interfaces/Request/UserRequest/UserFormRequest.interface';
import { EstablishmentSelectResponse } from '../../Interfaces/Response/establishment/establishmentSelectResponse.interface';
import { RolResponse } from '../../Interfaces/Response/rol/RolResponse.interface';
import { UserByIdResponse } from '../../Interfaces/Response/user/userByIdResponse';
import { EstablishmentService } from '../../Services/Search/EstablishmentService';
import { RolService } from '../../Services/RolService';
import { UsersService } from '../../Services/UsersService';
import UserFormContext from './UserFormContext';
import { toast } from 'react-toastify';

export const UserFormState: FC<Props> = ({ children }) => {
	const [user] = useState<UserByIdResponse | null>(null);
	const [establishmentOptions, setEstablishmentOptions] = useState<EstablishmentSelectResponse[]>([]);
	const [rolOptions, setRolOptions] = useState<RolResponse[]>([]);

	useEffect(() => {
		getOptionsData();
	}, []);

	const getUserById = async (id: string): Promise<UserByIdResponse> => {
		const result = await UsersService.getUserById(id);
		return result;
	};

	const getOptionsData = async () => {
		const rolesResponse = await RolService.getRoles();
		const establishmentsOptionsResponse = await EstablishmentService.getEstablishmentsForSelect();
		setRolOptions(rolesResponse);
		setEstablishmentOptions(establishmentsOptionsResponse);
	};

	const insertUserData = async (body: UserFormRequest) => {
		await UsersService.insertUser(body);
		toast.success(FormConstants.SUCCESS_REGISTER_MSG);
	};

	const updateUserData = async (body: UserFormRequest) => {
		await UsersService.updateUser(body);
		toast.success(FormConstants.SUCCESS_EDIT_MSG);
	};

	return (
		<UserFormContext.Provider
			value={{
				insertUserData,
				user,
				updateUserData,
				getUserById,
				rolOptions,
				establishmentOptions
			}}>
			{children}
		</UserFormContext.Provider>
	);
};
