import { useState } from "react"

export const useDialog = () => {
    const [showDialog, setShowDialog] = useState(false)
    const [content, setContent] = useState<JSX.Element | string>();
    const [header, setHeader] = useState<JSX.Element | string>();
    const [footer, setFooter] = useState<JSX.Element | string>();

    const OpenDialog = () => setShowDialog(true);
    const CloseDialog = () => setShowDialog(false);

    return { showDialog, content, header, footer, OpenDialog, CloseDialog, setContent, setHeader, setFooter }
}