import { NetworkGraphics } from './NetworkGraphics';
import { NetworkGeneralInfo } from './NetworkGeneralInfo';
import { NetworkPerformanceInfo } from './NetworkPerformanceInfo';

export const NetworkDashboardPage = () => {

    return (
        <>
            <div className="grid m-auto" style={{background: '#80808054'}}>
                <div className="col-12">
                    <NetworkGeneralInfo />
                </div>
                <div className="col-12">
                    <NetworkPerformanceInfo/>
                </div>
                <div className="col-12">
                    <NetworkGraphics />
                </div>
            </div>
        </>
    );
};