import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useContext, useState } from 'react';
import { TableComponentGeneric } from '../../../Components/TableGeneric';
import UserContext from '../../../Contexts/Users/UserContext';
import { useNavigate } from 'react-router';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { UserContextType } from '../../../Interfaces/Context/UserContextType.interface';
import UsersTableConfig from '../../../Configs/Tables/UsersTableConfig.json';
import { useDialog } from '../../../Hooks/useDialog';
import { ConfirmModal } from '../../../Components/ConfirmModal';
import { ModalGeneric } from '../../../Components/ModalGeneric';
import useDebounce from '../../../Hooks/useDebounce';

export const TableUser = () => {
    const { users, activateFilterSearch, deleteUser, onChangePage, paginationProps } = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();
    const dialog = useDialog();
    const [dniFilter, setDniFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');

    const [count, setCount] = useState(10)
    useDebounce(() => activateFilterSearch(dniFilter, emailFilter), 3000, [count])


    const editUser = (index: number) => {
        navigate(`${RoutesConstants.USERS_ABM}/${users[index].id}`);
    };

    const openModal = (index: number) => {
        dialog.setContent(
            <ConfirmModal dialogHook={dialog} action={() => deleteUser(index)} label={'usuario'} />
        );
        dialog.setHeader('Eliminar Usuario');
        dialog.OpenDialog();
    };

    const actions = [
        {
            icon: 'pi-pencil',
            class: 'p-button-info',
            action: editUser
        },
        {
            icon: 'pi-trash',
            class: 'p-button-danger',
            action: openModal
        }
    ];

    const handlePaginationChange = (limit: number, skip: number, page: number) => {
        onChangePage(limit, page);
    };

    return (
        <>
            <div className='m-3'>
                <div className="card p-fluid">
                    <div style={{ textAlign: 'center' }}>
                        <h3 className="mb-6" style={{ color: '#495057' }}>ABM de usuarios</h3>
                    </div>
                    <div className="grid formgrid">
                        <div className="col-4">
                            <h5 className="mb-2 ml-3" style={{ color: '#495057' }}>DNI</h5>
                            <i className="pi pi-search" style={{ marginLeft: 16 }} />
                            <InputText
                                type="search"
                                onInput={(e) => { setDniFilter(e.currentTarget.value); setCount(c => c + 1) }}
                                style={{ marginLeft: 5, width: '90%', height: '35px' }}
                                placeholder="Buscar por DNI"
                            />
                        </div>
                        <div className="col-4">
                            <h5 className="mb-2 ml-3" style={{ color: '#495057' }}>Username</h5>
                            <i className="pi pi-search" style={{ marginLeft: 16 }} />
                            <InputText
                                type="search"
                                onInput={(e) => { setEmailFilter(e.currentTarget.value); setCount(c => c + 1) }}
                                style={{ marginLeft: 5, width: '90%', height: '35px' }}
                                placeholder="Buscar por username"
                            />
                        </div>
                        <div className="col-2 ml-auto mt-auto">
                            <Button
                                label="Registrar usuario"
                                onClick={() => navigate(RoutesConstants.USERS_ABM)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <TableComponentGeneric
                columns={UsersTableConfig.tableConfig}
                rows={users}
                actions={actions}
                emptyMessage={'No hay usuarios'}
                paginationProps={paginationProps}
                handleChange={handlePaginationChange} />
            <ModalGeneric dialogHook={dialog} />
        </>
    );
};
