import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TableProps from '../../../Interfaces/Components/TableProps.interface';
import { useContext, useEffect, useState } from 'react';
import AlarmConfigContext from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigContext';
import { AlarmConfigContextType } from '../../../Interfaces/Context/AlarmConfigContextType.interface';
import { AlarmConfigConstants } from '../../../Constants/AlarmConfigConstants';
import { thresholdsResponse } from '../../../Interfaces/Response/alarm/thresholdsResponse.interface';
import { InputNumber } from 'primereact/inputnumber';

// COMPONENTE CON SOLO TABLA
export const ConfigComponentTable = ({ rows, emptyMessage, type }: TableProps) => {
	const { setDevices, setNetworks, setEstablishments } = useContext(AlarmConfigContext) satisfies AlarmConfigContextType;
	const [dataRows, setDataRows] = useState<any>();

	useEffect(() => {
		setDataRows(rows);
	}, [rows]);

	const setNewData = (rows: thresholdsResponse[]) => {
		if (type == AlarmConfigConstants.DEVICES) {
			setDevices(rows);
		} else if (type == AlarmConfigConstants.NETWORKS) {
			setNetworks(rows);
		} else if (type == AlarmConfigConstants.ESTABLISHMENTS) {
			setEstablishments(rows);
		}
	};

	const yellowValue = (index: number, data: any) => {
		return (
			<div style={{ textAlign: 'left' }}>
				<InputNumber
					allowEmpty={false}
					inputId="horizontal-buttons"
					value={dataRows[index].yellowValue}
					onValueChange={(e) => {
						dataRows[index].yellowValue = e.value;
						setNewData([...dataRows]);
					}}
					showButtons
					buttonLayout="horizontal"
					step={0.1}
					mode="decimal"
				/>
				<strong style={{ marginLeft: '5px', fontSize: '15px' }}>{data.measureUnit}</strong>
			</div>
		);
	};

	const redValue = (index: number, data: any) => {
		return (
			<div style={{ textAlign: 'left' }}>
				<InputNumber
					allowEmpty={false}
					inputId="horizontal-buttons"
					value={dataRows[index].redValue}
					onValueChange={(e) => {
						dataRows[index].redValue = e.value;
						setNewData([...dataRows]);
					}}
					showButtons
					buttonLayout="horizontal"
					step={0.1}
					mode="decimal"
				/>
				<strong style={{ marginLeft: '5px', fontSize: '15px' }}>{data.measureUnit}</strong>
			</div>
		);
	};

	return (
		<>
			{dataRows && (
				<>
					<DataTable
						value={dataRows}
						className="p-datatable-gridlines"
						showGridlines
						rows={10}
						dataKey="id"
						filterDisplay="menu"
						responsiveLayout="scroll"
						emptyMessage={emptyMessage}
						editMode="row">
						<Column
							header={'Nombre'}
							style={{ minWidth: '10rem' }}
							field={'externalName'}></Column>
						<Column
							header={'Valor del semaforo amarillo'}
							style={{ minWidth: '10rem' }}
							field={'isActive'}
							body={(data, props) => yellowValue(props.rowIndex, data)}></Column>
						<Column
							header={'Valor del semaforo rojo'}
							style={{ minWidth: '10rem' }}
							field={'sendEmail'}
							body={(data, props) => redValue(props.rowIndex, data)}></Column>
					</DataTable>
				</>
			)}
		</>
	);
};
