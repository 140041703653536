import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TableProps from '../../../Interfaces/Components/TableProps.interface';
import { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import AlarmConfigContext from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigContext';
import { AlarmConfigContextType } from '../../../Interfaces/Context/AlarmConfigContextType.interface';

// COMPONENTE CON SOLO TABLA
export const AlarmConfigComponent = ({ rows, emptyMessage }: TableProps) => {
	const [dataRows, setDataRows] = useState<any>();
	const { setAlarms } = useContext(AlarmConfigContext) satisfies AlarmConfigContextType;

	useEffect(() => {
		setDataRows(rows);
	}, [rows]);

	const setSendEmailChecked = (value: boolean, index: number) => {
		const data = [...rows];
		data[index].sendEmail = value;
		rows = data;
		setDataRows(rows);
		setAlarms((prev: any) => {
			const newData = { ...prev };
			newData['webhookAlarms'] = rows;
			return newData;
		});
	};

	const setActiveChecked = (value: boolean, index: number) => {
		const data = [...rows];
		data[index].isActive = value;
		rows = data;
		setDataRows(rows);
		setAlarms((prev: any) => {
			const newData = { ...prev };
			newData['webhookAlarms'] = rows;
			return newData;
		});
	};

	const activeCheckbox = (index: number) => {
		return (
			<div className="flex align-items-center justify-content-center button-demo">
				<Checkbox
					onChange={(e) => setActiveChecked(e.checked, index)}
					checked={dataRows[index].isActive}></Checkbox>
			</div>
		);
	};

	const sendEmailCheckbox = (index: number) => {
		return (
			<div className="flex align-items-center justify-content-center button-demo">
				<Checkbox
					onChange={(e) => setSendEmailChecked(e.checked, index)}
					checked={dataRows[index].sendEmail}></Checkbox>
			</div>
		);
	};

	return (
		<>
			{dataRows && (
				<>
					<DataTable
						value={dataRows}
						className="p-datatable-gridlines"
						showGridlines
						rows={10}
						dataKey="id"
						filterDisplay="menu"
						responsiveLayout="scroll"
						emptyMessage={emptyMessage}
						editMode="row">
						<Column
							header={'Nombre'}
							style={{ minWidth: '10rem' }}
							field={'name'}></Column>
						<Column
							header={'Tipo'}
							style={{ minWidth: '10rem' }}
							field={'alarmType'}></Column>
						<Column
							align={'center'}
							header={'Activar'}
							style={{ minWidth: '10rem' }}
							field={'isActive'}
							body={(data, props) => activeCheckbox(props.rowIndex)}></Column>
						<Column
							align={'center'}
							header={'Enviar email'}
							style={{ minWidth: '10rem' }}
							field={'sendEmail'}
							body={(data, props) => sendEmailCheckbox(props.rowIndex)}></Column>
					</DataTable>
				</>
			)}
		</>
	);
};
