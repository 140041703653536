import RequestTreeInterface from '../Interfaces/Request/OrganizationRequest/RequestTree.interface';
import { NetworkAssociateResponse } from '../Interfaces/Response/NetworkAssociateResponse.interface';
import { OrganizationSelectResponse } from '../Interfaces/Response/select/OrganizationSelect.interface';
import { OrganizationTreeResponse } from '../Interfaces/Response/organizationTree/OrganizationTreeResponse.interface';
import { OrganizationResponse } from '../Interfaces/Response/organizationTable/OrganizationResponse.interface';
import { BaseService } from './BaseService';
import { ResponseList } from '../Interfaces/Response/ResponseList';
import { RequestOrganizationInterface } from '../Interfaces/Request/OrganizationRequest/requestOrganization.interface';
import { IOrganization } from '../Interfaces/Response/organization/Organization.interface';

export const OrganizationTreeService = {
	async getAll(): Promise<OrganizationSelectResponse[]> {
		const url = `organizationsTree/trees`;
		const response = await BaseService.getData(url);
		return response.data as OrganizationSelectResponse[];
	},
	async getById(id: string): Promise<OrganizationTreeResponse> {
		const url = `organizationsTree/ByOrganization/${id}`;
		const response = await BaseService.getData(url);
		return response.data as OrganizationTreeResponse;
	},
	async addFaculty(body: RequestTreeInterface) {
		const url = `organizationsTree/AddFaculties`;
		const response = await BaseService.patchData(url, body);
		return response;
	},
	async addBuilding(body: RequestTreeInterface) {
		const url = `organizationsTree/AddBuilding`;
		const response = await BaseService.patchData(url, body);
		return response;
	},
	async associateNetwork(body: RequestTreeInterface) {
		const url = `organizationsTree/AddNetwork`;
		const response = await BaseService.patchData(url, body);
		return response;
	},
	async update(body: object) {
		const url = `organizations`;
		// TODO: completar url
		const response = await BaseService.putData(url, body);
		return response;
	},
	async deleteFaculty(body: RequestTreeInterface) {
		const url = `organizationsTree/faculty?organizationId=${body.organizationId}&facultyId=${body.facultyId}`;
		const response = await BaseService.deleteData(url);
		return response;
	},
	async deleteBuilding(body: RequestTreeInterface) {
		const url = `organizationsTree/building?organizationId=${body.organizationId}&buildingId=${body.buildingId}`;
		const response = await BaseService.deleteData(url);
		return response;
	},
	async deleteNetworkAssociate(body: RequestTreeInterface) {
		const url = `organizationsTree/network?establishmentId=${body.establishmentId}&networkId=${body.networkId}`;
		const response = await BaseService.deleteData(url);
		return response;
	},
	async getNetworks(id: string): Promise<NetworkAssociateResponse[]> {
		const url = `networks/ToAssociate`;
		const qs = `?organizationId=${id}`;
		const response = await BaseService.getData(url, qs);
		return response.data as NetworkAssociateResponse[];
	},
	async getOrganizations(body: RequestOrganizationInterface): Promise<ResponseList<OrganizationResponse>> {
		//TODO: cambiar a datos reales cuando haya endpoint
		const url = `networks/ToAssociate`;
		const qs = `?organizationId=${body}`;
		const response = await BaseService.getData(url, qs);
		return { data: response.data as OrganizationResponse[], count: response.count };
	},
	async getSingleOrganization(): Promise<IOrganization> {
		const url = `organizations/default`;
		const response = await BaseService.getData(url, '', false);
		return response as IOrganization;
	}
};
