import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import OrganizationDashboardConfig from '../../../Configs/Dashboards/OrganizationDashboardConfig.json';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { DashboardHeader } from '../../../Components/DashboardHeader';

export const OrganizationGeneralInfo = () => {
    const { data: fields } = useFetch(() => FetchKeyConstants.FIELDS_ACTIVE_ESTABLISHMENT, () => FetchConstants.FIELDS_ACTIVE_ORGANIZATION(), true);

    return (
        <div className="grid m-auto">
            <div className="col-12">
                <DashboardHeader title={'organización'} />
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <div className="p-2">
                    {fields &&
                        <DashboardGeneric fieldsData={fields} fieldsLabel={OrganizationDashboardConfig.generalInfo} />
                    }
                </div>
            </div>
        </div>
    );
};
