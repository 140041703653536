import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import Props from '../../Interfaces/State/Props.interface';
import { AuthService } from '../../Services/AuthService';
import { LocalStorageService } from '../../Services/LocalStorageService';
import AppContext from './AppContext';
import jwtDecode from 'jwt-decode';

export const AppState: FC<Props> = ({ children }) => {
	const [token, setToken] = useState(LocalStorageService.get('token') ? LocalStorageService.get('token') : null);
	const user = useMemo(() => {
		if (token) return jwtDecode(token);
		return null;
	}, [token]);

	const navigate = useNavigate();

	const onLogin = async () => {
		await AuthService.redirectUBALogin();
	};

	const onGetToken = async (newToken: string) => {
		debugger;
		setToken(newToken);
		LocalStorageService.set('token', newToken);
		// if (checkIsUserConsulta() === false) {
		// 	getUserInfo(username);
		// }
	};

	const onLogout = async () => {
		await AuthService.logout({ token: token });
		setToken(null);
		LocalStorageService.remove('token');
		navigate(RoutesConstants.LOGIN_PAGE);
	};

	// const getUserInfo = async (username: string) => {
	// 	const data = {
	// 		limit: 1,
	// 		skip: 0,
	// 		username: username,
	// 		dni: ''
	// 	};
	// 	const newUser = await UsersService.getUserData(data);
	// 	setUser(JSON.stringify(newUser.data[0]));
	// 	LocalStorageService.set('user', JSON.stringify(newUser.data[0]));
	// };

	return (
		<AppContext.Provider
			value={{
				onLogin,
				onLogout,
				onGetToken,
				token,
				user
			}}>
			{children}
		</AppContext.Provider>
	);
};
