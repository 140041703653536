import { useParams } from 'react-router-dom';
import { useFetch } from '../../../Hooks/useFetch';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import NetworkDashboardConfig from '../../../Configs/Dashboards/NetworkDashboardConfig.json';

export const NetworkPerformanceInfo = () => {
	const params = useParams();
	const { data: fieldsActivesNetwork } = useFetch(
		() => FetchKeyConstants.FIELDS_ACTIVE_NETWORK(params.id),
		() => FetchConstants.FIELDS_ACTIVE_NETWORK(params.id)
	);
	const { data: fieldsPowerSupply } = useFetch(
		() => FetchKeyConstants.FIELDS_POWERSUPPLY_NETWORK(params.id),
		() => FetchConstants.FIELDS_POWERSUPPLY_NETWORK(params.id)
	);

	return (
		<div className="grid m-auto">
			<div
				className="col-12"
				style={{ textAlign: 'start' }}>
				<div className="card p-3 grid">
					<div className="col-12">
						<h5>Performance de la red</h5>
					</div>
					<div
						className="col-12"
						style={{ padding: '15px' }}>
						{fieldsActivesNetwork && (
							<DashboardGeneric
								fieldsData={fieldsActivesNetwork}
								fieldsLabel={NetworkDashboardConfig.actives}
							/>
						)}
					</div>
					<div
						className="col-12"
						style={{ padding: '15px' }}>
						{fieldsPowerSupply && (
							<DashboardGeneric
								fieldsData={fieldsPowerSupply}
								fieldsLabel={NetworkDashboardConfig.powerSupply}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
