import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import DeviceSearchContext from '../../../Contexts/Devices/Search/DeviceSearchContext';
import { getMissingNames } from '../../../Helpers/TableHelper';
import { useAuth } from '../../../Hooks/useAuth';
import useDebounce from '../../../Hooks/useDebounce';
import { useFetch } from '../../../Hooks/useFetch';
import { DeviceSearchContextType } from '../../../Interfaces/Context/DeviceSearchContextType.interface';
import { NetworkSelectResponse } from '../../../Interfaces/Response/select/NetworkSelectResponse.interface';
import { TableComponent } from './TableComponent';
import { EstablishmentSelectResponse } from '../../../Interfaces/Response/establishment/establishmentSelectResponse.interface';

export const TableDevices = () => {
	const { devices, paginationProps, onChangePage, activateFilterSearch, deviceTypes, resetPagination, setOrganizationBothId, exportSearchDevices } =
		useContext(DeviceSearchContext) satisfies DeviceSearchContextType;
	const { data: organizations } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const { data: establishments } = useFetch(
		() => FetchKeyConstants.ESTABLISHMENTS_LIST,
		() => FetchConstants.ESTABLISHMENTS_LIST()
	);
	const { data: networks } = useFetch(
		() => FetchKeyConstants.NETWORKS_LIST,
		() => FetchConstants.NETWORKS_LIST()
	);
	const tableDevices = useMemo(
		() => getMissingNames(devices, networks, organizations, establishments),
		[devices, networks, organizations, establishments]
	);
	const [networkFilter, setNetworkFilter] = useState('');
	const [organizationFilter, setOrganizationFilter] = useState('');
	const [establishmentFilter, setEstablishmentFilter] = useState('');
	const [serialFilter, setSerialFilter] = useState('');
	const [robbenDevices, setRobbenDevices] = useState(false);
	const [deviceTypeFilter, setDeviceTypeFilter] = useState('');
	const { disabledDropdown, checkIsUserConsulta } = useAuth();
	const [count, setCount] = useState(10);
	useDebounce(
		() =>
			activateFilterSearch(
				networkFilter,
				organizationFilter,
				establishmentFilter,
				deviceTypeFilter,
				serialFilter,
				robbenDevices === true ? robbenDevices : ''
			),
		1500,
		[count]
	);

	const handlePaginationChange = (limit: number, skip: number, page: number) => {
		onChangePage(limit, page);
	};

	useEffect(() => {
		if (organizations?.length > 0) {
			const organizationsBothId: any = [];
			organizations.forEach((organization: any) => {
				organizationsBothId.push({
					_id: organization._id,
					externalId: organization.externalId
				});
			});
			setOrganizationBothId(organizationsBothId);
		}
	}, [organizations]);

	const networksToShow = useMemo(() => {
		const establishmentIds = establishments
			?.filter(
				(establishment: EstablishmentSelectResponse) =>
					establishment.id === establishmentFilter || establishment.parentId === establishmentFilter
			)
			?.map((x: EstablishmentSelectResponse) => x.id);
		return networks?.filter((network: NetworkSelectResponse) => !establishmentFilter || establishmentIds.includes(network.establishmentId));
	}, [networks, establishmentFilter, establishments]);

	return (
		<div className="m-3">
			<div className="card p-fluid">
				<div style={{ textAlign: 'center' }}>
					<h3
						className="mb-6"
						style={{ color: '#495057' }}>
						Buscador de dispositivos
					</h3>
				</div>
				<div className="grid formgrid">
					{!checkIsUserConsulta() && (
						<>
							<div className="col-2">
								<h5
									className="mb-2"
									style={{ color: '#495057' }}>
									Organizaciones
								</h5>
								<Dropdown
									value={organizationFilter}
									onChange={(e: any) => {
										setOrganizationFilter(e.value);
										activateFilterSearch(
											networkFilter,
											e.value,
											establishmentFilter,
											deviceTypeFilter,
											serialFilter,
											robbenDevices === true ? robbenDevices : ''
										);
									}}
									options={organizations}
									optionLabel={'name'}
									optionValue={'externalId'}
									placeholder={'Buscar por organización'}
									emptyMessage={'No se encontraron organizaciones'}
									filter
									filterBy="name"
									showClear
								/>
							</div>
							<div className="col-2">
								<h5
									className="mb-2"
									style={{ color: '#495057' }}>
									Establecimientos
								</h5>
								<Dropdown
									options={establishments}
									value={establishmentFilter}
									onChange={(e: any) => {
										setEstablishmentFilter(e.value);
										//si se selecciona un establecimiento  blanqueo la red
										if (e.value) {
											setNetworkFilter('');
										}
										activateFilterSearch(
											e.value ? '' : networkFilter,
											organizationFilter,
											e.value,
											deviceTypeFilter,
											serialFilter,
											robbenDevices === true ? robbenDevices : ''
										);
									}}
									optionLabel={'name'}
									optionValue={'id'}
									placeholder={'Buscar por establecimiento'}
									emptyMessage={'No se encontraron establecimientos'}
									disabled={disabledDropdown()}
									filter
									filterBy="name"
									showClear
								/>
							</div>
							<div className="col-2">
								<h5
									className="mb-2"
									style={{ color: '#495057' }}>
									Redes
								</h5>
								<Dropdown
									options={networksToShow}
									value={networkFilter}
									onChange={(e: any) => {
										setNetworkFilter(e.value);
										activateFilterSearch(
											e.value,
											organizationFilter,
											establishmentFilter,
											deviceTypeFilter,
											serialFilter,
											robbenDevices === true ? robbenDevices : ''
										);
									}}
									optionLabel={'name'}
									optionValue={'networkId'}
									placeholder={'Buscar por red'}
									emptyMessage={'No se encontraron redes'}
									filter
									filterBy="name"
									showClear
								/>
							</div>
						</>
					)}
					<div className="col-2">
						<h5
							className="mb-2"
							style={{ color: '#495057' }}>
							Tipo de dispositivo
						</h5>
						<Dropdown
							options={deviceTypes}
							value={deviceTypeFilter}
							onChange={(e: any) => {
								setDeviceTypeFilter(e.value);
								activateFilterSearch(
									networkFilter,
									organizationFilter,
									establishmentFilter,
									e.value,
									serialFilter,
									robbenDevices === true ? robbenDevices : ''
								);
							}}
							optionLabel={'name'}
							optionValue={'id'}
							placeholder={'Buscar por tipo'}
							emptyMessage={'No se encontraron tipos de dispositivos'}
							filter
							filterBy="name"
							showClear
						/>
					</div>
					<div className="col-2">
						<h5
							className="mb-2"
							style={{ color: '#495057' }}>
							Serial
						</h5>
						<i className="pi pi-search" />
						<InputText
							type="search"
							onInput={(e) => {
								setSerialFilter(e.currentTarget.value);
								setCount((c) => c + 1);
							}}
							style={{ marginLeft: 5, width: '88%', height: '35px' }}
							placeholder="Buscar por serial"
						/>
					</div>
					<div className="col-2">
						<h5
							className="mb-2"
							style={{ color: '#495057' }}>
							Dispositivos robados
						</h5>
						<div
							className="flex align-items-center"
							style={{ height: '30px' }}>
							<Checkbox
								inputId="robbenDevices"
								onChange={(e) => {
									setRobbenDevices(e.checked);
									activateFilterSearch(
										networkFilter,
										organizationFilter,
										establishmentFilter,
										deviceTypeFilter,
										serialFilter,
										e.checked === true ? e.checked : ''
									);
								}}
								checked={robbenDevices}
							/>
							<label
								htmlFor="robbenDevices"
								className="p-checkbox-label ml-1">
								Ver dispositivos robados
							</label>
						</div>
					</div>
				</div>
				<div className="col-2 ml-auto">
					<Button
						className="p-button-info p-1 mt-3"
						style={{ height: '30px' }}
						label="Exportar en excel"
						onClick={() => exportSearchDevices('Busqueda dispositivos')}
					/>
				</div>
			</div>
			<TableComponent
				rows={tableDevices ? tableDevices : []}
				emptyMessage={'No se encontraron dispositivos'}
				columns={[]}
				paginationProps={paginationProps}
				handleChange={handlePaginationChange}
				resetPagination={resetPagination}></TableComponent>
		</div>
	);
};
