import { DashboardGeneric } from "../../../Components/DashboardGeneric"
import DevicesDashboardConfig from '../../../Configs/Dashboards/DevicesDashboardConfig.json'
import { DevicesFieldsResponse } from "../../../Interfaces/Response/devicesDashboard/DevicesFields.interface";

interface DevicesInfoPropsInterface {
    fieldsGeneralInfo: DevicesFieldsResponse
}

export const DeviceConfigurationInfo = ({ fieldsGeneralInfo }: DevicesInfoPropsInterface) => {

    const configurationChange = fieldsGeneralInfo?.configurationChange;

    return (
        <div className="grid m-auto">
            <div className="col" style={{ textAlign: 'start' }}>
                <div className="card p-3 grid">
                    <div className='col-12'>
                        <h5>Cambio de configuración</h5>
                    </div>
                    <div className='col-12' style={{ padding: '15px' }}>
                        {configurationChange &&
                            <DashboardGeneric
                                fieldsData={configurationChange ? configurationChange : null}
                                fieldsLabel={DevicesDashboardConfig.configurationChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
