import { OrganizationTableState } from '../../../Contexts/Organization/Search/OrganizationTableState';
import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsPage = () => {
    return (
        <OrganizationTableState>
            <OrganizationsTable />
        </OrganizationTableState>
    );
};
