export const AlarmConfigConstants = {
    DEVICES: 'devices',
    NETWORKS: 'networks',
    ESTABLISHMENTS: 'establishments'
}

export const AlarmTypesConstants = {
    UTM_SWITCH: 'UTM_SWITCH',
    APS: 'APS',
    NETWORKS: 'NETWORK',
    ESTABLISHMENTS: 'ESTABLISHMENT'
}