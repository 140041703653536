import { Skeleton } from 'primereact/skeleton';
export const ChartSkeleton = () => {
	return (
		<Skeleton
			className="skeleton-chart"
			width="100%"
			height="450px">
			<Skeleton
				width="100%"
				height="70px"
			/>
			<Skeleton
				width="100%"
				height="100%"
			/>
		</Skeleton>
	);
};
