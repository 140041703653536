import { TableComponentGeneric } from '../../../Components/TableGeneric';
import NetworkTableConfig from '../../../Configs/Tables/NetworksTableConfig.json';
import { useContext, useMemo, useState } from 'react';
import NetworkSearchContext from '../../../Contexts/Network/Search/NetworkSearchContext';
import { NetworkSearchContextType } from '../../../Interfaces/Context/NetworkSearchContextType.interface';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { InputText } from 'primereact/inputtext';
import { getMissingNames } from '../../../Helpers/TableHelper';
import { useAuth } from '../../../Hooks/useAuth';
import useDebounce from '../../../Hooks/useDebounce';
import { useFetch } from '../../../Hooks/useFetch';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';

export const TableNetworks = () => {
    const { networks, paginationProps, activateFilterSearch, onChangePage, resetPagination } =
        useContext(NetworkSearchContext) satisfies NetworkSearchContextType;
    const { data: organizations } = useFetch(() => FetchKeyConstants.ORGANIZATIONS_LIST, () => FetchConstants.ORGANIZATIONS_LIST());
    const { data: establishments } = useFetch(() => FetchKeyConstants.ESTABLISHMENTS_LIST, () => FetchConstants.ESTABLISHMENTS_LIST());
    const tableNetworks = useMemo(() => getMissingNames(networks, networks, organizations, establishments), [networks, organizations, establishments])
    const [organizationFilter, setOrganizationFilter] = useState('');
    const [establishmentFilter, setEstablishmentFilter] = useState('');
    const [networkFilter, setNetworkFilter] = useState('');
    const navigate = useNavigate();
    const { disabledDropdown, checkIsUserConsulta } = useAuth();

    const [count, setCount] = useState(10);
    useDebounce(
        () => activateFilterSearch(organizationFilter, establishmentFilter, networkFilter),
        500,
        [count]
    );

    const navigateDashboard = (index: number) => {
        navigate(`${RoutesConstants.NETWORKS_SEARCH_PAGE}/${networks[index]._id}`);
    };

    const handlePaginationChange = (limit: number, skip: number, page: number) => {
        onChangePage(limit, page);
    };

    const actions = [
        {
            icon: 'pi-chart-bar',
            class: 'p-button-info',
            action: navigateDashboard
        }
    ];

    return (
        <div className="m-3">
            <div className="card p-fluid">
                <div style={{ textAlign: 'center' }}>
                    <h3 className="mb-6" style={{ color: '#495057' }}>
                        Buscador de redes
                    </h3>
                </div>
                <div className="grid formgrid">
                    <div className="col-4">
                        <h5 className="mb-2 ml-3" style={{ color: '#495057' }}>
                            Redes
                        </h5>
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) => {
                                setNetworkFilter(e.currentTarget.value);
                                setCount((c) => c + 1);
                            }}
                            style={{ marginLeft: 3, width: '95%', height: '35px' }}
                            placeholder="Buscar por nombre de red"
                        />
                    </div>
                    {!checkIsUserConsulta() && (
                        <>
                            <div className="col-4">
                                <h5 className="mb-2" style={{ color: '#495057' }}>
                                    Organizaciones
                                </h5>
                                <Dropdown
                                    value={organizationFilter}
                                    onChange={(e: any) => {
                                        setOrganizationFilter(e.value);
                                        activateFilterSearch(
                                            e.value,
                                            establishmentFilter,
                                            networkFilter
                                        );
                                    }}
                                    options={organizations}
                                    optionLabel={'name'}
                                    optionValue={'externalId'}
                                    placeholder={'Buscar por organización'}
                                    emptyMessage={'No se encontraron organizaciones'}
                                    filter
                                    filterBy="name"
                                    showClear
                                />
                            </div>
                            <div className="col-4">
                                <h5 className="mb-2" style={{ color: '#495057' }}>
                                    Establecimientos
                                </h5>
                                <Dropdown
                                    options={establishments}
                                    value={establishmentFilter}
                                    onChange={(e: any) => {
                                        setEstablishmentFilter(e.value);
                                        activateFilterSearch(
                                            organizationFilter,
                                            e.value,
                                            networkFilter
                                        );
                                    }}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    placeholder={'Buscar por establecimiento'}
                                    emptyMessage={'No se encontraron establecimientos'}
                                    filter
                                    filterBy="name"
                                    showClear
                                    disabled={disabledDropdown()}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <TableComponentGeneric
                columns={NetworkTableConfig.tableConfig}
                rows={tableNetworks ? tableNetworks : []}
                actions={actions}
                emptyMessage={'No se encontraron redes'}
                paginationProps={paginationProps}
                handleChange={handlePaginationChange}
                resetPagination={resetPagination}
            />
        </div>
    );
};
