import { InitPasswordInterface } from '../Interfaces/Request/UserRequest/initPasswordRequest.interface';
import { NewPasswordInterface } from '../Interfaces/Request/UserRequest/newPasswordRequest.interface';
import { RequestPassInterface } from '../Interfaces/Request/UserRequest/requestPass.interface';
import { RequestUserInterface } from '../Interfaces/Request/UserRequest/requestUser.interface';
import { UserFormRequest } from '../Interfaces/Request/UserRequest/UserFormRequest.interface';
import { ResponseList } from '../Interfaces/Response/ResponseList';
import { UserByIdResponse } from '../Interfaces/Response/user/userByIdResponse';
import { UserTableResponse } from '../Interfaces/Response/user/userTableResponse.interface';
import { BaseService } from './BaseService';

export const UsersService = {
    async insertUser(body: UserFormRequest): Promise<void> {
        const url = `users`;
        await BaseService.postData(url, body);
    },

    async updateUser(body: UserFormRequest): Promise<void> {
        const url = `users`;
        await BaseService.putData(url, body);
    },

    async getUserById(id: string): Promise<UserByIdResponse> {
        const url = 'users/' + id;
        const response = await BaseService.getData(url);
        return response.data as UserByIdResponse;
    },

    async getUserData(data: RequestUserInterface): Promise<ResponseList<UserTableResponse>> {
        const url = 'users/byFilter';
        const qs = `?limit=${data.limit}&skip=${data.skip}&username=${data.username}&dni=${data.dni}`;
        const response = await BaseService.getData(url, qs);
        return { data: response.data as UserTableResponse[], count: response.count };
    },
    async deleteUser(body: object) {
        const url = `users/delete-user`;
        const response = await BaseService.patchData(url, body);
        return response;
    },
    async requestPass(request: RequestPassInterface) {
        const url = 'users/requestPass';
        await BaseService.postData(url, request);
    },
    async newPass(request: NewPasswordInterface) {
        const url = 'users/newPass';
        const qs = `?username=${request.username}&password=${request.oldPassword}&newPassword=${request.newPassword}`;
        const response = await BaseService.getData(url, qs);
        return response;
    },
    async initPass(requestPasswordObject: InitPasswordInterface): Promise<void> {
        const url = 'users/initUser';
        await BaseService.patchData(url, requestPasswordObject);
    }
};
