import { getSearchQS } from '../../Helpers/GetSearchQSHelper';
import { SearchRequestInterface } from '../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { ResponseList } from '../../Interfaces/Response/ResponseList';
import { AlarmsTableResponse } from '../../Interfaces/Response/alarm/AlarmsTableResponse.interface';
import { BaseService } from '../BaseService';

export const AlarmService = {
    async getAlarmsData(data: SearchRequestInterface): Promise<ResponseList<AlarmsTableResponse>> {
        const url = 'alarms';
        const response = await BaseService.getData(url, getSearchQS(data));
        return { data: response.data as AlarmsTableResponse[], count: response.count };
    }
};
