import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { RoutesConstants } from '../Constants/RoutesConstants';
import { LoginContainer } from '../Pages/Auth/LoginContainer';
import { AppState } from './../Contexts/Global/AppState';
import { PrivateRoutes } from './PrivateRoutes';
import PublicRouteContainer from './PublicRouteContainer';
//import localStorageProvider from './SWRLocalCache';

export const AppRoutes = () => {
	return (
		<Router>
			<SWRConfig>
				<AppState>
					<Routes>
						<Route
							path="/*"
							element={<PrivateRoutes />}
						/>
						<Route element={<PublicRouteContainer />}>
							<Route
								path={RoutesConstants.LOGIN_PAGE}
								index
								element={<LoginContainer />}
							/>
						</Route>
					</Routes>
				</AppState>
			</SWRConfig>
		</Router>
	);
};
