import { useContext, useEffect, useState } from 'react';
import { TableComponentGeneric } from '../../../Components/TableGeneric';
import AlarmContext from '../../../Contexts/Alarm/Search/AlarmContext';
import { AlarmContextType } from '../../../Interfaces/Context/AlarmContextType.interface';
import AlarmTableConfig from '../../../Configs/Tables/AlarmTableConfig.json';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../../../Hooks/useAuth';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { Tooltip } from 'primereact/tooltip';

export const AlarmsTable = () => {
	const { alarms, activateFilterSearch, onChangePage, paginationProps, alertsTypes, resetPagination, setOrganization_idFilter } = useContext(
		AlarmContext
	) satisfies AlarmContextType;
	const { data: organizations } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const { data: establishments } = useFetch(
		() => FetchKeyConstants.ESTABLISHMENTS_LIST,
		() => FetchConstants.ESTABLISHMENTS_LIST()
	);
	const { data: networks } = useFetch(
		() => FetchKeyConstants.NETWORKS_LIST,
		() => FetchConstants.NETWORKS_LIST()
	);
	const [organizationFilter, setOrganizationFilter] = useState<any>(null);
	const [networkFilter, setNetworkFilter] = useState(null);
	const [alertTypeFilter, setAlertTypeFilter] = useState(null);
	const [establishmentIdFilter, setEstablishmentIdFilter] = useState(null);
	const [networkForUserConsutla, setNetworkForUserConsutla] = useState([]);
	const { disabledDropdown, checkIsUserConsulta, dataEstablishments } = useAuth();

	const handlePaginationChange = (limit: number, skip: number, page: number) => {
		onChangePage(limit, page);
	};

	useEffect(() => {
		if (organizations && organizations.length > 0) {
			const UBA = organizations.find((org: any) => org.name === 'UBA');
			setOrganizationFilter(UBA);
			setOrganization_idFilter(UBA._id);
			activateFilterSearch(UBA.externalId, networkFilter, alertTypeFilter, establishmentIdFilter);
		}
	}, [organizations]);

	useEffect(() => {
		if (!networks) return;
		if (checkIsUserConsulta()) {
			setNetworkForUserConsutla(networks?.filter((network: any) => network.establishmentId === dataEstablishments[0]) || []);
		} else {
			setNetworkForUserConsutla(networks);
		}
	}, [networks]);

	return (
		<div className="m-3">
			<div className="card p-fluid">
				<div style={{ textAlign: 'center' }}>
					<h3
						className="mb-6"
						style={{ color: '#495057' }}>
						Buscador de alarmas
					</h3>
				</div>
				<div className="grid formgrid">
					<div className="col-3">
						<h5
							className="mb-2"
							style={{ color: '#495057' }}>
							Organizaciones
						</h5>
						<Dropdown
							value={organizationFilter}
							onChange={(e) => {
								/* 	console.log(e); */
								setOrganizationFilter(e.value);
								activateFilterSearch(e.value?.externalId, networkFilter, alertTypeFilter, establishmentIdFilter);
							}}
							options={organizations}
							/* optionValue="_id" */
							optionLabel="name"
							placeholder="Buscar por organización"
							emptyMessage={'No se encontraron organizaciones'}
							filter
							filterBy="name"
							showClear
						/>
					</div>
					<div className="col-3">
						<h5
							className="mb-2 ml-3"
							style={{ color: '#495057' }}>
							Redes
						</h5>
						<Dropdown
							value={networkFilter}
							onChange={(e) => {
								setNetworkFilter(e.value);
								activateFilterSearch(organizationFilter?._id, e.value, alertTypeFilter, establishmentIdFilter);
							}}
							options={networkForUserConsutla}
							style={{ marginLeft: 10 }}
							optionLabel="name"
							optionValue="networkId"
							placeholder="Buscar por red"
							emptyMessage={'No se encontraron redes'}
							filter
							filterBy="name"
							showClear
						/>
					</div>
					<Tooltip
						target=".alert-tooltip"
						content={alertsTypes.length <= 0 ? 'Seleccione una organización.' : ''}
					/>
					<div
						className="col-3 alert-tooltip"
						data-pr-position="top">
						<h5
							className="mb-2 ml-3"
							style={{ color: '#495057' }}>
							Tipo de alerta
						</h5>
						<Dropdown
							value={alertTypeFilter}
							onChange={(e) => {
								setAlertTypeFilter(e.value);
								activateFilterSearch(organizationFilter?._id, networkFilter, e.value, establishmentIdFilter);
							}}
							options={alertsTypes}
							disabled={alertsTypes.length <= 0}
							style={{ marginLeft: 10 }}
							optionLabel="name"
							optionValue="id"
							placeholder="Buscar por tipo de alarma"
							emptyMessage={'No se encontraron alarmas'}
							filter
							filterBy="name"
							showClear
						/>
					</div>
					<div className="col-3">
						<h5
							className="mb-2 ml-3"
							style={{ color: '#495057' }}>
							Establecimiento
						</h5>
						<Dropdown
							value={establishmentIdFilter}
							onChange={(e) => {
								setEstablishmentIdFilter(e.value);
								activateFilterSearch(organizationFilter?._id, networkFilter, alertTypeFilter, e.value);
							}}
							options={establishments}
							style={{ marginLeft: 10 }}
							optionLabel="name"
							optionValue="id"
							placeholder="Buscar por establecimiento"
							emptyMessage={'No se encontraron establecimientos'}
							disabled={disabledDropdown()}
							filter
							filterBy="name"
							showClear
						/>
					</div>
				</div>
			</div>
			<TableComponentGeneric
				columns={AlarmTableConfig.tableConfig}
				rows={alarms}
				actions=""
				emptyMessage={'No hay alarmas'}
				paginationProps={paginationProps}
				handleChange={handlePaginationChange}
				resetPagination={resetPagination}
			/>
		</div>
	);
};
