//Table de usuarios
import { UserState } from '../../../Contexts/Users/UserState';
import { TableUser } from './TableUser';

export const UsersPage = () => {
    return (
        <UserState>
            <TableUser />
        </UserState>
    );
};
