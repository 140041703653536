import { useState } from "react";
import { usePagination } from "./usePagination";

//hook para manejo de estados de una tabla, incluye la información de los registros como el páginado donde
//se encuentra parado
export const useTable = <T = unknown>() => {
    const [data, setData] = useState<T[]>([]);
    //usar este campo solo si es editable para mostrar los selects 
    //si esto escala mucho capaz deberiamos separar el usetable fijo de la tabla editable
    const [options, setOptions] = useState([]);
    const pagination = usePagination();

    const updateOptions = (newOptions: any) => {
        setOptions(newOptions);
    }

    const onChange = (newData: any, limit: any = null, page: any = null, count: any = null) => {
        setData(newData)
        pagination?.updatePagination(limit, page);
        count || count === 0 ? pagination?.updateCount(count) : null;
    }

    /*se expone el siguiente objeto
    data : registros a renderizar
    options: listas maestras que deben ser renderizadas en los selects
    pagination : información del hook use pagination (ver documentación del use pagination)
    OnChange : metodo que actualiza el paginado y la data en una actualizacion  */

    return { data, options, paginationProps: pagination.paginationProps, updateOptions, onChange, getSkip: pagination.getSkip }

}