/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { AlarmBoardContext } from '../../../Contexts/Alarm/AlarmBoard/AlarmBoardContext';

// Style
import './AlarmBoardSelect.scss';

// PrimeReact
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { SelectSkeleton } from '../AlarmBoard';

export const AlarmBoardSelects = () => {
	const {
		organizationFilter,
		setOrganizationFilter,
		networkFilter,
		setNetworkFilter,
		establishmentFilter,
		setEstablishmentFilter,
		isOrgLoading,
		isOrgDataLoading,
		organizationsArr,
		establishmentsArr,
		networksArr,
		lastUpdate
	} = useContext(AlarmBoardContext);

	return (
		<div>
			<div className="title">
				<h3>Panel Alarmas</h3>
			</div>
			<div className="selects">
				<div className="filter">
					{!isOrgLoading && !isOrgDataLoading ? (
						<>
							<h5 style={{ color: '#495057' }}>Organizaciones</h5>
							<Dropdown
								value={organizationFilter}
								onChange={(e) => {
									setOrganizationFilter(e.value);
									if (!e.value) {
										setEstablishmentFilter(null);
										setNetworkFilter(null);
									}
								}}
								disabled
								style={{ minWidth: 300 }}
								options={organizationsArr}
								optionLabel="name"
								placeholder="Buscar por Organización"
								emptyMessage={'No se encontraron Organizaciones'}
								filter
								filterBy="name"
								showClear
							/>
						</>
					) : (
						<SelectSkeleton />
					)}
				</div>
				<div className="filter">
					{!isOrgDataLoading ? (
						<>
							<h5 style={{ color: '#495057' }}>Establecimiento</h5>
							<Dropdown
								value={establishmentFilter}
								onChange={(e) => {
									if (!e.value?.name) {
										setEstablishmentFilter(null);
										setNetworkFilter(null);
										return;
									}
									setEstablishmentFilter(e.value);
								}}
								options={establishmentsArr}
								style={{ minWidth: 300 }}
								optionLabel="name"
								placeholder="Buscar por Establecimiento"
								emptyMessage={'No se encontraron Establecimientos'}
								disabled={!organizationFilter}
								filter
								filterBy="name"
								showClear
							/>
						</>
					) : (
						<SelectSkeleton />
					)}
				</div>
				<div className="filter">
					{!isOrgDataLoading ? (
						<>
							<h5 style={{ color: '#495057' }}>Redes</h5>
							<Dropdown
								value={networkFilter}
								onChange={(e) => {
									if (!e.value?.name) {
										setNetworkFilter(null);
										return;
									}
									setNetworkFilter(e.value);
								}}
								disabled={!establishmentFilter}
								options={networksArr}
								style={{ minWidth: 300 }}
								optionLabel="name"
								placeholder="Buscar por Red"
								emptyMessage={'No se encontraron Redes'}
								filter
								filterBy="name"
								showClear
							/>
						</>
					) : (
						<SelectSkeleton />
					)}
				</div>
				{!isOrgLoading && !isOrgDataLoading ? (
					<>
						<div className="last-update">
							<h4 style={{ color: '#495057' }}>Último refresco</h4>
							<h3>{`${lastUpdate}hs`}</h3>
						</div>
					</>
				) : (
					<Skeleton
						width={'240px'}
						height={'125px'}
					/>
				)}
			</div>
		</div>
	);
};
