import { FC, useEffect, useState } from 'react';
import Props from '../../../Interfaces/State/Props.interface';
import NetworkSearchContext from './NetworkSearchContext';
import { NetworkStructureResponse } from '../../../Interfaces/Response/networkSearch/NetworkStructureResponse.interface';
import { NetworkSearchService } from '../../../Services/Search/NetworkSearchService';
import { useTable } from '../../../Hooks/useTable';
import { PaginationLimitsInterface } from '../../../Interfaces/Request/UserRequest/paginationLimits.interface';
import { SearchRequestInterface } from '../../../Interfaces/Request/SearchRequest/SearchRequest.interface';
import { useAuth } from '../../../Hooks/useAuth';
import { getDataSearch } from '../../../Helpers/DataSearchHelper';

export const NetworkSearchState: FC<Props> = ({ children }) => {
    const [organizationIdFilter, setOrganizationIdFilter] = useState('');
    const [establishmentIdFilter, setEstablishmentIdFilter] = useState('');
    const [networkFilter, setNetworkFilter] = useState('');
    const [resetPagination, setResetPagination] = useState(false);
    const { checkIsUserConsulta, dataEstablishments } = useAuth();
    const tableInfo = useTable<NetworkStructureResponse>();
    const { paginationProps } = tableInfo;
    const initialLimits: PaginationLimitsInterface = {
        limit: paginationProps.limit,
        skip: 0,
        page: 0
    };

    useEffect(() => {
        getNetworksByFilterData(initialLimits);
    }, []);

    const getNetworksByFilterData = async (
        limits: PaginationLimitsInterface,
        organizationId = organizationIdFilter,
        establishmentId = checkIsUserConsulta() ? dataEstablishments[0] : establishmentIdFilter,
        networkName = networkFilter
    ) => {
        const data: SearchRequestInterface = getDataSearch(
            limits,
            tableInfo,
            paginationProps,
            {
                "organizationId": organizationId,
                "establishmentId": establishmentId,
                "networkName": networkName
            }
        );
        const response = await NetworkSearchService.getAllNetworks(data);
        tableInfo.onChange(response.data, data.paginationProps.limit, limits.page, response.count);
        setResetPagination(false);
    };

    const onChangePage = (limit: number, page: number) => {
        const skip = tableInfo.getSkip(limit, page);
        getNetworksByFilterData({ limit, skip, page });
    };

    const activateFilterSearch = async (
        organizationId: string,
        establishmentId: string,
        networkName: string
    ) => {
        await getNetworksByFilterData(initialLimits, organizationId, establishmentId, networkName);
        setOrganizationIdFilter(organizationId);
        setEstablishmentIdFilter(establishmentId);
        setNetworkFilter(networkName);
        setResetPagination(true);
    };

    return (
        <NetworkSearchContext.Provider
            value={{
                networks: tableInfo.data,
                paginationProps: paginationProps,
                onChangePage,
                activateFilterSearch,
                resetPagination
            }}>
            {children}
        </NetworkSearchContext.Provider>
    );
};
