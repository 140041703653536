import { useEffect } from 'react';
import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import NetworkDashboardConfig from '../../../Configs/Dashboards/NetworkDashboardConfig.json';
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DashboardHeader } from '../../../Components/DashboardHeader';

export const NetworkGeneralInfo = () => {
	const params = useParams();
	const { data: fieldsGeneralInfo } = useFetch(
		() => FetchKeyConstants.GRAL_INFO_NETWORK,
		() => FetchConstants.GRAL_INFO_NETWORK(params.id)
	);

	useEffect(() => {
		if (fieldsGeneralInfo) {
			fieldsGeneralInfo['organizationsName'] = fieldsGeneralInfo.organizations?.name;
			fieldsGeneralInfo['organizationsEstablishment'] = fieldsGeneralInfo.organizations?.establishment;
		}
	}, [fieldsGeneralInfo]);

	return (
		<div
			className="grid"
			style={{ background: 'white', margin: '0px 1px' }}>
			<div className="col-12">
				<DashboardHeader title={'red'} />
			</div>
			<div
				className="col-12"
				style={{ textAlign: 'center' }}>
				<div className="p-2">
					{fieldsGeneralInfo && (
						<>
							<h5>Información general de la red</h5>
							<DashboardGeneric
								fieldsData={fieldsGeneralInfo}
								fieldsLabel={NetworkDashboardConfig.generalInfo}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
