import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import './EstablishmentDialog.scss';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { monthNames } from '../../Constants/MonthNames';

export interface EstablishmentDialogProps {
	info: any;
	id: number;
	showDialog: boolean;
	setShowDialog: Dispatch<SetStateAction<boolean>>;
	// eslint-disable-next-line no-unused-vars
	action: (id: number, month: number, year: number) => void;
}

export const EstablishmentDialog = ({ info, id, showDialog, setShowDialog, action }: EstablishmentDialogProps) => {
	const [now] = useState<any>(new Date());
	const [visible, setVisible] = useState<boolean>(true);
	const [monthSelected, setMonthSelected] = useState<any>(null);
	const [yearSelected, setYearSelected] = useState<any>(now.getFullYear());
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		const now = new Date();
		setMonthSelected(null);
		monthNames.map((month) => {
			if (Number(yearSelected) < now.getFullYear()) {
				month.disabled = false;
				return;
			} else if (Number(yearSelected) === now.getFullYear()) {
				month.disabled = true;
			}
			if (month.id <= now.getMonth() + 1) {
				month.disabled = false;
			}
		});
		setVisible(showDialog);
	}, [showDialog, yearSelected]);

	const headerContent = (
		<div className="header-content">
			<i
				className="pi pi-calendar"
				style={{ fontSize: '2rem' }}></i>
			<h3>Descargar Reporte Establecimiento</h3>
		</div>
	);

	const footerContent = (
		<div>
			<Button
				label="Salir"
				icon="pi pi-times"
				onClick={() => {
					setVisible(false);
					setShowDialog(false);
					setMonthSelected(null);
				}}
			/>
		</div>
	);

	return (
		<div className="card flex justify-content-center">
			<Dialog
				header={headerContent}
				visible={visible}
				style={{ width: '600px' }}
				onHide={() => {
					setVisible(false);
					setShowDialog(false);
				}}
				draggable={false}
				footer={footerContent}
				keepInViewport={false}>
				<>
					<div className="dialog-est-subttitle">
						<h3>{`${info?.organizationName} - ${info?.name} (${info?.typeEstablishment})`}</h3>
					</div>
					<div className="dialog-est-inputs">
						<Dropdown
							className="dropdown-month"
							value={monthSelected}
							onChange={(e) => {
								setMonthSelected(e.value);
							}}
							options={monthNames}
							optionLabel="name"
							optionValue="id"
							placeholder="Seleccionar mes..."
							emptyMessage={'No se encontraron meses para mostrar'}
							filter
							filterBy="name"
							showClear
						/>
						<div className="flex flex-column column-gap-2">
							<InputText
								className={error ? 'dialog-input-year p-error p-invalid' : 'dialog-input-year'}
								keyfilter="int"
								placeholder="Ingrese un año..."
								aria-describedby="input-year-help"
								value={yearSelected}
								onChange={(e) => {
									setYearSelected(e.target.value);
									if (e.target.value > now.getFullYear()) {
										setError(true);
									} else {
										setError(false);
									}
								}}
							/>
							{error ? (
								<small
									className="p-error p-invalid"
									id="input-year-help">{`Valor máximo ${now.getFullYear()}.`}</small>
							) : (
								<small id="input-year-help">{`Valor máximo ${now.getFullYear()}.`}</small>
							)}
						</div>
					</div>
				</>
				<Button
					className="btn-download mb-5"
					disabled={!monthSelected || !yearSelected || yearSelected > now.getFullYear()}
					label="Descargar"
					icon="pi pi-check"
					onClick={() => {
						action(id, monthSelected, Number(yearSelected));
						setShowDialog(false);
						setMonthSelected(null);
						setYearSelected(null);
					}}
				/>
			</Dialog>
		</div>
	);
};
