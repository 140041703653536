import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RoutesConstants } from '../Constants/RoutesConstants';
import AppContext from '../Contexts/Global/AppContext';
import { AppContextType } from '../Interfaces/Context/appContextType.interface';

const PrivateRouteContainer = () => {
    const { token } = useContext(AppContext) as AppContextType;
    return token ? <Outlet /> : <Navigate to={RoutesConstants.LOGIN_PAGE} />;
};

export default PrivateRouteContainer;
