import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import { DashboardHeader } from '../../../Components/DashboardHeader';
import EstablishmentDashboardConfig from '../../../Configs/Dashboards/EstablishmentDashboardConfig.json'
import { FetchConstants, FetchKeyConstants } from '../../../Constants/FetchConstants';
import { useFetch } from '../../../Hooks/useFetch';
import { useParams } from 'react-router-dom';

export const EstablishmentGeneralInfo = () => {

    const params = useParams();
    const { data: fieldsGeneralInfo } = useFetch(() => FetchKeyConstants.GRAL_INFO_ESTABLISHMENT, () => FetchConstants.GRAL_INFO_ESTABLISHMENT(params.id), true);

    return (
        <div className="grid m-auto">
            <div className="col-12">
                <DashboardHeader title={'establecimiento'} />
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <div className="p-2">
                    {fieldsGeneralInfo &&
                        <DashboardGeneric fieldsData={fieldsGeneralInfo} fieldsLabel={EstablishmentDashboardConfig.generalInfo} />
                    }
                </div>
            </div>
        </div>
    );
};