export const monthNames: any[] = [
	{
		id: 1,
		name: 'Enero',
		disabled: true
	},
	{
		id: 2,
		name: 'Febrero',
		disabled: true
	},
	{
		id: 3,
		name: 'Marzo',
		disabled: true
	},
	{
		id: 4,
		name: 'Abril',
		disabled: true
	},
	{
		id: 5,
		name: 'Mayo',
		disabled: true
	},
	{
		id: 6,
		name: 'Junio',
		disabled: true
	},
	{
		id: 7,
		name: 'Julio',
		disabled: true
	},
	{
		id: 8,
		name: 'Agosto',
		disabled: true
	},
	{
		id: 9,
		name: 'Septiembre',
		disabled: true
	},
	{
		id: 10,
		name: 'Octubre',
		disabled: true
	},
	{
		id: 11,
		name: 'Noviembre',
		disabled: true
	},
	{
		id: 12,
		name: 'Diciembre',
		disabled: true
	}
];
