import { AlarmsConfigResponse } from '../Interfaces/Response/alarm/AlarmConfigResponse.interface';
import { BaseService } from './BaseService';

export const AlarmConfigService = {
	async get(id: string): Promise<AlarmsConfigResponse> {
		const url = `organizations/organization/${id}`;
		const response = await BaseService.getData(url);
		return response as any;
	},
	async put(id: string, body: any) {
		const url = `organizations/organization/${id}`;
		const response = await BaseService.putData(url, body);
		return response;
	}
};
