// import { useMemo } from 'react';
import { useEffect } from 'react';
import { DashboardGeneric } from '../../../Components/DashboardGeneric';
import { DashboardHeader } from '../../../Components/DashboardHeader';
import DevicesDashboardConfig from '../../../Configs/Dashboards/DevicesDashboardConfig.json'
import { DevicesFieldsResponse } from '../../../Interfaces/Response/devicesDashboard/DevicesFields.interface';

interface DevicesInfoPropsInterface {
    fieldsGeneralInfo: DevicesFieldsResponse
}

export const DevicesInfo = ({ fieldsGeneralInfo }: DevicesInfoPropsInterface) => {
    useEffect(() => {
        if (fieldsGeneralInfo) {
            fieldsGeneralInfo.status = fieldsGeneralInfo?.deviceFallInfoDto?.status || 'No status';
        }
    }, [fieldsGeneralInfo]);

    return (
        <div className="grid" style={{ background: 'white', margin: '0px 1px' }}>
            <div className='col-12'>
                <DashboardHeader title={'dispositivo'} />
            </div>
            <div className="col-12">
                <div className="p-2">
                    {fieldsGeneralInfo &&
                        <>
                            <h5>Dispositivo {fieldsGeneralInfo.name}</h5>
                            <DashboardGeneric fieldsData={fieldsGeneralInfo} fieldsLabel={DevicesDashboardConfig.generalInfo} />
                        </>
                    }
                </div>
            </div>
        </div>
    );
};