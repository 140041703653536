// import { Box } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';
import { ClipLoader } from 'react-spinners';

export const Spinner = () => {
 
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress === true ? (
        <div
          style={{
            display: 'flex',
            background: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            width: '100%',
            height: '100%',
            opacity: '1',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '9999999',
            transition: 'all 1s ease-in-out'
          }}>
          <ClipLoader
            size="120px"
            loading={promiseInProgress}
            cssOverride={{
              display: 'block',
              position: 'fixed',
              margin: '0 auto',
              borderColor: 'black',
              zIndex: '9999',
              opacity: '1',
              transition: 'all 1s ease-in-out'
            }}></ClipLoader>
        </div>
      ) : null}
    </>
  );
};