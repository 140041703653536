import { TreeTableConstants } from '../Constants/TreeTableConstants';
import RequestTreeInterface from '../Interfaces/Request/OrganizationRequest/RequestTree.interface';

export const useTreeTable = ({
    dialog,
    nodeSelected,
    organizationSelected,
    nodes,
    setNodes,
    insertNewElement,
    deleteElement,
    setNodeSelected
}: any) => {
    const getFunction = (label: string, nameData: any) => {
        switch (label) {
            case TreeTableConstants.ADD_FACULTY:
                addNewFaculty(nameData);
                break;
            case TreeTableConstants.ADD_BUILDING:
                addNewBuilding(nameData);
                break;
            case TreeTableConstants.ASSOCIATE_NETWORK:
                associateNetwork(nameData);
                break;
        }
    };

    const addNewFaculty = async (name: string) => {
        const body = createBody({ name: name });
        const response = await insertNewElement(body, TreeTableConstants.FACULTY_LABEL);
        if (response) {
            nodes.push({
                key: response.id,
                data: {
                    name: name,
                    entity: TreeTableConstants.FACULTY_LABEL
                },
                children: []
            });
        }
        dialog.CloseDialog();
    };

    const addNewBuilding = async (name: string) => {
        const body = createBody({ name: name, facultyId: nodeSelected.key });
        const response = await insertNewElement(body, TreeTableConstants.BUILDING_LABEL);
        if (response) {
            if (nodeSelected != undefined)
                nodeSelected.children.push({
                    key: response.id,
                    data: {
                        name: name,
                        entity: TreeTableConstants.BUILDING_LABEL
                    },
                    children: []
                });
            dialog.CloseDialog();
        }
    };

    const associateNetwork = async (data: {
        _id: string;
        name: string;
        networkExternalId: string;
    }) => {
        if (nodeSelected != undefined) {
            const body = createBody({
                name: data.name,
                establishmentId: nodeSelected.key,
                networkId: data._id,
                networkExternalId: data.networkExternalId
            });
            const response = await insertNewElement(body, TreeTableConstants.NETWORK_LABEL);
            if (response) {
                nodeSelected.children.push({
                    key: data._id,
                    data: {
                        name: data.name,
                        entity: TreeTableConstants.NETWORK_LABEL
                    }
                });
            }
        }
        dialog.CloseDialog();
    };

    const createBody = (data: RequestTreeInterface) => {
        return {
            organizationId: organizationSelected.organizationId,
            name: data.name ? data.name : null,
            establishmentId: data.establishmentId ? data.establishmentId : null,
            facultyId: data.facultyId ? data.facultyId : null,
            buildingId: data.buildingId ? data.buildingId : null,
            networkId: data.networkId ? data.networkId : null,
            networkExternalId: data.networkExternalId ? data.networkExternalId : null
        };
    };

    const deleteNode = () => {
        switch (nodeSelected.data.entity) {
            case TreeTableConstants.FACULTY_LABEL:
                deleteFaculty(nodeSelected.key);
                break;
            case TreeTableConstants.FACULTY_WITHOUTBUILDINGS_LABEL:
                deleteFaculty(nodeSelected.key);
                break;
            case TreeTableConstants.BUILDING_LABEL:
                deleteBuilding(nodeSelected.key);
                break;
            case TreeTableConstants.NETWORK_LABEL:
                deleteNetwork(nodeSelected.key);
                break;
        }
    };

    const deleteFaculty = async (idNode: string) => {
        let indexNodeToDelete;
        const faculties = [...nodes];
        const body = createBody({ facultyId: idNode });
        const response = await deleteElement(body, TreeTableConstants.FACULTY_LABEL);
        faculties.forEach((f: any, index: number) => {
            if (f.key == idNode) {
                indexNodeToDelete = index;
            }
        });
        if (response) {
            if (indexNodeToDelete != null) faculties.splice(indexNodeToDelete, 1);
            nodes = faculties;
            setNodes(nodes);
            setNodeSelected(null);
        }
    };

    const deleteBuilding = async (idNode: string) => {
        let indexNodeToDelete, idFaculty: string;
        const faculties = [...nodes];
        faculties.forEach((f: any) => {
            f.children.forEach((c: any, index: number) => {
                if (c.key == idNode) {
                    indexNodeToDelete = index;
                    idFaculty = f.key;
                }
            });
        });
        const facultyIdentication = faculties.filter((f: any) => f.key == idFaculty);
        const body = createBody({ buildingId: idNode });
        const response = await deleteElement(body, TreeTableConstants.BUILDING_LABEL);
        if (response) {
            if (indexNodeToDelete != null && facultyIdentication != null) {
                indexNodeToDelete === 0
                    ? facultyIdentication[0].children.shift()
                    : facultyIdentication[0].children.splice(indexNodeToDelete, 1);
            }
            nodes = faculties;
            setNodes(nodes);
        }
    };

    const deleteNetwork = async (idNode: string) => {
        let indexNodeToDelete,
            idBuilding: any = null,
            idFaculty: any = null;
        const faculties = [...nodes];
        faculties.forEach((f: any) => {
            f.children.forEach((c: any, index: number) => {
                if (c.data.entity === TreeTableConstants.NETWORK_LABEL) {
                    if (c.key == idNode) {
                        indexNodeToDelete = index;
                        idFaculty = f.key;
                    }
                } else {
                    c.children.forEach((n: any, index: number) => {
                        if (n.key == idNode) {
                            indexNodeToDelete = index;
                            idBuilding = c.key;
                            idFaculty = f.key;
                        }
                    });
                }
            });
        });
        const nodeIdentification =
            idBuilding != null
                ? faculties
                      .filter((f: any) => f.key == idFaculty)[0]
                      .children.filter((b: any) => b.key == idBuilding)
                : faculties.filter((f: any) => f.key == idFaculty);
        const establishmentId = idBuilding != null ? idBuilding : idFaculty;
        const body = createBody({ establishmentId: establishmentId, networkId: idNode });
        const response = await deleteElement(body, TreeTableConstants.NETWORK_LABEL);
        if (response) {
            if (indexNodeToDelete != null && nodeIdentification != null) {
                indexNodeToDelete === 0
                    ? nodeIdentification[0].children.shift()
                    : nodeIdentification[0].children.splice(indexNodeToDelete, 1);
            }
            nodes = faculties;
            setNodes(nodes);
        }
    };

    return { getFunction, deleteNode };
};
