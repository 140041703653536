import { FC, useState, useEffect } from 'react';
import { AlarmBoardContext } from './AlarmBoardContext';
import { useFetch } from '../../../Hooks/useFetch';
import { FetchKeyConstants, FetchConstants } from '../../../Constants/FetchConstants';
import { IDataForSelects } from '../../../Interfaces/Components/AlarmBoard/AlarmBoard.interface';
import { Organization } from '../../../Interfaces/Response/organizationGetAll/OrganizationGetAll.interface';
import { EstablishmentForSelects } from '../../../Interfaces/Helpers/DataForSelects';
import { IBoardAlarmSelects } from '../../../Interfaces/Components/AlarmBoard/AlarmBoard.interface';
import { SelectSearchService } from '../../../Services/Search/SelectSearchService';
import { EstablishmentService } from '../../../Services/Search/EstablishmentService';
import { useAuth } from '../../../Hooks/useAuth';

export const AlarmBoardState: FC<any> = ({ children }) => {
	const [organizationID, setOrganizationID] = useState<string | undefined>(undefined);
	const {
		data: orgData,
		isLoading: isOrgDataLoading,
		isValidating: isValidatingOrgData
	} = useFetch(
		() => FetchKeyConstants.DEVICE_BOARD_DATA_ORG(organizationID),
		() => FetchConstants.DEVICE_BOARD_DATA_ORG(organizationID),
		true,
		true,
		1000 * 60 * 5
	);
	const { data: organizations, isLoading: isOrgLoading } = useFetch(
		() => FetchKeyConstants.ORGANIZATIONS_LIST,
		() => FetchConstants.ORGANIZATIONS_LIST()
	);
	const [organizationFilter, setOrganizationFilter] = useState<any>(null);
	const [networkFilter, setNetworkFilter] = useState<any>(null);
	const [establishmentFilter, setEstablishmentFilter] = useState<any>(null);
	//
	const [organizationsArr, setOrganizationsArr] = useState([]);
	const [establishmentsArr, setEstablishmentsArr] = useState<IDataForSelects[]>([]);
	const [allowEstablishments, setAllowEstablishments] = useState<string[]>([]);
	const [networksArr, setNetworksArr] = useState<IDataForSelects[]>([]);
	const [lastUpdate, setLastUpdate] = useState<string>('00:00');

	const { checkIsUserConsulta, dataEstablishments } = useAuth();

	useEffect(() => {
		getDefaultOrganization();
		getEstablishments();
	}, []);

	const getDefaultOrganization = async () => {
		const response = await SelectSearchService.getDefault();
		setOrganizationID(response.organizationId);
	};

	useEffect(() => {
		const newDate = new Date();
		if (newDate.getMinutes() < 10) {
			setLastUpdate(`${newDate.getHours()}:0${newDate.getMinutes()}`);
		} else {
			setLastUpdate(`${newDate.getHours()}:${newDate.getMinutes()}`);
		}
	}, [isValidatingOrgData]);

	useEffect(() => {
		if (orgData) {
			const establishmentArrAux = EstablishmentForSelects(orgData);
			setEstablishmentsArr(allowEstablishments.map(e => establishmentArrAux.filter(i => i.id == e)).flat());
			const newDate = new Date();
			if (newDate.getMinutes() < 10) {
				setLastUpdate(`${newDate.getHours()}:0${newDate.getMinutes()}`);
			} else {
				setLastUpdate(`${newDate.getHours()}:${newDate.getMinutes()}`);
			}
		}
	}, [orgData]);

	const getEstablishments = async () => {
		const data: any = {
			paginationProps: {
				limit: 100,
				skip: 0
			},
			params: {
				establishmentId: checkIsUserConsulta() ? dataEstablishments[0] : null
			}
		};
		const response = await EstablishmentService.getEstablishmentsData(data);
		setAllowEstablishments(response.data.map(e => e.id))
	};

	useEffect(() => {
		if (organizations) {
			formatOrganizations();
			const uba = organizations.find((org: Organization) => org.name === 'UBA');
			if (uba) {
				setOrganizationFilter({ name: uba.name, id: uba._id });
			}
		}
	}, [organizations]);

	useEffect(() => {
		if (establishmentFilter) {
			const networksArrAux: IDataForSelects[] = [];
			orgData?.establishments?.map((org: IBoardAlarmSelects) => {
				org.networks.forEach((net: any) => {
					if (org.id === establishmentFilter.id) {
						networksArrAux.push({
							name: net.name,
							id: net.id
						});
					}
				});
			});
			setNetworksArr([...networksArrAux]);
			setEstablishmentsArr([...establishmentsArr]);
		}
	}, [establishmentFilter]);

	const formatOrganizations = () => {
		const orgs = organizations.map((org: Organization) => {
			return { name: org.name, id: org._id };
		});
		setOrganizationsArr(orgs);
	};

	const value = {
		organizationFilter,
		setOrganizationFilter,
		networkFilter,
		setNetworkFilter,
		establishmentFilter,
		setEstablishmentFilter,
		organizationsArr,
		setOrganizationsArr,
		establishmentsArr,
		setEstablishmentsArr,
		networksArr,
		setNetworksArr,
		isOrgDataLoading,
		isOrgLoading,
		orgData,
		lastUpdate
	};
	return <AlarmBoardContext.Provider value={value}>{children}</AlarmBoardContext.Provider>;
};
