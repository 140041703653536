import { UserState } from '../../../Contexts/Users/UserState';
import { ChangeUserPasswordForm } from './ChangeUserPasswordForm';

export const ChangeUserPasswordPage = () => {
    return (
        <UserState>
            <ChangeUserPasswordForm />
        </UserState>
    );
};
