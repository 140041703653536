import { useParams } from "react-router-dom";
import { FetchConstants, FetchKeyConstants } from "../../../Constants/FetchConstants";
import { useFetch } from "../../../Hooks/useFetch";
import { DashboardGeneric } from "../../../Components/DashboardGeneric";
import EstablishmentDashboardConfig from '../../../Configs/Dashboards/EstablishmentDashboardConfig.json'

export const EstablishmentPerformanceInfo = () => {
    const params = useParams();
    const { data: fieldsPowerSupply } = useFetch(
        () => FetchKeyConstants.FIELDS_POWERSUPPLY_ESTABLISHMENT, 
        () => FetchConstants.FIELDS_POWERSUPPLY_ESTABLISHMENT(params.id), 
        true
    );
    const { data: fieldsActiveInfo } = useFetch(
        () => FetchKeyConstants.FIELDS_ACTIVE_ESTABLISHMENT, 
        () => FetchConstants.FIELDS_ACTIVE_ESTABLISHMENT(params.id), 
        true
    );

    return (
        <div className="grid m-auto">
            <div className="col-12" style={{ textAlign: 'start' }}>
                <div className="card p-3 grid">
                    <div className='col-12'>
                        <h5>Performance de la red</h5>
                    </div>
                    <div className='col-12' style={{padding: '15px'}}>
                        {fieldsActiveInfo && (
                            <DashboardGeneric
                            fieldsData={fieldsActiveInfo} 
                            fieldsLabel={EstablishmentDashboardConfig.dashboard}
                            />
                        )}
                    </div>
                    <div className='col-12' style={{padding: '15px'}}>
                        {fieldsPowerSupply && (
                            <DashboardGeneric
                            fieldsData={fieldsPowerSupply} 
                            fieldsLabel={EstablishmentDashboardConfig.powerSupply}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
