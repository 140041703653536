import { EstablishmentDashboardInfoResponse } from '../../Interfaces/Response/establishment/EstablishmentDashboardInfo.interface';
import { EstablishmentGeneralInfoResponse } from '../../Interfaces/Response/establishment/EstablishmentGeneralInfo.interface';
import { DataChartResponse } from '../../Interfaces/Response/DataChartResponse.interface';
import { BaseService } from '../BaseService';
import { DevicesPowersupplyResponse } from '../../Interfaces/Response/devicesDashboard/DevicesPowersupply.interface';

export const EstablishmentDashboardService = {
    async getFields(id: string | undefined): Promise<EstablishmentDashboardInfoResponse> {
        const url = `networks/dashboard`;
        const qs = `?establishmentId=${id}`;
        const response = await BaseService.getData(url, qs);
        return response.data as EstablishmentDashboardInfoResponse;
    },

    async getGeneralInfo(id: string | undefined): Promise<EstablishmentGeneralInfoResponse[]> {
        const url = `establishments/establishment`;
        const qs = `?id=${id}`;
        const response = await BaseService.getData(url, qs);
        return response.data as EstablishmentGeneralInfoResponse[];
    },

    async getDashboardData(idEstablishment: string | undefined): Promise<DataChartResponse[]> {
        const url = `networkClients`;
        const qs = `?establishmentId=${idEstablishment}`
        const response = await BaseService.getData(url, qs);
        return [response.data] as DataChartResponse[];
    },

    async getPowerSupply(id: string | undefined): Promise<DevicesPowersupplyResponse> {
        const url = `devices/powersupplytime`;
        const qs = `?establishmentId=${id}`;
        const response = await BaseService.getData(url, qs);
        return response.data as DevicesPowersupplyResponse;
    },
};
