import { ErrorLabelInterface } from "../Interfaces/Components/ErrorLabel.interface"

export const ErrorLabel = ({ validation, errorMsg }: ErrorLabelInterface) => {
  return (
    <>
      {validation &&
        <small id="input-email" className="p-error">
          {errorMsg}
        </small>
      }
    </>
  )
}
