import { useEffect } from 'react';
import { FetchConstants, FetchKeyConstants } from '../../Constants/FetchConstants';
import { useFetch } from '../../Hooks/useFetch';
import { Metric } from '../../Interfaces/Response/DeviceBoardResponse.interface';
import { AlarmBoardPanel } from '../Alarms/AlarmBoard';
import { PanelSkeleton } from '../Alarms/AlarmBoard/Skeletons/PanelSkeleton';
import { Skeleton } from 'primereact/skeleton';
import { ColorRgbEnum } from '../../Enums/ColorRgbEnum';
//import useSwr from 'swr';

export const DeviceBoardData = ({ serial }: { serial: string }) => {
	const {
		data: device,
		isLoading,
		mutate,
		error
	} = useFetch(
		() => FetchKeyConstants.DEVICE_BOARD_DATA(serial),
		() => FetchConstants.DEVICE_BOARD_DATA(serial)
	);

	useEffect(() => {
		/* console.log('METRICAS', device); */
		if (serial) mutate(() => FetchKeyConstants.DEVICE_BOARD_DATA(serial));
	}, [serial]);

	const getTabs = (device: any) => {
		const tabs: any[] = [];
		Array.isArray(device?.data?.metrics) &&
			device?.data?.metrics.forEach((metric: Metric) => {
				tabs.push({
					title: metric.metricTypeName,
					color: metric?.stateColor?.toLowerCase() || ColorRgbEnum.NoColor,
					description: (
						<div>
							<div>
								<strong>{`Información: `}</strong>
								<p className="p-2">{`${metric.setDescription}`}</p>
							</div>
							<div>
								<strong>{`Serial del dispositivo: `}</strong>
								<p className="p-2">{`${metric.deviceSerial}`}</p>
							</div>
						</div>
					)
				});
			});
		return tabs;
	};

	return (
		<>
			{!error ? (
				<>
					{isLoading && (
						<div className="mx-0 my-4">
							<Skeleton
								width={'100%'}
								height="455px">
								<PanelSkeleton />
							</Skeleton>
						</div>
					)}
					{device && !isLoading && (
						<AlarmBoardPanel
							title={`Dispositivo ${serial}`}
							tabs={getTabs(device)}
							globalState={[device?.data?.globalState.toLowerCase()]}
						/>
					)}
					{!device && serial && !isLoading && (
						<div
							className="text-center"
							style={{ color: '#3a3f44' }}>
							<h4>No se encontró ningun dispositivo con el serial ingresado...</h4>
						</div>
					)}
				</>
			) : (
				<div
					className="text-center"
					style={{ color: '#3a3f44' }}>
					<h4>Ocurrió un error inesperado, vuelve a intentarlo...</h4>
				</div>
			)}
		</>
	);
};
