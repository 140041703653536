import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TableProps from '../../../Interfaces/Components/TableProps.interface';
import { useEffect, useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { Paginator } from 'primereact/paginator';
import DeviceSearchContext from '../../../Contexts/Devices/Search/DeviceSearchContext';
import { DeviceSearchContextType } from '../../../Interfaces/Context/DeviceSearchContextType.interface';

// COMPONENTE CON SOLO TABLA
export const TableComponent = ({ rows, emptyMessage, paginationProps, handleChange, resetPagination }: TableProps) => {
	const [dataRows, setDataRows] = useState<any>();
	const [first, setFirst] = useState(0);

	const { patchRobbenDevicesData } = useContext(DeviceSearchContext) satisfies DeviceSearchContextType;

	useEffect(() => {
		if (resetPagination) {
			setFirst(0);
			handleChange(paginationProps.limit, null, 0);
		}
	}, [resetPagination]);

	const onBasicPageChange = (event: { first: number; page: number }) => {
		setFirst(event.first);
		handleChange(paginationProps.limit, null, event.page);
	};

	const navigate = useNavigate();

	useEffect(() => {
		setDataRows(rows);
	}, [rows]);

	const navigateDashboard = (index: number) => {
		navigate(`${RoutesConstants.DEVICES_SEARCH_PAGE}/${rows[index]._id}/${rows[index].serial}`);
	};

	const robbenDevice = (index: number, isStolen: boolean) => {
		const id = rows[index]._id;
		patchRobbenDevicesData(id, isStolen);
	};

	const nameIcon = (index: number) => {
		return (
			<div className="flex">
				{rows[index]?.isStolen === true ? (
					<>
						<div className="flex align-items-center">
							{rows[index]?.name}
							<Button
								type="button"
								icon="pi pi-exclamation-triangle"
								className="p-button-rounded p-button-info p-button-text ml-2"
								tooltip="Dispositivo robado"
							/>
						</div>
					</>
				) : (
					<div>{rows[index]?.name}</div>
				)}
			</div>
		);
	};

	const actionButton = (index: number) => {
		return (
			<div className="flex align-items-center justify-content-center button-demo">
				<Button
					icon="pi pi-chart-bar"
					className="p-button-rounded p-button-info p-button-text"
					style={{ marginRight: 10 }}
					onClick={() => navigateDashboard(index)}
				/>
				{rows[index]?.isStolen === true ? (
					<Button
						icon="pi pi-replay"
						className="p-button-rounded p-button-info p-button-text"
						style={{ marginRight: 10 }}
						onClick={() => robbenDevice(index, false)}
						tooltip="Recuperar dispositivo"
						tooltipOptions={{ position: 'bottom' }}
					/>
				) : (
					<Button
						icon="pi pi-exclamation-triangle"
						className="p-button-rounded p-button-info p-button-text"
						style={{ marginRight: 10 }}
						onClick={() => robbenDevice(index, true)}
						tooltip="Marcar dispositivo como robado"
						tooltipOptions={{ position: 'bottom' }}
					/>
				)}
			</div>
		);
	};

	return (
		<>
			{dataRows && (
				<>
					<DataTable
						value={dataRows}
						rows={paginationProps.limit}
						className="p-datatable-gridlines"
						showGridlines
						dataKey="id"
						filterDisplay="menu"
						responsiveLayout="scroll"
						emptyMessage={emptyMessage}
						editMode="row">
						<Column
							header={'Nombre'}
							style={{ minWidth: '10rem' }}
							field={'name'}
							body={(data, props) => nameIcon(props.rowIndex)}></Column>
						<Column
							header={'Organización'}
							style={{ minWidth: '10rem' }}
							field={'organizationName'}></Column>
						<Column
							header={'Establecimiento'}
							style={{ minWidth: '10rem' }}
							field={'establishmentName'}></Column>
						<Column
							header={'Red'}
							style={{ minWidth: '10rem' }}
							field={'networkName'}></Column>
						<Column
							header={'Tipo'}
							style={{ minWidth: '10rem' }}
							field={'productType'}></Column>
						<Column
							header={'Serial'}
							style={{ minWidth: '10rem' }}
							field={'serial'}></Column>
						<Column
							align={'center'}
							header={'Acciones'}
							style={{ minWidth: '10rem' }}
							field={'actions'}
							body={(data, props) => actionButton(props.rowIndex)}></Column>
					</DataTable>
					<Paginator
						first={first}
						rows={paginationProps.limit}
						totalRecords={paginationProps.count}
						onPageChange={onBasicPageChange}></Paginator>
				</>
			)}
		</>
	);
};
