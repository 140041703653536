import { Skeleton } from 'primereact/skeleton';

export const SelectSkeleton = () => {
	return (
		<>
			<Skeleton
				width="150px"
				height="2rem"
				className="mb-2"
			/>
			<Skeleton
				width="350px"
				height="2.5rem"
				className="mb-2"
			/>
		</>
	);
};
