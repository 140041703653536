import { RolResponse } from '../Interfaces/Response/rol/RolResponse.interface';
import { BaseService } from './BaseService';

export const RolService = {
    async getRoles(): Promise<RolResponse[]> {
        const url = 'roles/byFilter';
        const response = await BaseService.getData(url);
        return response.data as RolResponse[];
    }
};
