import './DeviceBoardStyle.scss';
import { DeviceBoardData } from './DeviceBoardData';
import { DeviceBoardHeader } from './DeviceBoardHeader';
import { useState } from 'react';

const DeviceBoardPage = () => {
	const [serial, setSerial] = useState<string | null>(null);

	const onSearchSerial = (value: string) => {
		setSerial(value);
	};

	return (
		<>
			<div className="grid-device">
				<div className="col-12 ">
					<div className="card p-2">
						<DeviceBoardHeader onSearch={onSearchSerial} />
					</div>
				</div>
				<div className="col-12 p-0">
					<h3 className="device-status-title">Estado de dispositivo</h3>
				</div>
				{serial && (
					<div className="col-12 card-container">
						{' '}
						<DeviceBoardData serial={serial ? serial : ''} />
					</div>
				)}
			</div>
		</>
	);
};

export default DeviceBoardPage;
