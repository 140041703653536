import { NetworkSearchState } from "../../../Contexts/Network/Search/NetworkSearchState";
import { TableNetworks } from "./TableNetworks";

export const NetworkSearchPage = () => {

    return (
        <NetworkSearchState>
            <TableNetworks/>
        </NetworkSearchState>
    )
}
