import { EstablishmentState } from '../../../Contexts/Establishment/Search/EstablishmentState';
import { EstablishmentTable } from './EstablishmentTable';

export const EstablishmentPage = () => {
    return (
        <EstablishmentState>
            <EstablishmentTable />
        </EstablishmentState>
    );
};
