import { DashboardGeneric } from "../../../Components/DashboardGeneric"
import DevicesDashboardConfig from '../../../Configs/Dashboards/DevicesDashboardConfig.json'
import { DevicesFieldsResponse } from "../../../Interfaces/Response/devicesDashboard/DevicesFields.interface";

interface DevicesInfoPropsInterface{
    fieldsGeneralInfo: DevicesFieldsResponse
}

export const DeviceInterfaceRebootInfo = ({ fieldsGeneralInfo }: DevicesInfoPropsInterface) => {

    const interfaceInfo = fieldsGeneralInfo?.interfaceModification;
    const equipmentReboot = fieldsGeneralInfo?.equipmentReboot;

    return (
        <div className="grid m-auto">
            <div className="col mr-3" style={{ textAlign: 'start' }}>
                <div className="card p-3 grid">
                    <div className='col-12'>
                        <h5>Modificación de interface</h5>
                    </div>
                    <div className='col-12' style={{padding: '15px'}}>
                        {interfaceInfo &&
                            <DashboardGeneric 
                            fieldsData={interfaceInfo ? interfaceInfo : null}
                            fieldsLabel={DevicesDashboardConfig.interfaceModification} 
                            />
                        }   
                    </div>
                </div>
            </div>
            <div className="col" style={{ textAlign: 'start' }}>
                <div className="card p-3 grid">
                    <div className='col-12'>
                        <h5>Reinicio de equipos</h5>
                    </div>
                    <div className='col' style={{padding: '15px'}}>
                        {equipmentReboot &&
                            <DashboardGeneric 
                            fieldsData={equipmentReboot ? equipmentReboot : null}
                            fieldsLabel={DevicesDashboardConfig.equipmentReboot} 
                            />
                        }   
                    </div>
                </div>
            </div>
        </div>
    )
}
