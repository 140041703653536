import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { TreeTableConstants } from '../Constants/TreeTableConstants';
import { useEffect, useState, useContext } from 'react';
// import { InputText } from 'primereact/inputtext';
import { ModalGeneric } from './ModalGeneric';
import { useDialog } from '../Hooks/useDialog';
import { FormAddNew } from '../Pages/Organizations/Tree/Components/FormAddNew';
import { useTreeTable } from '../Hooks/useTreeTable';
import { ConfirmModal } from '../Components/ConfirmModal';
import OrganizationTreeContext from '../Contexts/Organization/Tree/OrganizationTreeContext';
import { convertOrganizationData } from '../Helpers/OrganizationTreeHelper';
import { OrganizationTreeContextType } from '../Interfaces/Context/OrganizationTreeContextType.interface';

interface TreeTableProps {
    config: {
        tableConfig: {
            label: string,
            field: string,
            expander: boolean,
            body: boolean,
            alignRight: boolean
        }[]
    };
}

export const TreeTableComponent = ({ config }: TreeTableProps) => {
    const { organizationSelected, insertNewElement, deleteElement, networks } = useContext(
        OrganizationTreeContext
    ) as OrganizationTreeContextType;
    const [selectedTreeTableNodeKeys, setSelectedTreeTableNodeKeys] = useState([]);
    const [labelButton, setLabelButton] = useState(TreeTableConstants.ADD_FACULTY);
    const [nodeSelected, setNodeSelected] = useState<{ data: { entity: string }, children: { data: { entity: string } }[] } | null>(null);
    const [nodes, setNodes] = useState<object[] | null>(null);
    const [prevKeySelected, setPrevKeySelected] = useState<string | null>(null);
    const [disabledButton, setDisabledButton] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showFirstButton, setShowFirstButton] = useState(true);
    const dialog = useDialog();
    const { getFunction, deleteNode } = useTreeTable({
        dialog,
        nodeSelected,
        organizationSelected,
        nodes,
        setNodes,
        insertNewElement,
        deleteElement,
        setNodeSelected
    });

    const handleSelect = (event: { key: string, data: { entity: string } }) => {
        onSelectedKey(event.key);
        if (prevKeySelected === null || prevKeySelected != event.key) {
            switch (event.data.entity) {
                case TreeTableConstants.FACULTY_LABEL:
                    actions(TreeTableConstants.ADD_BUILDING, false, event, true);
                    break;
                case TreeTableConstants.BUILDING_LABEL:
                    actions(TreeTableConstants.ASSOCIATE_NETWORK, false, event, true);
                    break;
                case TreeTableConstants.NETWORK_LABEL:
                    actions(TreeTableConstants.ASSOCIATE_NETWORK, true, event, true);
                    break;
            }
        }
    };

    const actions = (label: string, disabled: boolean, event: any, firstButton: boolean) => {
        setLabelButton(label);
        setNodeSelected(event);
        setDisabledButton(disabled);
        if (event.children && event.children.length > 0
            && event.children[0].data.entity == TreeTableConstants.NETWORK_LABEL
            && event.data.entity === TreeTableConstants.FACULTY_LABEL) setShowFirstButton(false);
        else setShowFirstButton(firstButton);
    };

    const actionsModal = (label: string, element: any) => {
        dialog.setContent(element);
        dialog.setHeader(label);
        dialog.OpenDialog();
    };

    const onSelectedKey = (event: any) => {
        setSelectedTreeTableNodeKeys(event);
        if (prevKeySelected === event) {
            setSelectedTreeTableNodeKeys([]);
            setLabelButton(TreeTableConstants.ADD_FACULTY);
            setShowFirstButton(true);
            setNodeSelected(null);
            setPrevKeySelected(null);
        }
        setPrevKeySelected(event);
    };

    useEffect(() => {
        if (organizationSelected != null && nodeSelected == null)
            setNodes(convertOrganizationData(organizationSelected).faculties);
    }, [organizationSelected])

    useEffect(() => {
        if (nodeSelected && nodeSelected.data.entity === TreeTableConstants.FACULTY_LABEL) {
            if (nodeSelected.children.length === 0) setShowButton(true);
            else if (nodeSelected.children.length > 0 && nodeSelected.children[0].data.entity == TreeTableConstants.NETWORK_LABEL)
                setShowButton(true);
            else setShowButton(false);
        }
        else setShowButton(false);
        if (nodeSelected == null) setLabelButton(TreeTableConstants.ADD_FACULTY);
    }, [nodeSelected]);

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {organizationSelected && (
                <div>
                    <h5 className="m-0">{organizationSelected.name}</h5>
                    <span
                        className="block mt-2 md:mt-0 p-input-icon-left"
                        style={{ position: 'absolute', right: 0, top: '10px' }}>
                        {/* <i className="pi pi-search" />
                        <InputText type="search" placeholder="Buscar..." /> */}
                        {showFirstButton &&
                            <Button
                                label={labelButton}
                                icon="pi pi-plus"
                                className="p-button-success mr-2 ml-2"
                                disabled={disabledButton}
                                onClick={() =>
                                    actionsModal(
                                        labelButton,
                                        <FormAddNew label={labelButton} action={getFunction} networksOptions={networks} />
                                    )
                                }
                            />
                        }
                        {showButton && (
                            <Button
                                label={TreeTableConstants.ASSOCIATE_NETWORK}
                                icon="pi pi-plus"
                                className="p-button-success mr-2 ml-2"
                                disabled={disabledButton}
                                onClick={() =>
                                    actionsModal(
                                        TreeTableConstants.ASSOCIATE_NETWORK,
                                        <FormAddNew
                                            label={TreeTableConstants.ASSOCIATE_NETWORK}
                                            action={getFunction}
                                            networksOptions={networks}
                                        />
                                    )
                                }
                            />
                        )}
                        <Button
                            label={TreeTableConstants.DELETE}
                            icon="pi pi-trash"
                            onClick={() => {
                                actionsModal(
                                    'Eliminar Elemento',
                                    <ConfirmModal dialogHook={dialog} action={deleteNode} />
                                );
                            }}
                            disabled={nodeSelected == null}
                            className="p-button-danger mr-2 ml-2"
                        />
                    </span>
                </div>
            )}
        </div>
    );

    return (
        <div className="grid">
            {nodes != null && (
                <div className="col-12">
                    <div className="card">
                        <TreeTable
                            value={nodes}
                            header={header}
                            selectionMode="single"
                            propagateSelectionUp
                            selectionKeys={selectedTreeTableNodeKeys}
                            emptyMessage='Ninguna facultad asociada.'
                            onSelect={(e: any) => handleSelect(e.node)}>
                            {config.tableConfig.map(
                                (config: {
                                    label: string;
                                    field: string;
                                    expander: boolean;
                                    body: boolean;
                                    alignRight: boolean;
                                }) => {
                                    return (
                                        <Column
                                            key={config.label}
                                            header={config.label}
                                            style={{
                                                minWidth: '10rem',
                                                textAlign: `${config.alignRight ? 'right' : 'left'}`
                                            }}
                                            field={config.field}
                                            expander={config.expander}></Column>
                                    );
                                }
                            )}
                        </TreeTable>
                    </div>
                </div>
            )}
            <ModalGeneric dialogHook={dialog} />
        </div>
    );
};
