import { FC, useEffect, useState } from 'react';
import Props from '../../Interfaces/State/Props.interface';
import MapContext from './MapContext';
import { MapPointResponse } from '../../Interfaces/Response/map/MapPointReponse.interface';
import { MapService } from '../../Services/MapService';

export const MapState: FC<Props> = ({ children }) => {

    const [points, setPoints] = useState<MapPointResponse[]>();

    useEffect(() => {
        getPoints();
    }, [])

    const getPoints = async () => {
        const response = await MapService.getMapPoints();
        setPoints(response);
    }

    return (
        <MapContext.Provider
            value={{
                points,
                getPoints,
            }}>
            {children}
        </MapContext.Provider>
    );
};
