export const RoutesConstants = {
	ANY_ROUTE: '*',
	FORGOT_PASSWORD_PAGE: '/recuperar-contraseña',
	PRIVATE_ROUTE: '/inicio',
	METRIC_PAGE: '/metricas',
	INIT_PASSWORD_PAGE: '/iniciar-password',
	CHANGE_PASSWORD: '/cambiar-contraseña',
	LOGIN_PAGE: '/iniciar-sesion',
	USERS_PAGE: '/usuarios',
	USERS_ABM: '/usuario',
	USERS_ABM_ROUTE: '/usuario/:id',
	ORGANIZATION_PAGE: '/organizaciones/arbol',
	ORGANIZATION_TABLE_PAGE: '/organizaciones',
	ORGANIZATION_DASHBOARD: '/organizaciones/:id',
	DEVICES_DASHBOARD: '/dispositivos/:id/:serial',
	DEVICES_SEARCH_PAGE: '/dispositivos',
	NETWORK_DASHBOARD: '/redes/:id',
	NETWORKS_SEARCH_PAGE: '/redes',
	ESTABLISHMENT_PAGE: '/establecimientos',
	ESTABLISHMENT_DASHBOARD: '/establecimientos/:id',
	ALARMS_PAGE: '/alarmas',
	ALARMS_CONFIG: '/configuración-alarmas',
	ALARMS_BOARD: '/panel-alarmas',
	MAP_PAGE: '/mapa',
	DEVICE_BOARD_PAGE_SERIAL: '/tablero-dispositivo/:serial',
	DEVICE_BOARD_PAGE: '/tablero-dispositivo'
};

export const RedirectRouteConstants = {
	REDIRECT_ORGANIZATION_DASHBOARD: (id: string) => RoutesConstants.ORGANIZATION_DASHBOARD.replace(':id', id)
};
