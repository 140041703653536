import { DashboardGeneralService } from '../Services/Dashboard/DashboardGeneralService';
import { DevicesDashboardService } from '../Services/Dashboard/DevicesDashboardService';
import { EstablishmentDashboardService } from '../Services/Dashboard/EstablishmentDashboardService';
import { NetworkClientsService } from '../Services/Dashboard/NetworkClientsService';
import { NetworkDashboardService } from '../Services/Dashboard/NetworkDashboardService';
import { OrganizationDashboardService } from '../Services/Dashboard/OrganizationDashboardService';
import { DeviceBoardService } from '../Services/DeviceBoardService';
import { SelectSearchService } from '../Services/Search/SelectSearchService';

export const FetchConstants = {
	ESTABLISHMENTS_LIST: () => SelectSearchService.getEstablishments(),
	ORGANIZATIONS_LIST: () => SelectSearchService.getOrganizations(),
	NETWORKS_LIST: () => SelectSearchService.getNetworks(),
	LOGIN_SPLASH: (id: string | undefined, constant: string) => DashboardGeneralService.getLoginInfo(id, constant),
	CHART_DATA: (id: string | undefined, constant: string) => NetworkClientsService.getDashboardData(id, constant),
	GRAL_INFO_NETWORK: (id: string | undefined) => NetworkDashboardService.getGeneralInfo(id),
	FIELDS_ACTIVE_NETWORK: (id: string | undefined) => NetworkDashboardService.getNetworkFall(id),
	FIELDS_POWERSUPPLY_NETWORK: (id: string | undefined) => NetworkDashboardService.getPowerSupply(id),
	GRAL_INFO_ESTABLISHMENT: (id: string | undefined) => EstablishmentDashboardService.getGeneralInfo(id),
	FIELDS_ACTIVE_ESTABLISHMENT: (id: string | undefined) => EstablishmentDashboardService.getFields(id),
	FIELDS_POWERSUPPLY_ESTABLISHMENT: (id: string | undefined) => EstablishmentDashboardService.getPowerSupply(id),
	GRAL_INFO_DEVICE: (id: string | undefined) => DevicesDashboardService.getInfo(id),
	CHART_DATA_DEVICE: (id: string | undefined) => DevicesDashboardService.getGraphicDashboard(id),
	CHART_NETWORK_HEALTH_CHANNEL: (id: string | undefined) => DevicesDashboardService.getNetworkHealthChannel(id),
	DEVICE_SNR_SSI: (id: string | undefined) => DevicesDashboardService.getSnrSsiChart(id),
	FIELDS_ACTIVE_ORGANIZATION: () => OrganizationDashboardService.getFields(),
	DEVICE_BOARD_DATA: (serial: string) => DeviceBoardService.get(serial),
	DEVICE_BOARD_DATA_ORG: (serial: string | undefined) => DeviceBoardService.getOrganizationForBoardAlarm(serial),
	TIME_SERIES_DATA: (id: string | undefined) => DevicesDashboardService.getTimeSeriesGraphicData(id),
	TRAFFIC_DATA: (id: string | undefined) => NetworkDashboardService.getTrafficData(id),
	UPLINKS_USAGE: (id: string | undefined) => NetworkDashboardService.getUplinksUsage(id),
	SWITCH_PORT_STATUS: (id: string | undefined) => NetworkDashboardService.getSwitchPortStatus(id)
};

export const FetchKeyConstants = {
	ESTABLISHMENTS_LIST: 'establishmentsList',
	ORGANIZATIONS_LIST: 'organizationsList',
	NETWORKS_LIST: 'networksList',
	SWITCH_PORT_STATUS: (id: string | undefined) => {
		return `switchPortStatus${id}`;
	},
	LOGIN_SPLASH: (id: string | undefined) => {
		return `loginSplash${id}`;
	},
	CHART_DATA: (id: string | undefined) => {
		return `chartData${id}`;
	},
	GRAL_INFO_NETWORK: 'gralInfoNetwork',
	FIELDS_ACTIVE_NETWORK: (id: string | undefined) => {
		return `fieldsActiveNetwork${id}`;
	},
	FIELDS_POWERSUPPLY_NETWORK: (id: string | undefined) => {
		return `fieldsPowerSupplyNetwork${id}`;
	},
	GRAL_INFO_ESTABLISHMENT: 'gralInfoEstablishment',
	FIELDS_ACTIVE_ESTABLISHMENT: 'fieldsActiveEstablishment',
	FIELDS_POWERSUPPLY_ESTABLISHMENT: 'fieldsPowerSupplyEstablishment',
	GRAL_INFO_DEVICE: (id: string | undefined) => {
		return `gralInfoDevice${id}`;
	},
	CHART_DATA_DEVICE: (id: string | undefined) => {
		return `chartDataDevice${id}`;
	},
	FIELDS_ACTIVE_DEVICE: 'fieldsActiveDevice',
	FIELDS_POWERSUPPLY_DEVICE: 'fieldsPowerSupplyDevice',
	FIELDS_ACTIVE_ORGANIZATION: 'fieldsActiveOrganization',
	DEVICE_BOARD_DATA: (id: string | undefined) => {
		return `deviceBoardData${id}`;
	},
	DEVICE_BOARD_DATA_ORG: (serial: string | undefined) => {
		if (!serial) {
			return null;
		}
		return `deviceBoardDataOrg${serial}`;
	},
	TIME_SERIES_DATA: (id: string | undefined) => {
		return `timeSeriesData${id}`;
	},
	TRAFFIC_DATA: (id: string | undefined) => {
		return `trafficData${id}`;
	},
	CHART_NETWORK_HEALTH_CHANNEL: (id: string | undefined) => {
		return `chartNetworkHealthChannel${id}`;
	},
	DEVICE_SNR_SSI: (id: string | undefined) => {
		return `wirelessSignalQualityHistory${id}`;
	},
	UPLINKS_USAGE: (id: string | undefined) => {
		return `sentReceived${id}`;
	}
};
