interface statusParseHelperArg {
    active: boolean | string,
    fallDate: string,
    totalHoursDown: number
}

interface statusParseDatesArg {
    lastChange?: string,
    count?: number
}

export const statusParseHelper = (fieldsData: statusParseHelperArg) => {
    if (fieldsData) {
        if (fieldsData.active) fieldsData['active'] = 'Estable'
        else fieldsData['active'] = 'Caído'
        if (fieldsData.fallDate) {
            const date = fieldsData.fallDate;
            fieldsData['fallDate'] = date + ':00'
        }
    }
    return fieldsData
}

export const statusParseDates = (fieldsData: statusParseDatesArg) => {
    if (fieldsData) {
        if (fieldsData.lastChange) {
            const date = fieldsData.lastChange.split('T')[0].split('-').reverse().join('/')
            const time = fieldsData.lastChange.split('T')[1].split('.')[0]
            fieldsData['lastChange'] = date + ' ' + time
        }
    }
    return fieldsData
}