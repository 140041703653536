import useSwr from 'swr';
/**
 *
 * @param path The path for the fetcher to use. Can be a string or a function that returns a string.
 * @param fetcher The fetcher function to use. Can be a function that returns a promise or a promise.
 * @param reload Specify if the fetcher should be revalidated on mount, focus, reconnect or if the data is stale.
 * @param revalidateOnMount Specify if the fetcher should be revalidated on mount.
 * @param RevalidateInterval Specify the interval in MINUTES for the fetcher to be revalidated (default is 3 minutes)
 * @returns
 */
export const useFetch = (
	path: () => string | string | null,
	fetcher: () => Promise<any>,
	reload = false,
	revalidateOnMount?: boolean,
	RevalidateInterval = 3
) => {
	if (!path || path === null) throw new Error('Path is required');

	const { data, error, mutate, isLoading, isValidating } = useSwr(path, fetcher, {
		revalidateIfStale: reload,
		revalidateOnFocus: reload,
		revalidateOnReconnect: reload,
		revalidateOnMount: revalidateOnMount,
		refreshInterval: RevalidateInterval ? RevalidateInterval * 60 * 1000 : undefined
	});

	return { data, error, mutate, isLoading, isValidating };
};
