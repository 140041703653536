import { InputText } from 'primereact/inputtext';
import { AlarmConfigComponent } from './AlarmConfigComponentTable';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { emailValidate } from '../../../Helpers/ValidationsHelper';
import { ErrorLabel } from '../../../Components/ErrorLabel';
import { FormConstants } from '../../../Constants/FormConstants';
import AlarmConfigContext from '../../../Contexts/Alarm/AlarmConfig/AlarmConfigContext';
import { AlarmConfigContextType } from '../../../Interfaces/Context/AlarmConfigContextType.interface';

export const AlarmWebhooksConfig = () => {
	const { alarms } = useContext(AlarmConfigContext) satisfies AlarmConfigContextType;
	const [email, setEmail] = useState<string>('');
	const emailTouched = useRef(false);
	const errorEmail = useMemo(() => emailValidate(email) && emailTouched.current, [email, emailTouched.current]);

	useEffect(() => {
		if (alarms && alarms.globalEmail) setEmail(alarms.globalEmail);
	}, [alarms]);

	const onChangeValue = () => {
		if (emailTouched.current === false) emailTouched.current = true;
	};

	return (
		<>
			<div className="m-3">
				<div className="card p-fluid">
					<div className="grid formgrid">
						<div className="col-6">
							<h5
								className="mb-2"
								style={{ color: '#495057' }}>
								Email
							</h5>
							<InputText
								value={email}
								type="search"
								onInput={(e) => {
									setEmail(e.currentTarget.value);
									alarms.globalEmail = e.currentTarget.value;
								}}
								onBlur={() => onChangeValue()}
								style={{ marginLeft: 0, width: '460px', height: '35px' }}
								placeholder="Ingrese email"
							/>
							<br></br>
							{email.length > 0 && (
								<ErrorLabel
									validation={errorEmail}
									errorMsg={FormConstants.ERROR_EMAIL_MSG}
								/>
							)}
						</div>
					</div>
				</div>
				<AlarmConfigComponent
					rows={alarms.webhookAlarms}
					columns={[]}
					emptyMessage={'No se encontraron alarmas'}
					paginationProps={undefined}
				/>
			</div>
		</>
	);
};
